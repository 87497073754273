import React from "react";
import { motion } from "framer-motion";
import { useWindowSize } from "./functions";

const FullScreenModal = ({ children }) => {
  const size = useWindowSize();
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <>
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className="d-flex  w-100 justify-content-center align-items-center"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          overflow: "hidden",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1.5,
          height: size.height,
        }}
      >
        <motion.div
          variants={item}
          className="container-sm d-flex w-100 h-100 py-1 flex-column bg-white rounded-2 shadow-lg"
          style={{
            position: "fixed",
            opacity: 1,
            maxWidth: "400px",
            maxHeight: "100%",
            overflowY: "auto",
            zIndex: 3,
          }}
        >
          {children}
        </motion.div>
      </motion.div>
    </>
  );
};

export default FullScreenModal;
