import React, { memo, useEffect, useReducer } from "react";
import { BsFillPencilFill, BsFillTelephoneFill } from "react-icons/bs";
import { TiContacts } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { Modal } from "../../functions/Modal";
import { AddTags } from "./components/AddTags";
import { AddNotes } from "./components/AddNotes";
import { useNavigate } from "react-router-dom";
import { Lists } from "./components/Lists";
import { ToastContainer } from "react-toastify";

export const ProfileScreen = memo((props) => {
  let profileinfo = props.data;
  const navigate = useNavigate();
  let initialProfileScreenState = {
    AddNotes: {
      isAddNotesModal_Active: false,
      Notes: profileinfo.notes,
    },
    AddTags: {
      isAddTagsModal_Active: false,
      Tags: profileinfo.profiletags,
      RemoveTagsId: profileinfo.profiletags_id,
    },
    AddLists: {
      isAddListsModal_Active: false,
      Lists: [...profileinfo?.Lists],
      OnlyListIds: [...profileinfo?.ListsIDs],
      UpdatedList: [...profileinfo?.Lists],
    },
  };
  const ProfileScreenReducer = (state, action) => {
    switch (action.type) {
      case "ADD_TAGS_MODAL_STATE": {
        return {
          ...state,
          AddTags: { ...state.AddTags, isAddTagsModal_Active: action.value },
        };
      }
      case "TAGS_STATE": {
        return {
          ...state,
          AddTags: {
            ...state.AddTags,
            Tags: action.value.Tags,
            RemoveTagsId: action.value.Tags_ID,
          },
        };
      }
      case "REMOVE_TAG": {
        return {
          ...state,
          AddTags: {
            ...state.AddTags,
            Tags: [...action.value],
          },
        };
      }
      case "ADD_NOTES_MODAL_STATE": {
        return {
          ...state,
          AddNotes: { ...state.AddNotes, isAddNotesModal_Active: action.value },
        };
      }
      case "NOTES_STATE": {
        return {
          ...state,
          AddNotes: { ...state.AddNotes, Notes: action.value },
        };
      }
      case "ADD_LISTS_MODAL_STATE": {
        return {
          ...state,
          AddLists: { ...state.AddLists, isAddListsModal_Active: action.value },
        };
      }
      case "LISTS_STATE": {
        return {
          ...state,
          AddLists: { ...state.AddLists, Lists: action.value },
        };
      }
      case "ONLY_LISTS_ID_STATE": {
        return {
          ...state,
          AddLists: { ...state.AddLists, OnlyListIds: action.value },
        };
      }
      case "FINAL_UPDATED_LIST": {
        return {
          ...state,
          AddLists: { ...state.AddLists, UpdatedList: action.value },
        };
      }

      default:
        break;
    }
  };
  const [ProfileScreenState, ProfileScreenDispatch] = useReducer(
    ProfileScreenReducer,
    initialProfileScreenState,
  );

  const Goto_EditPage = () => {
    navigate("/editleads");
  };

  return (
    <>
      <div
        className="container-fluid d-flex justify-content-start align-items-center flex-column "
        style={{ gap: "12px" }}
      >
        <div className="d-flex flex-column w-75 align-items-center p-3 justify-content-center bg-white shadow rounded">
          <div className="d-flex justify-content-end w-100">
            <BsFillPencilFill
              size={20}
              onClick={Goto_EditPage}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            className="d-flex justify-content-center align-items-center shadow rounded-circle mb-2"
            style={{ width: "50px", height: "50px" }}
          >
            <TiContacts size={30} />
          </div>

          <h4 className="fs-10 fw-semibold mb-1">
            {profileinfo.contactinfo.first_name +
              " " +
              profileinfo.contactinfo.last_name}
          </h4>
          <h6 className=" fst-italic fs-11 fw-light text-secondary mb-1">
            {profileinfo.contactinfo.desigination}
          </h6>
          <div className="d-flex flex-column w-100 px-1 py-2">
            {/* <p className="d-flex w-100 justify-content-start    mb-1">
              <BsFillTelephoneFill size={15} />
              <a className="text-decoration-none  fs-11 fw-normal ms-2">
                {profileinfo.contactinfo.phone_no}
              </a>
            </p> */}
            {profileinfo.contactinfo.phone_no != null &&
              profileinfo.contactinfo.phone_no !== "" &&
              Array.isArray(profileinfo.contactinfo.phone_no) &&
              profileinfo.contactinfo.phone_no.map((phone, index) => (
                <p
                  className="d-flex w-100 justify-content-start mb-1"
                  key={index}
                >
                  <BsFillTelephoneFill size={15} />
                  <a
                    className="text-decoration-none fs-11 fw-normal ms-2"
                    style={{ overflowWrap: "anywhere" }}
                  >
                    {phone}
                  </a>
                </p>
              ))}

            {profileinfo.contactinfo.email_address != null &&
              profileinfo.contactinfo.email_address !== "" &&
              Array.isArray(profileinfo.contactinfo.email_address) &&
              profileinfo.contactinfo.email_address.map(
                (emailAddress, index) => (
                  <p
                    className="d-flex w-100 justify-content-start mb-1"
                    key={index}
                  >
                    <MdEmail size={15} />
                    <a
                      className="text-decoration-none fs-11 fw-normal ms-2"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {emailAddress}
                    </a>
                  </p>
                ),
              )}

            <p className="d-flex  fs-11 fw-light  mb-0"></p>
          </div>
        </div>
        <div className="d-flex w-75 flex-column pb-3" style={{ gap: "10px" }}>
          <AddTags
            dependencies={{
              state: ProfileScreenState,
              setState: ProfileScreenDispatch,
              profileInformation: profileinfo?.contactinfo,
              existing_tags: profileinfo?.existing_tags,
            }}
          />
          <AddNotes
            dependencies={{
              state: ProfileScreenState,
              setState: ProfileScreenDispatch,
              profileInformation: profileinfo?.contactinfo,
            }}
          />
          <Lists
            dependencies={{
              state: ProfileScreenState,
              setState: ProfileScreenDispatch,
              profileInformation: profileinfo?.contactinfo,
            }}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
});
