import React from "react";
import { Modal } from "./Modal";
import { createPortal } from "react-dom";
import { useWindowSize } from "./functions";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import background from "../assets/background_upgrade.png";

const UpgradePlan = () => {
  const size = useWindowSize();

  return (
    <div
      className="d-flex w-100 flex-column justify-content-start mb-2 align-items-center bg-white rounded-2"
      style={{
        height: size.height - 100,
      }}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center h-100"
        style={{ gap: "20px" }}
      >
        <div className="d-flex flex-column rounded-2  p-3 bg-white mx-2">
          <h4 className="fw-bold fs-4 fs-md-1   text-center lh-base mb-4">
            This feature is not available in your current plan, need plan
            upgrade
          </h4>

          <p className="fw-normal fs-9 fs-md-8 mb-4 text-center text-dark ">
            To upgrade, pls follow the instructions
          </p>
          <ol className="fw-normal text-secondary">
            <li style={{ listStyle: "outside", paddingLeft: "8px" }}>
              <a
                href="https://app.veespaces.com/login"
                className="text-primary fw-normal "
              >
                Click here
              </a>{" "}
              Log into your account
            </li>
            <li style={{ listStyle: "outside", paddingLeft: "8px" }}>
              Go to my account &#8594; My subscriptions
            </li>
            <li style={{ listStyle: "outside", paddingLeft: "8px" }}>
              Choose the plan you like to upgrade
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
