import React, { useEffect, useReducer } from "react";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { inputMerge, pasteInput } from "../../functions/functions";
import { useForm } from "react-hook-form";
import { Login_API } from "../../services/userservices";
import { useTranslation } from "react-i18next";

export const LoginCodeSet = ({
  logo,
  dependencies: { LoginState, LoginChanger },
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      code_1: "",
      code_2: "",
      code_3: "",
      code_4: "",
      code_5: "",
      code_6: "",
    },
  });

  let InitialState = {
    inputs: {
      inputType: "number",
      isShowIcon: true,
    },
    isAPILoading: false,
  };

  function StateChanger(state, action) {
    switch (action.type) {
      case "SHOWHIDEINPUT": {
        return {
          ...state,
          inputs: {
            ...state.inputs,
            inputType: action.inputType,
            isShowIcon: action.icon,
          },
        };
      }
      case "API_LOADER": {
        return {
          ...state,
          isAPILoading: action.status,
        };
      }
      default: {
        return null;
      }
    }
  }

  const [state, changer] = useReducer(StateChanger, InitialState);

  async function registerLoginCode(data) {
    changer({ type: "API_LOADER", status: true });
    await Login_API({
      email: JSON.parse(localStorage.getItem("user_email")),
      action: "create_authentication_code",
      auth_code: Number(
        Object.keys(data)
          .map((key) => data[key])
          .join("")
      ),
    }).then((res) => {
      LoginChanger({
        type: "TOAST_RESPONSE",
        status: res?.status,
        message: res?.message,
      });
      if (res?.status === "success") {
        setTimeout(() => {
          localStorage.setItem(
            "user_login_code",
            JSON.stringify(
              Object.keys(data)
                .map((key) => data[key])
                .join("")
            )
          );
          LoginChanger({ type: "SCREEN_NAME", screen: "login" });
        }, 1000);
      }

      changer({ type: "API_LOADER", status: false });
    });
  }

  useEffect(() => {
    reset({
      code_1: "",
      code_2: "",
      code_3: "",
      code_4: "",
      code_5: "",
      code_6: "",
    });
  }, [isSubmitSuccessful]);

  return (
    <>
      <div
        id="overall"
        className="d-flex flex-column py-5 align-items-center px-3"
      >
        <img
          src={logo}
          width="auto"
          height="auto"
          style={{ maxWidth: "270px", maxHeight: "70px" }}
        />
        <h4 className="fw-semibold fs-2 pt-4" style={{ color: "#322d43" }}>
          {t("login.set_login_code")}
        </h4>
        <p className="fw-normal fs-8 pt-2 px-2" style={{ color: "#4a455a" }}>
          {t("login.login_code_description")}
        </p>

        <form
          className="d-flex flex-column align-items-center mt-2 fs-11"
          style={{ gap: "20px" }}
          onSubmit={handleSubmit(registerLoginCode)}
        >
          <div className="d-flex  fs-9 " style={{ gap: "10px" }}>
            {Array.from({ length: 6 }).map((_, index) => (
              <input
                key={index}
                className={`form-control text-center ${
                  errors[`code_${index + 1}`] ? "border border-danger" : ""
                }`}
                id={`input_num_${index + 1}`}
                min={0}
                max={9}
                maxLength={1}
                onInput={(evt) => {
                  if (evt.target.value.length > evt.target.maxLength) {
                    evt.target.value = evt.target.value.slice(
                      0,
                      evt.target.maxLength
                    );
                  }
                }}
                size={1}
                onKeyUp={(event) =>
                  inputMerge(
                    event,
                    index === 0 ? "" : `input_num_${index}`,
                    `input_num_${index + 1}`,
                    index === 5 ? "" : `input_num_${index + 2}`
                  )
                }
                onPaste={(event) => pasteInput(event)}
                {...register(`code_${index + 1}`, {
                  required: {
                    value: true,
                    message: t("login.pin_required"),
                  },
                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: t("login.pin_invalid"),
                  },
                })}
                type={state?.inputs?.inputType}
                style={{ borderRadius: "10px", width: "40px" }}
              />
            ))}
          </div>
          {Object.keys(errors).some((key) => errors[key]) && (
            <p className="text-danger fw-normal fs-10 text-start m-0">
              {Object.values(errors).find((error) => error)?.message}
            </p>
          )}
          <div className="d-flex w-100 justify-content-center">
            <button
              type="submit"
              className="d-flex align-items-center justify-content-center btn btn-primary rounded-start  fw-semibold w-75 py-3"
              style={{
                borderRadius: "10px 0px 0px 10px",
                backgroundColor: "none",
                gap: "10px",
              }}
            >
              <p className="m-0">{t("login.set_code")}</p>

              {state.isAPILoading && (
                <div className="spinner-border text-light"></div>
              )}
            </button>
            <button
              type="button"
              className="btn btn-light border border-primary  fw-semibold w-25 py-1"
              style={{
                borderRadius: "0px 10px 10px 0px",
              }}
              onClick={() => {
                if (state?.inputs?.inputType === "text") {
                  changer({
                    type: "SHOWHIDEINPUT",
                    inputType: "password",
                    icon: false,
                  });
                } else {
                  changer({
                    type: "SHOWHIDEINPUT",
                    inputType: "text",
                    icon: true,
                  });
                }
              }}
            >
              {state?.inputs?.isShowIcon === true ? (
                <BiShowAlt size={30} color="#5411dc" />
              ) : (
                <BiHide size={30} color="#5411dc" />
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
