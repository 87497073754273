import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Modal } from "../../../functions/Modal";
import { useForm } from "react-hook-form";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { EditLeads_API, EditNewLead_API, List_API } from "../../../services/userservices";
import { toast } from "react-toastify";
import { t } from "i18next";

export const Lists = memo(
  ({ dependencies: { state, setState, profileInformation } }) => {
    let TOAST_CONFIG = {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    };
    // const EXHIBITOR_ID = JSON.parse(localStorage.getItem("exhibitor_id"));
    const SearchContact = useRef(null);
    const DisplayAllLists = useRef(null);
    const [allLists, setAllLists] = useState([]);
    const [searchContactField, setSearchContactField] = useState("");
    async function UpdateLists() {
      if (state.AddLists.Lists) {
        //---------------------------------------API Calling----------------------------------------//
        const PayloadFormData = new FormData();
        PayloadFormData.append("action", "update_list");
        // PayloadFormData.append("exhibitor_id", EXHIBITOR_ID);
        PayloadFormData.append("contact_id", profileInformation.contact_id);
        PayloadFormData.append("list_id", [...state.AddLists.OnlyListIds]);
        const serialize = () => {
          let requestArray = {};
          requestArray["country"] = "india";
          requestArray["status"] = "1";
          requestArray["key"] = "4";
          requestArray["source"] = "leads-mobileapp";
          requestArray["uid"] = JSON.parse(
            localStorage.getItem("user_id"),
          );
          return requestArray;
        };
        let serialized = serialize();
        serialized.list_id = [...state.AddLists.OnlyListIds];
        serialized.contactId = profileInformation.contact_id;
        let payload = {action : "individual", user: [serialized] };
        await EditNewLead_API(payload,profileInformation.contact_id).then((res) => {
          // console.log(res);
          toast.success(res?.message, TOAST_CONFIG);
          setState({
            type: "FINAL_UPDATED_LIST",
            value: state?.AddLists?.Lists,
          });
          setState({
            type: "ADD_LISTS_MODAL_STATE",
            value: false,
          });
        });
        //---------------------------------------API Calling----------------------------------------//
      }
    }
    let CALLBACK_VAR_LISTS = state?.AddLists?.Lists;
    let CALLBACK_VAR_LISTS_IDS = state?.AddLists?.OnlyListIds;
    const SelectionList = useCallback(() => {
      let filteredListName = [];

      if (
        state?.AddLists?.Lists.length > 0 &&
        state?.AddLists?.OnlyListIds.length > 0
      ) {
        JSON.parse(localStorage.getItem("Lists")).filter((list) => {
          if (!state?.AddLists?.OnlyListIds?.includes(list?.list_id)) {
            filteredListName?.push(list);
          }
        });
      } else {
        filteredListName = JSON.parse(localStorage.getItem("Lists"));
      }
      return filteredListName;
    }, [CALLBACK_VAR_LISTS, CALLBACK_VAR_LISTS_IDS]);
    let EFFECT_UPDATE_LISTS_ONLY_ID = state?.AddLists?.Lists;

    useEffect(() => {
      let value = () => {
        let onlyID = [];
        if (state?.AddLists?.Lists.length > 0) {
          [...state?.AddLists?.Lists].filter((list) => {
            onlyID.push(list?.list_id);
          });
        }
        return onlyID;
      };
      setState({ type: "ONLY_LISTS_ID_STATE", value: value() });
    }, [EFFECT_UPDATE_LISTS_ONLY_ID]);

    useEffect(() => {
      setAllLists(SelectionList());
    }, []);

    useEffect(() => {
      if (searchContactField !== "") {
        let filteredListName = SelectionList()?.filter((filteredList) => {
          return filteredList?.list_name
            .toLowerCase()
            .includes(searchContactField.toLowerCase());
        });
        setAllLists([...filteredListName]);
      } else {
        setAllLists(SelectionList());
      }
    }, [searchContactField]);

    function RemoveList(Checking_List) {
      let filteredList = state?.AddLists?.Lists.filter((list) => {
        return !(Checking_List?.list_id).includes(list?.list_id);
      });

      setState({
        type: "LISTS_STATE",
        value: filteredList,
      });
      setAllLists((previousState) => [...previousState, Checking_List]);
    }

    return (
      <>
        {state?.AddLists?.UpdatedList.length === 0 ? (
          <div
            className="d-flex w-100 justify-content-center "
            style={{ gap: "10px" }}
          >
            <p className="fst-italic fs-11 fw-semibold text-secondary">
            {t('contactsMenu.yourLists')}
            </p>
            <BsFillPencilFill
              style={{ cursor: "pointer" }}
              type="button"
              onClick={() => {
                setState({
                  type: "ADD_LISTS_MODAL_STATE",
                  value: true,
                });
              }}
            />
          </div>
        ) : (
          <div
            className="d-flex flex-column w-100 justify-content-center rounded  px-3 py-3"
            style={{
              gap: "10px",
              cursor: "pointer",
              backgroundColor: "rgb(235, 224, 255)",
            }}
            onClick={() => {
              setState({
                type: "ADD_LISTS_MODAL_STATE",
                value: true,
              });
            }}
          >
            <div className="d-flex justify-content-between w-100 align-items-center">
              <p className="d-flex justify-content-start w-100  fs-10 fw-semibold text-dark mb-0">
              {t('contactsMenu.yourLists')} 
              </p>
              <BsFillPencilFill style={{ cursor: "pointer" }} type="button" />
            </div>

            <div
              className="d-flex justify-content-start flex-wrap"
              style={{ gap: "10px" }}
            >
              {state?.AddLists?.UpdatedList.length > 0 &&
                state?.AddLists?.UpdatedList.map((list, index) => {
                  return (
                    <p
                      className="d-flex  align-items-center mb-0  text-white fw-semibold fs-10 rounded px-2 py-1"
                      style={{
                        backgroundColor: `${
                          list?.list_color != "" ? list?.list_color : "#5411dc"
                        }`,
                        gap: "5px",
                      }}
                      key={index}
                    >
                      {list?.list_name}
                    </p>
                  );
                })}
            </div>
          </div>
        )}
        {state?.AddLists?.isAddListsModal_Active && (
          <Modal>
            <div className="d-flex justify-content-between align-items-center  py-2 px-2">
              <p className="fst-italic fs-10 fw-semibold text-dark mb-0">
              {t('contactsMenu.lists')} 
              </p>
            </div>
            <div>
              <div
                className="d-flex flex-column flex-start  py-2 px-2"
                style={{ gap: "5px" }}
              >
                {/*------------------------ Display the lists that are added to the contact and also newly added through selection box------------------------------------  */}
                <div
                  className="d-flex justify-content-start flex-wrap"
                  style={{ gap: "10px" }}
                >
                  {state?.AddLists?.Lists.map((list, index) => {
                    // console.log(list);
                    return (
                      <p
                        className="d-flex  align-items-center mb-0 px-2 py-1 text-white fw-semibold fs-10 rounded"
                        style={{
                          backgroundColor: `${
                            list?.list_color != ""
                              ? list?.list_color
                              : "#5411dc"
                          }`,
                          gap: "5px",
                        }}
                        key={index}
                      >
                        {list?.list_name}
                        <AiOutlineCloseSquare
                          size={20}
                          onClick={() => {
                            RemoveList(list);
                          }}
                        />
                      </p>
                    );
                  })}
                </div>
                {/*------------------------ Display the lists that are added to the contact and also newly added through selection box------------------------------------  */}

                {/* ----------------------------------Search lists from filtered all lists from selection box below -----------------------------------------*/}
                <input
                  className="form-control fs-10"
                  ref={SearchContact}
                  placeholder="Add"
                  onFocus={(evt) => {
                    DisplayAllLists.current.style.display = "block";
                  }}
                  onChange={(evt) => {
                    setSearchContactField(evt.target.value);
                  }}
                  // onBlur={(evt) => {
                  //   DisplayAllLists.current.style.display = 'none';
                  // }}
                ></input>
                {/* ----------------------------------Search lists from filtered all lists from selection box below -----------------------------------------*/}

                {/*---------------------------- Display All lists filtered with exception from lists that are added already to the contact --------------------------*/}
                <div
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                    display: "none",
                  }}
                  ref={DisplayAllLists}
                >
                  {allLists.length > 0 &&
                    allLists?.map((filteredList) => {
                      return (
                        <>
                          <p
                            role="button"
                            className="fw-semibold text-secondary fs-10"
                            onClick={(e) => {
                              let value = allLists.filter((list) => {
                                return list?.list_id != filteredList?.list_id;
                              });

                              setAllLists([...value]);
                              setState({
                                type: "LISTS_STATE",
                                value: [
                                  ...state?.AddLists?.Lists,
                                  filteredList,
                                ],
                              });
                            }}
                          >
                            {filteredList?.list_name}
                          </p>
                        </>
                      );
                    })}
                </div>
                {/*---------------------------- Display All lists filtered with exception from lists that are added already to the contact --------------------------*/}
              </div>
              <div className="d-flex justify-content-between py-2 px-2">
                <button // Cancel button to close the update list popup ------------------------------------------------------------
                  className="d-flex justify-content-center align-items-center flex-fill btn btn-light rounded-0 fs-11"
                  onClick={() => {
                    setState({
                      type: "ADD_LISTS_MODAL_STATE",
                      value: false,
                    });
                  }}
                >
                  {t('contactsMenu.cancel')} 
                </button>
                <button // Save Button to update the list attached through API request -------------------------------------------------
                  className="d-flex justify-content-center  align-items-center flex-fill btn btn-primary  rounded-0 fs-11"
                  onClick={() => {
                    if (
                      searchContactField.length > 0 &&
                      allLists.length === 0
                    ) {
                      setAllLists(SelectionList());
                    }
                    UpdateLists();
                  }}
                >
                  {t('contactsMenu.save')} 
                </button>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  },
);
