import React, { useState, useEffect } from "react";
import {
  AiFillCalendar,
  AiOutlineCalculator,
  AiOutlineCalendar,
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineFile,
  AiOutlineLink,
} from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { IoMdCopy } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import {
  renderFieldValue,
  useDateTime,
  useQRScanData,
} from "../../../functions/functions";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
//API SERVICES
import { HistoryForQrcode_API } from "../../../services/userservices";

export const ScannedData = ({ dependencies: { state, setState } }) => {
  const date = useDateTime();
  const navigate = useNavigate();
  const data = useQRScanData(state?.Scanner?.url);
  const [contactInfo, setContactInfo] = useState({});
  console.log(contactInfo, "contactInfo");
  console.log(data?.dataType, "data?.dataType");

  useEffect(() => {
    if (data?.dataType === "vcard") {
      setContactInfo(data?.content);
    }
  }, []);

  const AddToContact_Fn = () => {
    setState({
      type: "UPDATE_SCANNED_LINK",
      url: "",
      data: {},
      date: "",
      time: "",
    });
    localStorage.setItem("AddToContact", JSON.stringify(data?.content));
    navigate("/leads");
  };
// Function to parse vCalendar string and trigger calendar action
const handleVCalendar = (vCalendarString) => {
  // Parse vCalendar string to extract event details
  const parsedEvent = parseVCalendar(vCalendarString); // Implement parseVCalendar function as needed

  // Check if the parsed event has necessary details
  if (parsedEvent && parsedEvent.summary && parsedEvent.startDate && parsedEvent.endDate) {
    // Construct a calendar event object
    const calendarEvent = {
      summary: parsedEvent.summary,
      location: parsedEvent.location,
      description: parsedEvent.description,
      start: moment(parsedEvent.startDate, 'YYYYMMDDTHHmmss').toDate(),
      end: moment(parsedEvent.endDate, 'YYYYMMDDTHHmmss').toDate(),
    };

    // Attempt to add the event to the user's default calendar
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For Internet Explorer
      const blob = new Blob([calendarEvent.toString()]);
      window.navigator.msSaveOrOpenBlob(blob, 'calendarEvent.vcs');
    } else {
      // For modern browsers
      const calendarEventBlob = new Blob([calendarEvent.toString()], { type: 'text/calendar;charset=utf-8' });
      const calendarEventURL = window.URL.createObjectURL(calendarEventBlob);

      // Trigger download or add to calendar
      const link = document.createElement('a');
      link.href = calendarEventURL;
      link.setAttribute('download', 'calendarEvent.ics');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    console.error('Invalid vCalendar format or missing required fields.');
    // Handle error or provide feedback to the user
  }
};

// Example of parsing vCalendar string (basic implementation)
const parseVCalendar = (vCalendarString) => {
  const lines = vCalendarString.split(/\r\n|\r|\n/);
  let event = {};

  for (let line of lines) {
    const [key, value] = line.split(':');
    switch (key) {
      case 'BEGIN':
        // Handle BEGIN, END, and other properties as needed
        break;
      case 'SUMMARY':
        event.summary = value;
        break;
      case 'DTSTART':
        event.startDate = value;
        break;
      case 'DTEND':
        event.endDate = value;
        break;
      case 'LOCATION':
        event.location = value;
        break;
      case 'DESCRIPTION':
        event.description = value;
        break;
      default:
        break;
    }
  }

  return event;
};
 const evenData = parseVCalendar(state?.Scanner?.url)
  return (
    <>
      <div
        className="d-flex flex-column align-items-center shadow px-4 py-5 rounded-2 mx-auto bg-white"
        style={{ gap: "20px", maxWidth: "400px" }}
      >
        <div className="d-flex w-100 justify-content-between align-items-center text-primary">
          <AiOutlineClose
            size={30}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState({
                type: "UPDATE_SCANNED_LINK",
                url: "",
                data: {},
                date: "",
                time: "",
              });
            }}
          />
          <div className="d-flex align-items-center justify-content-center rounded-circle p-3 text-primary">
            {data?.dataType === "vcard" ? (
              <>
                <div onClick={AddToContact_Fn} className="btn btn-default">
                  <center>
                    <AiOutlineFile size={30} />
                    <p>Edit & Save</p>
                  </center>
                </div>
              </>
            ) :data?.dataType === "event" ? (<AiOutlineCalendar size={30} />): (
              <AiOutlineLink size={30} />
            )}
          </div>
          {/* <TiTick
            size={30}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              AddToHistory();
            }}
          /> */}
        </div>
        {(data?.dataType !== "vcard" && data?.dataType !== "event") ? (
          <a
            className="  text-wrap text-break mb-0 fw-semibold px-5 "
            style={{ cursor: "pointer" }}
          >
            {state?.Scanner?.url}
          </a>
        ) : null}
{data?.dataType == "event" ? (
          <div className="table-responsive">
          <table className="table ">
            <tbody>
              <tr>
                
                <td style={{ overflowWrap: "anywhere" }}>
                <th scope="row">Event Summary </th> 
                  {evenData?.summary}
                </td>
              </tr>
              <tr>
                
                <td style={{ overflowWrap: "anywhere" }}>
                <th scope="row">Start </th> 
                  {moment(evenData?.startDate, 'YYYYMMDDTHHmmss').toDate().toString()}
                </td>
              </tr>
              <tr>
             
                <td style={{ overflowWrap: "anywhere" }}>
                <th scope="row">End </th>
                {moment(evenData?.endDate, 'YYYYMMDDTHHmmss').toDate().toString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        ) : null}
        {data?.dataType === "vcard" ? (
          <div className="table-responsive">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">First Name : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "firstName")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last Name : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "lastName")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">organization : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "organization")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Designation : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "designation")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email Address : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "email")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Contact No : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "contactNo")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Address : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "address")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">City : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "city")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">State : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "state")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Country : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "country")}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Pincode : </th>
                  <td style={{ overflowWrap: "anywhere" }}>
                    {renderFieldValue(contactInfo, "pincode")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}

        <div className="d-flex " style={{ gap: "10px" }}>
        {(data?.dataType !== "vcard" && data?.dataType !== "event") &&
          <button
            className="d-flex justify-content-between align-items-center btn btn-outline-primary rounded-1 shadow"
            style={{ gap: "5px" }}
            onClick={() => {
              let link = document.getElementsByTagName("a");
              navigator.clipboard.writeText(link?.[0].innerHTML);
            }}
          >
            <IoMdCopy />
            <p className="mb-0 fw-semibold ">COPY</p>
          </button>}
          <button
            className="d-flex justify-content-between align-items-center btn btn-outline-primary rounded-1 shadow"
            style={{ gap: "5px" }}
            onClick={() => {
              if (data?.dataType !== "vcard" && data?.dataType !== "event") {
                window.open(state?.Scanner?.url, "_blank");
              }
            }}
          >
            <BiLinkExternal />

            {data?.dataType === "vcard" ? (
  <p className="mb-0 fw-semibold" onClick={AddToContact_Fn}>
    ADD TO CONTACTS
  </p>
) : data?.dataType === "event" ? (
  <p className="mb-0 fw-semibold" onClick={(() =>handleVCalendar(state?.Scanner?.url))}>
    SAVE EVENT
  </p>
) : (
  <p className="mb-0 fw-semibold">OPEN LINK</p>
)}
          </button>
        </div>
      </div>
    </>
  );
};
