import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { Create_List } from "../services/userservices";
import { Menu } from "../functions/menu";
import { useWindowSize } from "../functions/functions";
import { t } from "i18next";

const ListForm = () =>
{
  const navigate = useNavigate();
  const location = useLocation();
  const { action, initialValues } = location.state || {};
  const size = useWindowSize();
  const [ listName, setListName ] = useState( "" );
  const [ listDescription, setListDescription ] = useState( "" );
  const [ listType, setListType ] = useState( "0" );
  const [ listColor, setListColor ] = useState( "#5411dc" );

  useEffect( () =>
  {
    if ( initialValues )
    {
      setListName( initialValues.list_name || "" );
      setListDescription( initialValues.list_description || "" );
      setListColor( initialValues.list_color || "" );
      setListType(initialValues.list_type || "0")
    }
  }, [ initialValues ] );

  const handleAdd = () =>
  {
    const payload = {
      action: "add",
      uid: JSON.parse( localStorage.getItem( "user_id" ) ),
      list_name: listName,
      list_description: listDescription,
      list_color: listColor,
      list_type: listType,
    };
    Create_List( payload )
      .then( ( res ) =>
      {
        if ( res?.status === "success" )
        {
          toast.success( res.message, {
            onClose: () =>
            {
              navigate( "/showleads", { state: { key: "List" } } );
            },
          } );
        } else
        {
          toast.error( res.message );
        }
      } )
      .catch( ( error ) =>
      {
        console.error( "Error creating list:", error );
      } );
  };

  const handleUpdate = () =>
  {
    const payload = {
      action: "update",
      uid: JSON.parse( localStorage.getItem( "user_id" ) ),
      list_name: listName,
      list_description: listDescription,
      list_id: initialValues.list_id,
      list_color: listColor,
      list_type: listType,
    };
    Create_List( payload )
      .then( ( res ) =>
      {
        if ( res?.status === "success" )
        {
          toast.success( res.message, {
            autoClose: "1",
            onClose: () =>
            {
              navigate( "/showleads", { state: { key: "List" } } );
            },
          } );
        } else
        {
          toast.error( res.message );
        }
      } )
      .catch( ( error ) =>
      {
        console.error( "Error updating list:", error );
      } );
  };

  const deleteList = () =>
  {
    const payload = {
      action: "delete",
      list_id: initialValues.list_id,
      uid: JSON.parse( localStorage.getItem( "user_id" ) ),
    };
    Create_List( payload )
      .then( ( res ) =>
      {
        if ( res?.status === "success" )
        {
          toast.success( res.message, {
            autoClose: "1",
            onClose: () =>
            {
              navigate( "/showleads", { state: { key: "List" } } );
            },
          } );
        } else
        {
          toast.error( res.message );
        }
      } )
      .catch( ( error ) =>
      {
        console.error( "Error deleting list:", error );
      } );
  };

  const handleSubmit = ( e ) =>
  {
    e.preventDefault();
    if ( action === "add" )
    {
      handleAdd();
    } else if ( action === "update" )
    {
      handleUpdate();
    }
  };

  const goBack = () =>
  {
    navigate( "/showleads", { state: { key: "List" } } );
  };

  if ( !action )
  {
    return <div>Error: Action not provided</div>;
  }

  return (
    <>
      <div className=" m-0 p-0 container-fluid vh-100 bg-primary">
        <div className="row justify-content-center m-0 p-0">
          <div className="w-100 m-0 p-0">
            <div className="card-header m-0 p-2 sticky-top" style={ { backgroundColor: "#5411dc", color: "white" } }>
              <div className="d-flex justify-content-between align-items-center">
                <FaChevronLeft size={ 25 } onClick={ goBack } style={ { cursor: "pointer" } } />
                <p className="m-0 fw-semibold fs-4">{ action === "add" ? t( 'contactsMenu.newList' ) : t( 'contactsMenu.updateList' ) }</p>
                <div className="d-flex" style={ { width: "25px" } }></div>
              </div>
            </div>
            <div className="card vh-100">

              <div className="card-body col-lg-8 d-flex justify-content-center  w-100 overflow-auto">
                <form onSubmit={ handleSubmit } className="mb-3 w-lg-45-inline" >
                  <div className="mb-3">
                    <label className="form-label fw-semibold text-primary">{ t( 'contactsMenu.listName' ) } <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" value={ listName } onChange={ ( e ) => setListName( e.target.value ) } required />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-semibold text-primary">{ t( 'contactsMenu.listDescription' ) } <span className="text-danger">*</span></label>
                    <textarea className="form-control" rows={ 2 } value={ listDescription } onChange={ ( e ) => setListDescription( e.target.value ) } required />
                  </div>

                  <div className="mb-3">
                    <label className="form-label fw-semibold text-primary">{ t( 'contactsMenu.listColor' ) }</label>
                    <div className="d-flex align-items-center">
                    <input type="color" className=" p-0 rounded w-25 " id="colorpickr" value={ listColor } onChange={ ( e ) => setListColor( e.target.value ) } />
                    <input type="text" className="form-control w-75 rounded" value={ listColor } disabled /></div>
                  </div>
                  <div className="mb-3">
                  <label className="form-label fw-semibold text-primary">{ t( 'contactsMenu.listType' ) } </label>
                  <select class="form-select" aria-label="Default select example" value={ listType } onChange={ ( e ) => setListType( e.target.value ) }>
  <option selected value="0">General</option>
  <option value="1">Event (People met at event)</option>
  <option value="2">Association (Association members)</option>
  <option value="3">Location (People in a region)</option>
  <option value="4">Community</option>
</select>
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary me-2">{ action === "add" ? t( 'contactsMenu.add' ) : t( 'contactsMenu.update' ) }</button>
                    { action === "update" && (
                      <button type="button" className="btn btn-outline-primary" onClick={ deleteList }>{ t( 'contactsMenu.delete' ) }</button>
                    ) }
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={ 500 } position="bottom-center" hideProgressBar />
      <Menu />
    </>
  );
};

export default ListForm;
