import React from "react";
import { useWindowSize } from "../../../functions/functions";

const LoaderUI = () => {
  const size = useWindowSize();
  const style = {
    position: "absolute",
    backgroundColor: "",
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.53)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(17.3px)",
    WebkitBackdropFilter: "blur(17.3px)",
  };
  return (
    <>
      <div style={style}>
        <div
          class="spinner-border text-light "
          role="status"
          style={{ width: "200px", height: "200px" }}
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <p className="mb-0 fw-semibold fs-8 text-light">processing ...</p>
      </div>
    </>
  );
};

export default LoaderUI;
