import React, { useState } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import moment from "moment";
import { Box } from "@mui/material";
import { downloadFile } from "../../../services/userservices";

export const StoryTile = ({ story, dependencies: { state, setState } }) => {
  const [loaded, setLoaded] = useState(false);

  const convertDate = (dateStr) => {
    const formattedDate = moment(dateStr).format("DD-MMM-YYYY");
    return formattedDate;
  };

  return (
    <ImageListItem key={story?.story_img} sx={{ minHeight: "100px" }}>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          height: loaded ? "auto" : "300px",
        }}
      >
        <img
          onClick={() => {
            setState({ type: "UPDATE_VIEW_STORY_STATE", story: story });
            setState({ type: "UPDATE_VIEW_MODAL_OPEN", view: true });
          }}
          srcSet={story?.story_img}
          src={story?.story_img}
          alt={story?.name}
          loading="lazy"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: loaded ? "none" : "blur(10px)",
            opacity: loaded ? 1 : 0.5,
            transition: "filter 0.5s ease-out, opacity 0.5s ease-out",
          }}
          onLoad={() => setLoaded(true)}
        />
      </div>
      <ImageListItemBar
        title={story?.name}
        subtitle={convertDate(story?.date_added)}
        actionIcon={
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <AvatarGroup
              color="secondary"
              className="me-2"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              max={3}
            >
              {story?.tagged_users.map((user, index) => (
                <Avatar
                  key={index}
                  alt={user.tag_name}
                  src="/static/images/avatar/1.jpg"
                />
              ))}
            </AvatarGroup>
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              aria-label={`info about ${story?.name}`}
              onClick={async () => {
                if (navigator.share) {
                  try {
                    await downloadFile(story);
                  } catch (error) {
                    console.error("Error sharing:", error);
                  }
                } else {
                  // Fallback if navigator.share is not supported
                }
              }}
            >
              <RiShareForwardFill />
            </IconButton>
          </Box>
        }
      />
    </ImageListItem>
  );
};
