// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
import en from './en/translation.json';
import fr from './fr/translation.json';
import de from './de/translation.json';
import ar from './ar/translation.json';
import ta from './ta/translation.json';
import hi from './hi/translation.json';

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      de: { translation: de },
      ar: { translation: ar },
      ta: { translation: ta },
      hi: { translation: hi },
    },
    lng: savedLanguage, // use saved language or default to 'en'
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
