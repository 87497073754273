import React, {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";

const Filterby = memo(({ reference, showLeads, state, setState, fromList }) => {
  const DeferredContacts = useDeferredValue(
    JSON.parse(localStorage.getItem("DisplayLeads")),
  );
  const [fromListID, setFromListID] = useState(fromList.listId);
  const [selectAllChecked, setSelectAllChecked] = useState(false); // Track the state of "select all" checkbox
  useEffect(() => {
    if (fromListID != null) {
      setState({
        type: "UPDATE_LISTS_FILTER",
        value: fromList.listId,
        event: "checked",
      });
      const checkbox = document.getElementById(`list_${fromListID}`);
      if (checkbox) {
        checkbox.checked = true;
      }
    }
  }, []);
  const tags = useMemo(() => {
    let AllTaggedContacts = [];
    Object.keys(DeferredContacts).map((lead) => {
      if (DeferredContacts[lead]?.tagged_contacts.length > 0) {
        DeferredContacts[lead]?.tagged_contacts.map((tag) => {
          AllTaggedContacts.push(tag);
        });
      }
    });
    return AllTaggedContacts.reduce((acc, curr) => {
      if (!acc.find((tag) => tag.tag_id == curr.tag_id)) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }, [DeferredContacts]);

  const lists = useMemo(() => {
    let AllListsContacts = [];
    Object.keys(DeferredContacts).map((lead) => {
      if (DeferredContacts[lead]?.contacts_lists.length > 0) {
        DeferredContacts[lead]?.contacts_lists.map((tag) => {
          AllListsContacts.push(tag);
        });
      }
    });

    return AllListsContacts.reduce((acc, curr) => {
      if (!acc.find((list) => list.list_id == curr.list_id)) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }, [DeferredContacts]);
  return (
    <>
      <div
        ref={reference}
        className="d-none container w-100 p-3 flex-column align-items-start justify-content-center bg-light rounded-2 border mb-3"
        style={{
          transition: "all 0.3s ease-in-out ",
        }}
      >
        <p className=" fw-semibold fs-10">FILTER BY</p>
        <ul className="mb-0 p-0 w-100" style={{ listStyle: "none" }}>
          <li className="mb-1 mt-1">
            <p className="fs-11 fw-semibold text-secondary ">TAGS</p>

            <ul
              className="rounded-2 bg-white border border-2 py-1 d-flex flex-wrap"
              style={{
                listStyle: "none",
                maxHeight: "110px",
                overflowY: "auto",
              }}
            >
              {tags.length > 0 ? (
                tags.map((tag, index) => {
                  return (
                    <>
                      <div key={tag?.tag_id} className="form-check pe-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`tag_${tag?.tag_id}`}
                          onChange={(evt) => {
                            if (evt.target.checked) {
                              setState({
                                type: "UPDATE_TAGS_FILTER",
                                value: tag?.tag_id,
                                event: "checked",
                              });
                            } else {
                              setState({
                                type: "UPDATE_TAGS_FILTER",
                                value: tag?.tag_id,
                                event: "unchecked",
                              });
                            }
                          }}
                        />
                        <label
                          className="form-check-label fw-normal fs-11"
                          htmlFor={`${tag?.tag_name}_${tag?.tag_id}`}
                        >
                          {tag?.tag_name}
                        </label>
                      </div>
                    </>
                  );
                })
              ) : (
                <p className="mb-0 fw-semibold fs-11 fst-italic">
                  No tags to filter
                </p>
              )}
            </ul>
          </li>
          <li className="mb-1 mt-1">
            <p className="fs-11 fw-semibold text-secondary">LISTS</p>

            <ul
              className="rounded-2 bg-white border border-2 py-1 d-flex flex-wrap"
              style={{
                listStyle: "none",
                maxHeight: "110px",
                overflowY: "auto",
              }}
            >
              {lists.length > 0 ? (
                lists.map((list) => {
                  return (
                    <>
                      <div key={list?.list_id} className="form-check pe-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`list_${list?.list_id}`}
                          // checked={fromListID !== null && fromListID === list?.list_id}
                          onChange={(evt) => {
                            if (evt.target.checked) {
                              setState({
                                type: "UPDATE_LISTS_FILTER",
                                value: list?.list_id,
                                event: "checked",
                              });
                              // setFromListID(null)
                            } else {
                              setState({
                                type: "UPDATE_LISTS_FILTER",
                                value: list?.list_id,
                                event: "unchecked",
                              });
                              // setFromListID(null)
                            }
                          }}
                        />
                        <label
                          className="form-check-label fw-normal fs-11"
                          htmlFor={`${list?.list_name}_${list?.list_id}`}
                        >
                          {list?.list_name}
                        </label>
                      </div>
                    </>
                  );
                })
              ) : (
                <p className="mb-0 fw-semibold fs-11 fst-italic">
                  No lists to filter
                </p>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
});

export default Filterby;
