import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { MdFlipCameraIos } from "react-icons/md";
import { useCamera, useWindowSize } from "../../functions/functions";
import { useNavigate } from "react-router-dom";

import {
  StoreBcardImageData,
  SetPresentDisplay,
} from "../../state_services/actions/ocr/OCRActions";
import { BCARD_IMAGE_CROP } from "../../state_services/actionTypes/ocr/OCRActionTypes";
import UpgradePlan from "../../functions/UpgradePlan";

export const CameraOCR = (props) => {
  const { cameraFacingMode, switchCameraFacingMode, imageData, captureImage } =
    useCamera();
  let navigate = useNavigate();
  const [pageLoader, setPageLoader] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPageLoader(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (imageData !== "") {
      let _Object = props?.data?.state?.BCARD_IMAGE;
      _Object.URL = imageData;
      props?.data?.dispatch(StoreBcardImageData(_Object));
      props?.data?.dispatch(SetPresentDisplay(BCARD_IMAGE_CROP));
    }
  }, [imageData]);

  const size = useWindowSize();
  return (
    <>
      <div
        className="d-flex flex-column align-items-center"
        style={{ overflow: "hidden" }}
      >
        <FaChevronLeft
          size={40}
          className="text-primary"
          style={{
            position: "absolute",
            top: "20px",
            left: "20px",
            cursor: "pointer",
            zIndex: 2,
          }}
          onClick={() => {
            navigate("/dashboard");
          }}
        />
        {JSON.parse(localStorage.getItem("config_settings"))
          .business_card_scanner_enabled === "No" ? (
          pageLoader ? (
            <>
              <div
                className="d-flex w-100 bg-white align-items-center justify-content-center"
                style={{ height: size.height }}
              >
                <div
                  className="spinner-border text-primary "
                  role="status"
                  style={{ width: "200px", height: "200px" }}
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <UpgradePlan />
          )
        ) : (
          <>
            {pageLoader ? (
              <>
                <div
                  className="d-flex w-100 bg-white align-items-center justify-content-center"
                  style={{ height: size.height }}
                >
                  <div
                    className="spinner-border text-primary "
                    role="status"
                    style={{ width: "200px", height: "200px" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <MdFlipCameraIos
                  size={50}
                  className="text-primary"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                  onClick={() => {
                    switchCameraFacingMode();
                  }}
                />
                <div style={{ overflow: "hidden" }}>
                  <video
                    width="100%"
                    height={size.height ? size.height : "100%"}
                    style={{ objectFit: "cover" }}
                  />
                  <canvas
                    style={{ display: "none", opacity: 0, overflow: "hidden" }}
                  />
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle border border-2 border-white"
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    width: "80px",
                    height: "80px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    captureImage();
                  }}
                >
                  <div
                    className="rounded-circle bg-white"
                    style={{ width: "65px", height: "65px" }}
                  ></div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
