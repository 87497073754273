import React from "react";
import { useWindowSize } from "./functions";


const NotFoundPage = () => {
  const size = useWindowSize();

  return (
    <div
      className="d-flex w-100 flex-column justify-content-start mb-2 align-items-center bg-white rounded-2"
      style={{
        height: size.height - 100,
      }}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center h-100"
        style={{ gap: "20px" }}
      >
        <div className="d-flex flex-column rounded-2  p-3 bg-white mx-2">
          <h4 className="fw-bold fs-4 fs-md-1   text-center lh-base mb-4">
           404 Not Found
          </h4>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
