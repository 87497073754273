import React, { useEffect } from "react";
import { useCamera, useWindowSize } from "../../functions/functions";
import { MdFlipCameraIos } from "react-icons/md";
import { Modal } from "../../functions/Modal";
import { BsCameraFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { FaChevronLeft } from "react-icons/fa";
import { CapturedPhoto } from "./components/CapturedPhoto";

export const Camera = ({ dependencies: { state, setState } }) => {
  const { cameraFacingMode, switchCameraFacingMode, imageData, captureImage } =
    useCamera();

  const size = useWindowSize();

  return (
    <>
      <div
        className="d-flex flex-column align-items-center "
        style={{
          overflow: "hidden",
          position: "relative",
          height: size.height,
        }}
      >
        <video
          width={size.width ? size.width : "100%"}
          height={size.height}
          style={{ objectFit: "cover" }}
        />
        <canvas style={{ display: "none", opacity: 0, overflow: "hidden" }} />

        <FaChevronLeft
          size={40}
          className="text-primary"
          style={{
            position: "absolute",
            top: "20px",
            left: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            setState({ type: "UPDATE_SCREEN", screen: "StoriesList" });
          }}
        />
        <MdFlipCameraIos
          size={50}
          className="text-primary"
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            switchCameraFacingMode();
          }}
        />
        <div
          className="d-flex justify-content-center align-items-center rounded-circle border border-2 border-white"
          style={{
            position: "absolute",
            bottom: "15px",
            width: "80px",
            height: "80px",
            cursor: "pointer",
          }}
          onClick={() => {
            captureImage();
            setState({ type: "UPDATE_CAPTURE_MODAL_OPEN", image: true });
          }}
        >
          <div
            className="rounded-circle bg-white"
            style={{ width: "65px", height: "65px" }}
          ></div>
        </div>
      </div>
      {state?.isCaptureImageModalOpen === true ? (
        <Modal>
          <CapturedPhoto
            dependencies={{
              state: state,
              setState: setState,
            }}
            imageData={imageData}
          />
        </Modal>
      ) : null}
    </>
  );
};
