import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CameraOCR } from "../screens/OCR/CameraOCR";
import { Review } from "../screens/OCR/Review";
import { ImageCrop } from "../screens/OCR/ImageCrop";
import { Preview } from "../screens/OCR/Preview";

import {
  CAPTURE_BCARD_IMAGE,
  BCARD_IMAGE_CROP,
  BCARD_IMAGE_PREVIEW,
  BCARD_DATA_REVIEW,
} from "../state_services/actionTypes/ocr/OCRActionTypes";
import { SetPresentDisplay } from "../state_services/actions/ocr/OCRActions";
import UpgradePlan from "../functions/UpgradePlan";
import { useWindowSize } from "../functions/functions";

export const OCR = () => {
  const state = useSelector((state) => state.OCR);
  const size = useWindowSize();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SetPresentDisplay(CAPTURE_BCARD_IMAGE));
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <div
          className="d-flex justify-content-center "
          style={{
            height: size.height,
            // maxWidth: '450px',
            position: "relative",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {state?.DISPLAY === CAPTURE_BCARD_IMAGE && (
            <CameraOCR
              data={{
                state: state,
                dispatch: dispatch,
              }}
            />
          )}
          {state?.DISPLAY === BCARD_IMAGE_CROP && (
            <ImageCrop
              data={{
                state: state,
                dispatch: dispatch,
              }}
            />
          )}
          {state?.DISPLAY === BCARD_IMAGE_PREVIEW && (
            <Review
              data={{
                state: state,
                dispatch: dispatch,
              }}
            />
          )}
          {state?.DISPLAY === BCARD_DATA_REVIEW && (
            <Preview
              data={{
                state: state,
                dispatch: dispatch,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
