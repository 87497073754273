import React from "react";
import { IoMdPersonAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateNewLead_API } from "../../../services/userservices";
export const FinalData = (props) => {
  const navigate = useNavigate();
  const finalData = props?.data?.FinalData;
  console.log(finalData);
  const imageData = props?.data?.Crop_Base64Image;
  const LeadsFormData = {
    user: [
      {
        first_name: finalData?.[0]?.value,
        last_name: finalData?.[1]?.value,
        company: finalData?.[6]?.value,
        phone_no: [finalData?.[3]?.value],
        email_address: [finalData?.[4]?.value],
        list_id: [...finalData?.[7]?.value[0]],
        desigination: finalData?.[2]?.value,
        country: "india",
        status: "1",
        key: "4",
        source: "leads-mobileapp",
        uid: JSON.parse(localStorage.getItem("user_id")),
        businesscard_frontimage_scandata: imageData,
      },
    ],
  };
  const AddToContact_Fn = () => {
    CreateNewLead_API(LeadsFormData).then((res) => {
      if (Array.isArray(res)) {
        res.map(function (object, index) {
          if (object.status.localeCompare("success") == 0) {
            toast.success("Contacts saved successfully!..", {
              icon: false,
              onClose: () => {
                navigate("/showleads");
              },
            });
          } else {
            toast.error(`${object.message}`, {
              icon: false,
              onClose: () => {
                // console.log('close');
              },
            });
          }
        });
      }
    });
  };
  return (
    <>
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          theme="colored"
        />
      </div>
      <div
        className="d-flex flex-column  rounded-2 bg-white py-3 px-3 shadow-lg my-2"
        style={{ width: "95%", maxWidth: "450px", gap: "15px" }}
      >
        {finalData.map((data, index) => {
          // console.log(typeof data?.value == 'object' ? data?.value[1] : null);
          return (
            <>
              <div
                className="d-flex flex-column align-items-start rounded-2 shadow px-2 py-2 border border-primary"
                style={{ gap: "5px" }}
                key={index + "_FinalDataDiv"}
              >
                <p className="mb-0 fw-semibold fs-10 text-secondary">
                  {data?.opt_text}
                </p>
                {data?.opt_text != "Lists" ? (
                  data?.value !== "" &&
                  data?.value !== null &&
                  typeof data?.value !== "undefined" ? (
                    <p
                      className="mb-0 fw-normal fs-11 p-2 rounded-2 text-white"
                      style={{ backgroundColor: "#999999" }}
                    >
                      {data?.value}
                    </p>
                  ) : null
                ) : null}
                <div className="d-flex gap-2">
                  {data?.opt_text == "Lists"
                    ? data?.value?.[1].length > 0
                      ? data?.value[1].map((value) => {
                          return (
                            <p
                              className="mb-0 fw-normal fs-11 p-2 rounded-2 text-white"
                              style={{ backgroundColor: "#999999" }}
                            >
                              {value}
                            </p>
                          );
                        })
                      : null
                    : null}
                </div>
              </div>
            </>
          );
        })}
        <button
          className="d-flex align-items-center justify-content-center btn btn-primary fw-semibold"
          style={{ gap: "10px" }}
          onClick={AddToContact_Fn}
        >
          <p className="mb-0 fw-semibold fs-9 ">SAVE CONTACT</p>
          <IoMdPersonAdd size={20} />
        </button>
      </div>
    </>
  );
};
