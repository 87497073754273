import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { FormOCR } from "./components/FormOCR";
import { ShowHideImage } from "./components/ShowHideImage";

import { CAPTURE_BCARD_IMAGE } from "../../state_services/actionTypes/ocr/OCRActionTypes";
import { SetPresentDisplay } from "../../state_services/actions/ocr/OCRActions";
import { t } from "i18next";

export const Review = (props) => {
  const image_Data = props?.data?.state?.BCARD_IMAGE;

  const Goback_Fn = () => {
    props?.data?.dispatch(SetPresentDisplay(CAPTURE_BCARD_IMAGE));
  };

  return (
    <>
      <div
        className="d-flex flex-column  align-items-center w-100 bg-transparent mb-5"
        style={{ minHeight: "100%" }}
      >
        <div
          className="d-flex w-100 text-start align-items-center justify-content-between py-3 px-3 bg-primary"
          style={{ color: "white", fontSize: "2em" }}
        >
          <FaChevronLeft
            size={25}
            style={{ cursor: "pointer" }}
            onClick={Goback_Fn}
          />
          <p className="m-0 fw-semibold fs-4">{t('cardScannerMenu.review')}</p>
          <p className="m-0 " style={{ width: "20px" }}></p>
        </div>
        <ShowHideImage data={image_Data} />
        <FormOCR
          data={{
            state: props?.data?.state,
            dispatch: props?.data?.dispatch,
          }}
        />
      </div>
    </>
  );
};
