import React, { memo, useEffect, useState } from "react";
import { AiOutlineCalendar, AiOutlineLink } from "react-icons/ai";
import { FaChevronLeft, FaTrash, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import { DeleteConformationModal } from "./components/DeleteConformationModal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
//API SERVICES
import { HistoryForQrcode_API } from "../../services/userservices";
import { MdOutlineContactPage } from "react-icons/md";
import { t } from "i18next";

export const History = memo(({ dependencies: { state, setState } }) => {

  const [clickStatus, setClickStatus] = useState(false);
  const [loader, setLoader] = useState(true);
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    const PayloadFormData = new FormData();
    const U_ID = JSON.parse(localStorage.getItem("user_id"));
    PayloadFormData.append("action", "list_contact_qr_code");
    PayloadFormData.append("uid", U_ID);
    HistoryForQrcode_API(PayloadFormData).then((res) => {
      // console.log('res', res.data);
      setLoader(false);
      setState({
        type: "FETCH_HISTORY_FROM_API",
        ScannerHistory: res?.data,
      });
    });
  }, []);

  const Delete_Fn = () => {
    setClickStatus(!clickStatus);
  };

  const IndividualDelete_Fn = (event, DeleteId) => {
    setState({
      type: "HISTROY_DELETE_MODAL_SHOW_STATUS",
      HistroyDelete: {
        ModalStatus: true,
        DeleteId: DeleteId,
      },
    });
  };

  const DeleteAllHistroy_Fn = () => {
    const PayloadFormData = new FormData();
    const U_ID = JSON.parse(localStorage.getItem("user_id"));
    PayloadFormData.append("action", "deleted_contact_qr_code");
    PayloadFormData.append("uid", U_ID);
    PayloadFormData.append("qr_code_id", -1);
    PayloadFormData.append("delete_data_by", "all");
    HistoryForQrcode_API(PayloadFormData).then((res) => {
      if (res?.status?.localeCompare("success") == 0) {
        setState({
          type: "FETCH_HISTORY_FROM_API",
          ScannerHistory: {},
        });
      }
    });
  };

  const IsJsonString = (str) => {
    try {
      var json = JSON.parse(str);
      if (typeof json === "object") {
        return json;
      }
    } catch (e) {
      return false;
    }
  };

  const parseVCalendar = (vCalendarString) => {
    const lines = vCalendarString.split(/\r\n|\r|\n/);
    let event = {};
  
    for (let line of lines) {
      const [key, value] = line.split(':');
      switch (key) {
        case 'BEGIN':
          // Handle BEGIN, END, and other properties as needed
          break;
        case 'SUMMARY':
          event.summary = value;
          break;
        case 'DTSTART':
          event.startDate = value;
          break;
        case 'DTEND':
          event.endDate = value;
          break;
        case 'LOCATION':
          event.location = value;
          break;
        case 'DESCRIPTION':
          event.description = value;
          break;
        default:
          break;
      }
    }
  
    return event;
  };

  return (
    <>
      <div
        className="container-lg d-flex flex-column min-vh-100 bg-primary "
        style={{ gap: "20px" }}
      >
        <div className="d-flex w-100  justify-content-between align-items-center px-2 text-white py-3">
          <FaChevronLeft
            size={25}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState({ type: "UPDATE_SCREEN", screen: "Scanner" });
            }}
          />
          <p className="mb-0 fw-semibold fs-3 text-white">{t('qrMenu.history')}</p>
          {Object.keys(state?.ScannerHistory).length > 0 ? (
            <div
              className="d-flex align-items-center justify-content-center "
              onClick={Delete_Fn}
              style={{ gap: "10px" }}
            >
              <FaTrash style={{ cursor: "pointer" }} />
              <button
                type="button"
                className="btn btn-light btn-sm text-secondary fw-semibold fs-11"
                style={{
                  display: `${
                    clickStatus && Object.keys(state?.ScannerHistory).length > 0
                      ? "block"
                      : "none"
                  }`,
                }}
                onClick={DeleteAllHistroy_Fn}
              >
                Delete All History
              </button>
            </div>
          ) : (
            <p className="mb-0"></p>
          )}
        </div>
        <div
          className="d-flex flex-column w-100 align-items-center pb-4"
          style={{ gap: "10px" }}
        >
          {loader ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="warning" />
            </Box>
          ) : (
            <>
              {Object.keys(state?.ScannerHistory).length > 0 ? (
                Object.keys(state?.ScannerHistory).map((date, index) => {
                  return (
                    <motion.div
                      variants={container}
                      initial="hidden"
                      animate="visible"
                      className="d-flex flex-column w-100 align-items-center"
                      style={{ gap: "10px" }}
                      key={index}
                    >
                      <motion.p
                        variants={item}
                        className="mb-0 text-wrap text-white fw-semibold fs-7"
                      >
                        {date}
                      </motion.p>
                      {state?.ScannerHistory?.[date].map((data, index) => {
                        let ParseContactInfo = "",
                          ValidJson = false;
                        if (
                          typeof data?.dataType != "undefined" &&
                          data?.dataType === "vcard"
                        ) {
                          ParseContactInfo = data?.content?.replace(
                            /\\"/g,
                            '"',
                          );
                          if (!IsJsonString(ParseContactInfo)) {
                            // console.log(
                            //   'Track:',
                            //   state?.ScannerHistory?.[date][index],
                            //   ParseContactInfo
                            // );
                          } else {
                            ValidJson = true;
                            ParseContactInfo = JSON.parse(ParseContactInfo);
                          }
                        }
                        return (
                          <>
                            {ValidJson ? (
                              <motion.div
                                variants={item}
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ gap: "15px" }}
                              >
                                <div
                                  className="d-flex w-100 align-items-center justify-content-start rounded-1 shadow px-3 py-3 bg-white"
                                  onClick={() => {
                                    setState({
                                      type: "UPDATE_HISTORY",
                                      history: { ...data },
                                    });
                                    setState({
                                      type: "UPDATE_SCREEN",
                                      screen: "IndividualHistory",
                                    });
                                  }}
                                  style={{
                                    gap: "10px",
                                    maxWidth: "400px",
                                    cursor: "pointer",
                                  }}
                                  key={index}
                                >
                                  {typeof data?.dataType != "undefined" &&
                                  data?.dataType === "vcard" ? (
                                    <MdOutlineContactPage
                                      size={35}
                                      style={{ color: "#555d66" }}
                                    />
                                  ) : data?.dataType === "event" ?(<AiOutlineCalendar
                                    size={35}
                                    style={{ color: "#555d66" }}
                                  />): (
                                    <AiOutlineLink
                                      size={35}
                                      style={{ color: "#555d66" }}
                                    />
                                  )}
                                  <div
                                    className="d-flex flex-column align-items-start "
                                    style={{ gap: "5px" }}
                                  >
                                    <a
                                      className={`text-break text-wrap fw-semibold fst-italic  fs-10 ${
                                        data?.dataType === "vcard"
                                          ? "text-decoration-none"
                                          : null
                                      }`}
                                      style={{
                                        cursor: "pointer",
                                        maxWidth: "300px",
                                      }}
                                    >
                                      {typeof data?.dataType != "undefined" &&
                                      data?.dataType === "vcard"
                                        ? ParseContactInfo?.fn?.[0]?.value?.[0]
                                        : data?.url}
                                    </a>
                                    <p className="mb-0 text-wrap fw-semibold fs-12 text-secondary">
                                      {data?.date + " " + data?.time}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-start rounded-5 shadow px-1 py-1 bg-white ${
                                    clickStatus ? "d-flex" : "d-none"
                                  }`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaTimes
                                    size={15}
                                    className="text-primary"
                                    onClick={(event) =>
                                      IndividualDelete_Fn(
                                        event,
                                        data?.qr_code_id,
                                      )
                                    }
                                  />
                                </div>
                              </motion.div>
                            ) : (
                              <motion.div
                                variants={item}
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ gap: "15px" }}
                              >
                                <div
                                  className="d-flex w-100 align-items-center justify-content-start rounded-1 shadow px-3 py-3 bg-white"
                                  onClick={() => {
                                    setState({
                                      type: "UPDATE_HISTORY",
                                      history: { ...data },
                                    });
                                    setState({
                                      type: "UPDATE_SCREEN",
                                      screen: "IndividualHistory",
                                    });
                                  }}
                                  style={{
                                    gap: "10px",
                                    maxWidth: "400px",
                                    cursor: "pointer",
                                  }}
                                  key={index}
                                >
                                  {typeof data?.dataType != "undefined" &&
                                  data?.dataType === "vcard" ? (
                                    <MdOutlineContactPage
                                      size={35}
                                      style={{ color: "#555d66" }}
                                    />
                                  ) : data?.dataType === "event" ?(<AiOutlineCalendar
                                    size={35}
                                    style={{ color: "#555d66" }}
                                  />):(
                                    <AiOutlineLink
                                      size={35}
                                      style={{ color: "#555d66" }}
                                    />
                                  )}
                                  <div
                                    className="d-flex flex-column align-items-start "
                                    style={{ gap: "5px" }}
                                  >{data?.dataType !== "event" &&  <a
                                  className={`text-break text-wrap fw-semibold fst-italic  fs-10 ${
                                    data?.dataType === "vcard"
                                      ? "text-decoration-none"
                                      : null
                                  }`}
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "300px",
                                  }}
                                >
                                  {typeof data?.dataType != "undefined" &&
                                  data?.dataType === "vcard"
                                    ? ParseContactInfo?.fn?.[0]?.value?.[0]
                                    : data?.url}
                                </a>}
                                   {data?.dataType === "event" && <a  className="text-break text-wrap fw-semibold fst-italic  fs-10 text-decoration-none" >{parseVCalendar(data?.url).summary}</a>}
                                    <p className="mb-0 text-wrap fw-semibold fs-12 text-secondary">
                                      {data?.date + " " + data?.time}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-start rounded-5 shadow px-1 py-1 bg-white ${
                                    clickStatus ? "d-flex" : "d-none"
                                  }`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaTimes
                                    size={15}
                                    className="text-primary"
                                    onClick={(event) =>
                                      IndividualDelete_Fn(
                                        event,
                                        data?.qr_code_id,
                                      )
                                    }
                                  />
                                </div>
                              </motion.div>
                            )}
                          </>
                        );
                      })}
                    </motion.div>
                  );
                })
              ) : (
                <div className="d-flex w-100 justify-content-center align-items-center bg-white px-2 py-3 rounded-1">
                  <p className="fw-semibold fs-10 mb-0">
                  {t('qrMenu.noScanAvail')}
                  </p>
                </div>
              )}
            </>
          )}

          {state?.HistroyDelete?.ModalStatus ? (
            <DeleteConformationModal
              dependencies={{
                state: state,
                setState: setState,
                EXHIBITOR_ID: null,
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  );
});
