import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import {
  StoreBcardImageData,
  SetPresentDisplay,
} from "../../../state_services/actions/ocr/OCRActions";
import { BCARD_DATA_REVIEW } from "../../../state_services/actionTypes/ocr/OCRActionTypes";
import { OCRLists } from "./OCRLists";
import { t } from "i18next";

export const FormOCR = (props) => {
  const image_Data = props?.data?.state?.BCARD_IMAGE;
  console.log(image_Data);
  let Label_value = "";

  const options_data = [
    {
      opt_value: "first_name",
      opt_text: t("contactsMenu.firstName"),
      value: "",
      opt:
        image_Data?.Data?.first_name?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "last_name",
      opt_text: t("contactsMenu.lastName"),
      value: "",
      opt:
        image_Data?.Data?.first_name?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "designation",
      opt_text: t("contactsMenu.designation"),
      value: "",
      opt:
        image_Data?.Data?.designation?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "phone_number",
      opt_text: t("contactsMenu.phone"),
      value: "",
      opt:
        image_Data?.Data?.phone_number?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "email_address",
      opt_text: t("contactsMenu.email"),
      value: "",
      opt:
        image_Data?.Data?.email_address?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "website",
      opt_text: t("cardScannerMenu.website"),
      value: "",
      opt:
        image_Data?.Data?.first_name?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "company",
      opt_text: t("contactsMenu.company"),
      value: "",
      opt:
        image_Data?.Data?.first_name?.map((item) => ({
          value: item,
          label: item,
        })) || [],
    },
    {
      opt_value: "list_id",
      opt_text: t("contactsMenu.lists"),
      value: [],
    },
  ];
  const [options, setOptions] = useState("");
  console.log(options, "options+......");
  const [addLists, setAddLists] = useState({
    Lists: [],
    OnlyListIds: [],
    OnlyListNames: [],
    UpdatedList: [],
  });

  useEffect(() => {
    Object.keys(options_data).map((fieldData, fieldIndex) => {
      if (image_Data?.FinalData !== "") {
        if (fieldIndex !== 7) {
          options_data[fieldIndex].value =
            image_Data?.FinalData?.[fieldIndex]?.value;
        } else {
          options_data[fieldIndex].value = [];
        }
      } else {
        if (fieldIndex !== 7) {
          options_data[fieldIndex].value =
            image_Data?.Data?.[options_data[fieldIndex]?.opt_value]?.[0] || "";
        } else {
          options_data[fieldIndex].value = [];
        }
      }
    });

    setOptions(options_data);
  }, []);

  const Preview_Fn = () => {
    let _Object = props?.data?.state?.BCARD_IMAGE;
    //---------------------------------------------------------------------------------//
    const Select_Control_Data =
      document.getElementsByClassName("select_control");
    const Text_Control_Data = document.getElementsByClassName("text_control");
    let i = 0,
      DETECT_REQUIRED_FIELD = false;
    for (let label of Select_Control_Data) {
      // console.log(addLists);
      Object.keys(options).map((fieldData, fieldIndex) => {
        if (options[fieldData].opt_value.localeCompare(label.value) == 0) {
          options[fieldData].value = Text_Control_Data[i].value;
        }
      });
      if (options[0].value == "" || options[4].value == "") {
        DETECT_REQUIRED_FIELD = true;
      }
      i++;
    }
    options[7] = {
      ...options[7],
      value: [[...addLists?.OnlyListIds], [...addLists?.OnlyListNames]],
    };

    _Object.FinalData = options;
    if (DETECT_REQUIRED_FIELD) {
      toast.error("Firstname, EmailID is required.", {
        icon: false,
      });
    } else {
      props?.data?.dispatch(StoreBcardImageData(_Object));
      props?.data?.dispatch(SetPresentDisplay(BCARD_DATA_REVIEW));
    }
    //------------------------------------------------------------------------//
  };

  const OnClick_Fn = (e) => {
    Label_value = e.target.value;
  };

  const OnChange_Fn = (e) => {
    const Select_Control_Data =
      document.getElementsByClassName("select_control");
    let DUP_COUNT = 0,
      Hightlight_objects = Array();
    for (let label of Select_Control_Data) {
      if (
        e.target.value.localeCompare(label.value) == 0 &&
        e.target.value !== ""
      ) {
        DUP_COUNT++;
        Hightlight_objects.push(label);
      }
    }
    if (DUP_COUNT > 1) {
      Hightlight_objects.map(function (object, index) {
        object.parentElement.style.border = "1px solid red";
      });
      //e.target.value=Label_value;
      toast.error(
        "You have chosen '" +
          e.target.selectedOptions[0].text +
          "' multiple times.",
        {
          icon: false,
          onClose: () => {
            Hightlight_objects.map(function (object, index) {
              object.parentElement.style.border = "1px solid white";
            });
          },
        },
      );
    }
  };

  const handleOptionChange = (newValue, index) => {
    // Create a copy of the options state
    const updatedOptions = [...options];
    // Update the value at the specified index
    updatedOptions[index].value = newValue;
    // Set the updated options state
    setOptions(updatedOptions);
  };

  return (
    <>
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          theme="colored"
        />
      </div>
      <form
        className="d-flex flex-column justify-content-start bg-white pt-3 pb-3 rounded-2 px-2 shadow-lg p-3 bg-white rounded"
        style={{ width: "95%", maxWidth: "450px" }}
      >
        {options !== ""
          ? Object.keys(options).map((fieldData, fieldIndex) => {
              return (
                <>
                  <div
                    key={fieldIndex}
                    className="d-flex flex-column px-3 py-2 rounded-2  bg-transparent"
                    style={{ maxWidth: "100%", gap: "10px" }}
                  >
                    {options[fieldIndex]?.opt_value !== "list_id" ? (
                      // <select
                      //   className="d-flex bg-transparent fw-semibold fs-10 text-primary select_control"
                      //   style={{ border: 'none', outline: 'none' }}
                      //   defaultValue={options[fieldIndex]?.opt_value}
                      //   onChange={(e) => OnChange_Fn(e)}
                      //   onClick={(e) => OnClick_Fn(e)}>
                      //   <option value="">---Select---</option>

                      //   {Object.keys(options).map((index) => (
                      //     <>
                      //       <option
                      //         key={index}
                      //         value={options[index]?.opt_value}>
                      //         {options[index]?.opt_text}
                      //       </option>
                      //     </>
                      //   ))}
                      // </select>
                      <label
                        className="d-flex bg-transparent fw-semibold fs-10 text-primary select_control"
                        style={{ border: "none", outline: "none" }}
                      >
                        {options[fieldIndex]?.opt_text}
                      </label>
                    ) : (
                      <p className="mb-0 fw-semibold fs-10 text-primary px-1">
                        {t("contactsMenu.lists")}
                      </p>
                    )}
                    <div key={options[fieldIndex]?.opt_value}>
                      {options[fieldIndex]?.opt_value !== "list_id" ? (
                        <>
                          {/* <input
                          id={options[fieldIndex]?.opt_value}
                          type="text"
                          className="bg-transparent text-secondary fw-semibold fs-11 w-100 text_control"
                          style={{ outline: 'none', border: 'none' }}
                          defaultValue={options[fieldIndex]?.value}
                        /> */}
                          <Stack>
                            <Autocomplete
                              freeSolo
                              id="free-solo-demo"
                              disableClearable
                              onInputChange={(e, newValue) =>
                                handleOptionChange(newValue, fieldIndex)
                              }
                              value={options[fieldIndex]?.value}
                              options={options[fieldIndex]?.opt}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  type={
                                    options[fieldIndex]?.opt_value ==
                                    "email_address"
                                      ? "email"
                                      : "text"
                                  }
                                  color="secondary"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </>
                      ) : (
                        <OCRLists
                          addLists={addLists}
                          setAddLists={setAddLists}
                        />
                      )}
                    </div>
                  </div>
                </>
              );
            })
          : null}

        <center>
          <button
            type="button"
            className="btn btn-primary btn-sm w-30"
            onClick={Preview_Fn}
          >
            {t('storiesMenu.preview')}
          </button>
        </center>
      </form>
    </>
  );
};
