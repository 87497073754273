import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/locales/i18n';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render( <I18nextProvider i18n={i18n}>
  <App />
</I18nextProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//For reference START------------------->

{
  /*
Development build 

App.js - change basename in browser router tag to /pwa
package.json - change homepage to /pwa/
SignInWithLoginCode.js - change window.location url to /pwa/ 
Dashboard.js - change window.location url to /pwa/
menu.js - change window.location url to /pwa/
userservices.js - change base url for api 

*/
}

{
  /*
Production build 

App.js - change basename in browser router tag to /cm
package.json - change homepage to /cm/
SignInWithLoginCode.js - change window.location url to /cm/ 
Dashboard.js - change window.location url to /cm/
menu.js - change window.location url to /cm/
userservices.js - change base url for api 

*/
}

//For reference END------------------->
