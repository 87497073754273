import React, { useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";

export const ProfileInfo = (props) => {
  let profileinfo = props.data;

  return (
    <div className="container-fluid  bg-white p-3 rounded-top">
      <div className="d-flex w-100 h-100 justify-content-end ">
        {/* <BsFillPencilFill style={{ cursor: 'pointer' }} onClick={Goto_EditPage}/> */}
      </div>
      <div className="d-flex flex-column w-100 px-3 py-2 justify-content-center ">
        <h4 className="fs-8 fw-bold " style={{ color: "#5411dc" }}>
          {profileinfo.contactinfo.first_name +
            " " +
            profileinfo.contactinfo.last_name}
        </h4>
        <h6 className=" fst-italic fs-10 fw-light text-secondary">
          {profileinfo.contactinfo.desigination}
        </h6>
        <p className="fs-11 fw-semibold text-dark ">
          {profileinfo.contactinfo.company}
        </p>
      </div>
    </div>
  );
};
