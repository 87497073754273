import React from "react";
import { RiLogoutBoxLine, RiSendPlaneFill } from "react-icons/ri";
import { IoMdContacts } from "react-icons/io";
import { TiBusinessCard } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { AiOutlineQrcode } from "react-icons/ai";
import { MdAutoStories, MdFlip, MdPhotoLibrary } from "react-icons/md";
import { useTranslation } from "react-i18next";

export const Menu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const Navigate = (path) => {
    navigate("/" + path);
  };

  return (
    <>
      <ul
        className="navbar sticky-bottom shadow-sm mb-0 px-4 text-dark"
        style={{
          background:
            "linear-gradient(142deg, rgba(230,230,251,1) 0%, rgba(230,224,246,1) 33%, rgba(232,223,247,1) 66%, rgba(223,206,235,1) 100%)",
          height: "auto",
          textDecoration: "none",
          listStyle: "none",
          zIndex: 0,
        }}
      >
        <li
          className="nav-item text-center"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate("showleads")}
        >
          <IoMdContacts size={28} />
          <p className="m-0 fs-12 fw-semibold text-secondary">{t("menu.contacts")}</p>
        </li>
        <li
          className="nav-item text-center"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate("share")}
        >
          <RiSendPlaneFill size={28} />
          <p className="m-0 fs-12 fw-semibold text-secondary">{t("menu.share")}</p>
        </li>
        <li
          className="nav-item text-center"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate("stories")}
        >
          <MdPhotoLibrary size={28} />
          <p className="m-0 fs-12 fw-semibold text-secondary">{t("menu.stories")}</p>
        </li>
        <li
          className="nav-item text-center"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate("qr")}
        >
          <AiOutlineQrcode size={28} />
          <p className="m-0 fs-12 fw-semibold text-secondary">{t("menu.qr")}</p>
        </li>
        <li
          className="nav-item text-center"
          style={{ cursor: "pointer" }}
          onClick={() => Navigate("ocr")}
        >
          <MdFlip size={28} />
          <p className="m-0 fs-12 fw-semibold text-secondary">{t("menu.cardScanner")}</p>
        </li>
        <li
          className="nav-item text-center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            localStorage.setItem("user_logged_in", JSON.stringify(false));
            window.location = `${window.location.origin}/login`;
          }}
        >
          <RiLogoutBoxLine size={28} />
          <p className="m-0 fs-12 fw-semibold text-secondary">{t("menu.logout")}</p>
        </li>
      </ul>
    </>
  );
};
