import React, { useState, useEffect } from "react";
import { AddTags } from "./AddTags";
import { IoMdClose } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { BiImageAdd } from "react-icons/bi";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { FaAt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
export const EditStory = ({
  dependencies: { state, setState },
  editedTags,
  addedTags,
  setAddedTags,
  setEditedTags,
  StoryImageRef,
  UpdateStory,
  setEditMode,
  register,
  handleSubmit,
  errors,
}) => {
  let story = state?.ViewStory;
  const [date, setDate] = useState(story?.date_added);
  console.log(date, "date");
  console.log(story?.date_added, "story?.date_added");
  useEffect(() => {
    setEditedTags([...story?.tagged_users]);
  }, []);
  let ExhibitorTags = JSON.parse(localStorage.getItem("DisplayLeads"));
  let selected = [];
  selected = editedTags;
  let contactList = Object.values(ExhibitorTags).map((option) => ({
    tag_id: option.contact_id,
    tag_name: `${option?.first_name} ${option?.last_name}`,
  }));
  // Filter out options from editedTags that are not present in contactList
  const filteredEditedTags = editedTags.filter((tag) =>
    contactList.some((option) => option.tag_id === tag.tag_id),
  );

  // Filter out selected options from the options array
  const filteredOptions = contactList.filter(
    (option) => !editedTags.some((tag) => tag.tag_id === option.tag_id),
  );
  const formatDate = (inputDateString) => {
    // Parse the date string into a Date object
    console.log(inputDateString, "inputDateString");
    const date = new Date(inputDateString);

    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <div
        className="d-flex w-100 justify-content-between align-items-center py-2 px-1"
        style={{
          gap: "20px",
          position: "sticky",
          top: "0px",
          zIndex: 4,
        }}
      >
        <IoMdClose
          size={30}
          style={{ cursor: "pointer" }}
          className="text-secondary"
          onClick={() => {
            setEditMode(false);
            setAddedTags([]);
          }}
        />
        <p className="mb-0 fw-bold fst-italic fs-8 text-secondary">
          {story?.name}
        </p>
        <button
          type="submit"
          className="rounded-2"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "none",
            width: "auto",
          }}
          form="edit-form"
        >
          <TiTick
            size={30}
            style={{ cursor: "pointer" }}
            className="text-primary"
          />
        </button>
      </div>
      <div
        className="row row-cols-1 align-items-center justify-content-center py-3 mx-auto"
        style={{ width: "80%", gap: "15px" }}
      >
        <div className="d-flex bg-white text-center justify-content-center border border-2 rounded-2 p-3">
          <img
            id="image-preview"
            ref={StoryImageRef}
            src={story?.story_img}
            height="150"
            className="rounded-2"
            style={{ maxWidth: "100%" }}
          />
          <label
            htmlFor="file-upload"
            className="d-flex justify-content-center align-items-center  p-2 rounded-circle bg-white shadow"
            style={{
              position: "relative",
              top: 120,
              right: 30,
              height: "40px",
              width: "40px",
              cursor: "pointer",
            }}
          >
            <BiImageAdd size={25} className="text-secondary" />
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            className="d-none"
            onChange={(evt) => {
              let image = document.getElementById("image-preview");
              setState({
                type: "UPDATE_FILE_UPLOADED",
                file: evt.target.files[0],
              });
              image.src = URL.createObjectURL(evt.target.files[0]);
            }}
          />
        </div>

        {/* <AddTags
          dependencies={{
            state: state,
            setState: setState,
          }}
          Tags={editedTags}
          addedTags={addedTags}
          setAddedTags={setAddedTags}
          setEditedTags={setEditedTags}
        /> */}
        <div
          className="d-flex flex-column w-100 justify-content-center rounded  px-3 py-3"
          style={{
            gap: "10px",
            cursor: "pointer",
            backgroundColor: "rgb(235, 224, 255)",
          }}
        >
          <p className="d-flex justify-content-start w-100 fst-italic  fs-10 fw-semibold text-dark mb-0">
          {t('storiesMenu.taggedPeople')}
          </p>

          {/* <Autocomplete
            multiple
            id="tags-filled"
            options={contactList}
            size="small"
            filterSelectedOptions
            defaultValue={filteredEditedTags}
            onChange={ ( event, newValue ) =>
            {
              console.log(newValue,"newValue")
              selected =newValue
              console.log(selected,"selected")
            } }
            getOptionLabel={ ( option ) => option.tag_name } // Display only the concatenated name
            renderTags={ ( value, getTagProps ) =>
              value.map( ( option, index ) => (
                <Chip
                key={`${option.tag_id}-${index}`} // Use combination of tag_id and index as the key
                  icon={ <FaAt /> }
                  label={ option.tag_name } // Display only the concatenated name
                  color="secondary"
                  sx={ { padding: "2px", margin: "2px" } }
                  { ...getTagProps( { index } ) }
                />
              ) )
            }
            renderInput={ ( params ) => (
              <TextField
                { ...params }
                color='secondary'
              />
            ) }
          /> */}

          <Autocomplete
            multiple
            id="tags-filled"
            options={filteredOptions}
            size="small"
            filterSelectedOptions
            defaultValue={editedTags}
            onChange={(event, newValue) => {
              console.log(newValue, "newValue");
              selected = newValue;
              console.log(selected, "selected");
            }}
            getOptionLabel={(option) => option.tag_name} // Display only the concatenated name
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.tag_id} // Use option.id as the key
                  icon={<FaAt />}
                  label={option.tag_name} // Display only the concatenated name
                  color="secondary"
                  sx={{ padding: "2px", margin: "2px" }}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />
        </div>
        <form
          id="edit-form"
          className="d-flex flex-column p-0 pb-4"
          style={{ gap: "5px" }}
          onSubmit={(event) =>
            handleSubmit((data) => UpdateStory(data, selected, story, date))(
              event,
            )
          }
        >
          <label
            htmlFor="story_name"
            className="fst-italic fs-11 fw-semibold text-secondary"
          >
            {t('storiesMenu.storyName')}
          </label>
          <input
            id="story_name"
            type="text"
            defaultValue={story?.name}
            placeholder={t('storiesMenu.storyName')}
            className={`form-control input-group form-control px-3 py-2 fs-11 ${
              errors?.story_name ? "border border-danger" : null
            }`}
            style={{ padding: "10px 0px" }}
            {...register("story_name", {
              required: {
                value: true,
                message: "A story name is required to add this story",
              },
              pattern: {
                value: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
                message: "Story name should not contain special characters",
              },
            })}
          />

          {errors?.story_name && (
            <p className="text-danger fw-normal fs-11 fst-italic  text-start m-0">
              {errors?.story_name?.message}
            </p>
          )}
          <label
            htmlFor="story_date"
            className="fst-italic fs-11 fw-semibold text-secondary"
          >
            Date
          </label>
          {/* <input
            id="story_date"
            type="date"
            defaultValue={date}
            placeholder="Story date"
            className={`form-control input-group form-control px-3 py-2 fs-11 ${
              errors?.story_date ? 'border border-danger' : null
            }`}
            style={{ padding: '10px 0px' }}
            {...register('story_date', {
              required: {
                value: true,
                message: 'A story date is required to add this story',
              },
              // pattern: {
              //   value: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
              //   message: 'Story date should not contain special characters',
              // },
            })}
            
          /> */}
          <div className="form-group input-group  w-100">
            <DatePicker
              id="story_date"
              type="date"
              defaultValue={story?.date_added}
              placeholder="Story date"
              className={` form-control w-100 px-3 py-2 fs-11 ${
                errors?.story_date ? "border border-danger" : null
              }`}
              style={{ padding: "10px 0px", width: "100%" }}
              {...register("story_date", {
                required: {
                  value: true,
                  message: "A story date is required to add this story",
                },
              })}
              onChange={(value) => setDate(formatDate(value))}
              dateFormat="dd-MMMM-yyyy"
              selected={date}
              selectsStart
            />
          </div>
          {errors?.story_date && (
            <p className="text-danger fw-normal fs-11 fst-italic  text-start m-0">
              {errors?.story_date?.message}
            </p>
          )}
        </form>
      </div>
    </>
  );
};
