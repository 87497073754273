import React, { useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import { MdRotate90DegreesCcw } from "react-icons/md";
import { TiTick } from "react-icons/ti";

export const CapturedPhoto = ({
  dependencies: { state, setState },
  imageData,
}) => {
  const [rotationAngle, setRotationAngle] = useState(0);

  // Function to rotate the image
  const rotateImage = () => {
    setRotationAngle((prevAngle) => prevAngle + 90);
  };

  // Function to handle image rotation and upload
  const handleImageUpload = async () => {
    // Check if rotationAngle is not 0, then rotate the image before uploading
    let rotatedImageData = imageData;
    if (rotationAngle !== 0) {
      const img = new Image();
      img.src = imageData;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Calculate the new dimensions of the canvas after rotation
      const radians = (rotationAngle * Math.PI) / 180;
      const cos = Math.abs(Math.cos(radians));
      const sin = Math.abs(Math.sin(radians));
      canvas.width = img.width * cos + img.height * sin;
      canvas.height = img.width * sin + img.height * cos;

      // Rotate and draw the image on the canvas
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(radians);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      // Get the rotated image data
      rotatedImageData = canvas.toDataURL();
    }

    // Fetch and upload the rotated image
    await fetch(rotatedImageData)
      .then((response) => response.blob())
      .then((blob) => {
        setState({
          type: "UPDATE_FILE_UPLOADED",
          file: new File([blob], "sample.png", { type: blob.type }),
        });
      });

    // Update state
    setState({ type: "UPDATE_CAPTURE_MODAL_OPEN", image: false });
    setState({ type: "UPDATE_SCREEN", screen: "AddStoryPreview" });
  };

  return (
    <>
      <div className="d-flex flex-column p-2" style={{ gap: "10px" }}>
        <div className="d-flex justify-content-between">
          <BsCameraFill
            style={{ cursor: "pointer" }}
            size={40}
            className="text-primary"
            onClick={() =>
              setState({ type: "UPDATE_CAPTURE_MODAL_OPEN", image: false })
            }
          />
          <div>
            <MdRotate90DegreesCcw
              onClick={rotateImage}
              size={40}
              style={{ cursor: "pointer" }}
              className="text-primary"
            />
            <TiTick
              size={40}
              style={{ cursor: "pointer" }}
              className="text-primary"
              onClick={handleImageUpload}
            />
          </div>
        </div>
      </div>
      {/* Apply rotation to the image */}
      <img
        className="rounded-2 pb-2"
        src={imageData}
        width="auto"
        height="auto"
        style={{
          maxWidth: "100%",
          transform: `rotate(${rotationAngle}deg)`,
        }}
      />
    </>
  );
};
