import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const SpecificCard = ({ cardURL, setState }) => {
  const [loader, setLoader] = useState(true);
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center"
          style={{ position: "absolute" }}
        >
          <div className="spinner-border">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : null}
      <div
        className="d-flex flex-column w-100 h-100 bg-none rounded-2"
        style={{ gap: "10px" }}
      >
        <div className="d-flex w-100  justify-content-end ">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setState("ALL");
            }}
          >
            <AiOutlineClose size={20} className="text-white" />
          </button>
        </div>
        <embed
          type="text/html"
          className="border border-2 rounded-2 p-1 w-100"
          src={cardURL}
          width="100%"
          height="600px"
          onLoad={() => setLoader(false)}
        ></embed>
      </div>
    </>
  );
};

export default SpecificCard;
