import React from "react";
import { AiFillCloseCircle, AiOutlineTwitter } from "react-icons/ai";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const FallbackSocial = ({ data, change }) => {
  return (
    <>
      <div className="d-flex w-100 justify-content-end align-items-center p-1">
        <AiFillCloseCircle
          className="text-primary"
          size={28}
          style={{ cursor: "pointer" }}
          onClick={() => {
            change({ type: "UPDATE_FALLBACK_STATE", url: "", state: false });
          }}
        />
      </div>
      <div
        className="d-flex w-100 flex-wrap gap-2 align-items-center justify-content-center pb-2 pt-1 px-1"
        style={{ minHeight: "80px" }}
      >
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${data?.url}`,
              "_blank",
            );
          }}
        >
          <BsFacebook size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`https://wa.me/?text=${data?.url}`, "_blank");
          }}
        >
          <IoLogoWhatsapp size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`http://twitter.com/share?text=${data?.url}`, "_blank");
          }}
        >
          <AiOutlineTwitter size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`https://t.me/share/url?url=${data?.url}`, "_blank");
          }}
        >
          <FaTelegramPlane size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(
              `https://www.linkedin.com/shareArticle?mini=true&url=${data?.url}`,
              "_blank",
            );
          }}
        >
          <BsLinkedin size={20} />
        </button>
      </div>
    </>
  );
};

export default FallbackSocial;
