import { t } from "i18next";
import React, { useState } from "react";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

export const ShowHideImage = (props) => {
  const [toggleShow, setToggleShow] = useState(false);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-start pt-3 pb-3  rounded-2 px-3 m-2  shadow-lg p-3 bg-white rounded"
        onClick={() => {
          setToggleShow(!toggleShow);
        }}
        style={{
          width: "95%",
          maxWidth: "450px",
          gap: "15px",
          cursor: "pointer",
          transitionProperty: "height",
          transitionDuration: "2s",
          transitionTimingFunction: "linear",
          transitionDelay: "1s",
        }}
      >
        <div className="d-flex justify-content-between align-items-center px-2 ">
          <p className="mb-0 fw-semibold fs-10 text-primary ">
            {!toggleShow ? t('cardScannerMenu.showCard') : t('cardScannerMenu.hideCard')}
          </p>
          <MdOutlineArrowDropDownCircle size={20} className="text-primary" />
        </div>

        {toggleShow && (
          <div className="d-flex w-100 ">
            <img
              src={props?.data?.Crop_Base64Image}
              style={{
                width: "100%",
                aspectRatio: "3/2",
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
