import { useEffect, useMemo, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export function inputMerge(event, p, c, n) {
  let currentInput = document.getElementById(c);
  let nextInput = document.getElementById(n);
  let previousInput = document.getElementById(p);
  let lengthOfCurrentInput = document.getElementById(c).value.length;
  let maxLengthOfCurrentInput = document
    .getElementById(c)
    .getAttribute("maxLength");
  if (lengthOfCurrentInput == maxLengthOfCurrentInput) {
    nextInput.focus();
  } else {
    currentInput.focus();
  }

  if (event.key == "Backspace") {
    if (previousInput !== null) {
      previousInput.focus();
    }
  }
}
export function pasteInput(event) {
  let data = event.clipboardData.getData("Text");
  data = data.split("");
  let inputs = document.getElementsByTagName("input");
  data.forEach.call(inputs, (node, index) => {
    node.value = data[index];
  });
}

export function useQRScanData(Text) {
  let data = {};
  const Parse_Vcard_Fn = (Text) => {
    //let Re1 = /^(version|fn|n|title|org):(.+)$/i;
    let Re1 = /^(version|n|title|org|tel|email):(.+)$/i;
    let Re2 = /^([^:;]+);([^:]+):(.+)$/;
    let ReKey = /item\d{1,2}\./;
    let fields = {};
    // console.log("QR scan data:=",Text );

    Text.split(/\r\n|\r|\n/).forEach(function (line) {
      let results, key;
      //------Firstname & lastname-------------
      if (fields.hasOwnProperty("fn") == false) {
        if (line.split(":")[0].localeCompare("N") == 0) {
          let Name = line.split(":")[1];
          fields["fn"] = [
            {
              value: [
                Name.includes(";")
                  ? Name.split(";")[0] + " " + Name.split(";")[1]
                  : Name,
              ],
            },
          ];
        }
      } else if (line.includes("FN")) {
        fields["fn"] = [
          {
            value: [line.split(":")[1]],
          },
        ];
      }
      //--------------Address------------------
      if (line.includes("ADR")) {
        fields["adr"] = {};
        let ADDR_STR = line.split(":")[1];
        fields["adr"]["street"] = ADDR_STR.split(";")[2];
        fields["adr"]["city"] = ADDR_STR.split(";")[3];
        fields["adr"]["state"] = ADDR_STR.split(";")[4];
        fields["adr"]["pincode"] = ADDR_STR.split(";")[5];
        fields["adr"]["country"] = ADDR_STR.split(";")[6];
      }

      if (Re1.test(line)) {
        results = line.match(Re1);
        key = results[1].toLowerCase();
        fields[key] = results[2];
      } else if (Re2.test(line)) {
        results = line.match(Re2);
        key = results[1].replace(ReKey, "").toLowerCase();

        let meta = {};
        results[2]
          .split(";")
          .map(function (p, i) {
            let match = p.match(/([a-z]+)=(.*)/i);
            if (match) {
              return [match[1], match[2]];
            } else {
              return ["TYPE" + (i === 0 ? "" : i), p];
            }
          })
          .forEach(function (p) {
            meta[p[0]] = p[1];
          });

        if (!fields[key]) fields[key] = [];

        fields[key].push({
          meta: meta,
          value: results[3].split(";"),
        });
      }
    });
    console.log("QR scan fields:", fields);

    return fields;
  };

  if (typeof Text !== "undefined" && Text !== null) {
    if (
      Text.includes("VCARD") ||
      Text.includes("MECARD") ||
      Text.includes("BIZCARD")
    ) {
      data = {
        dataType: "vcard",
        //content: Text,
        content: Parse_Vcard_Fn(Text),
      };
    }else if (Text.includes("VEVENT")) {
      data = {
        dataType: "event",

        content: Text,
      };
    } else if (Text.includes("mailto:")) {
      data = {
        dataType: "email",

        content: Text.split(":")?.[1],
      };
    } else if (Text.includes("tel:")) {
      data = {
        dataType: "call",

        content: Text.split(":")?.[1],
      };
    } else if (Text.includes("sms:")) {
      data = {
        dataType: "message",

        content: Text.split(":")?.[1],
      };
    } else if (Text.includes("facetime:")) {
      data = {
        dataType: "videocall",

        content: Text.split(":")?.[1],
      };
    } else if (Text.includes("geo:")) {
      data = {
        dataType: "location",

        content: Text.split(":")?.[1],
      };
    }  else if (Text.includes("WIFI")) {
      data = {
        dataType: "wifi",

        content: Text,
      };
    }
  }
  return data;
}

export function useDateTime() {
  let getFullDate = Date()
    .split(/(\s+)/)
    .filter(function (e) {
      return e.trim().length > 0;
    });

  let Date_Time_Details = {
    getMonth: getFullDate?.[1],
    getDate: getFullDate?.[2],
    getYear: getFullDate?.[3],
    getTime: getFullDate?.[4],
    getFullDate: getFullDate,
  };
  return Date_Time_Details;
}

export function useEditedStory(imageRef, nameRef, tagRef) {
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    if (imageRef) {
      setEditedData({ ...editedData, image: imageRef });
    } else if (nameRef) {
      setEditedData({ ...editedData, name: nameRef });
    } else if (tagRef) {
      setEditedData({ ...editedData, tags: { ...editedData?.tags, tagRef } });
    }
  }, [imageRef, nameRef, tagRef]);

  return editedData;
}

export const useCamera = () => {
  const [videoDem, handleVideoDem] = useState({ w: 0, h: 0 });
  const [cameraFacingMode, handleCameraFacingMode] = useState("environment");
  const [imageData, handleImageData] = useState("");
  let video;
  let canvas;

  useEffect(() => {
    try {
      //find video and canvas elements by tagNames
      video = document.getElementsByTagName("video")[0];
      canvas = document.getElementsByTagName("canvas")[0];
      let constraint = {
        video: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 },
          facingMode: cameraFacingMode,
        },
        audio: false,
      };
      navigator.mediaDevices
        .getUserMedia(constraint)
        .then((stream) => {
          video.setAttribute("playsinline", "true");
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            //get position of video tag;
            let { clientLeft, clientTop, videoWidth, videoHeight } = video;
            handleVideoDem({ w: videoWidth, h: videoHeight });
            //align canvas position with video position
            canvas.style.position = "absolute";
            canvas.style.left = clientLeft.toString();
            canvas.style.top = clientTop.toString();
            canvas.setAttribute("width", videoWidth.toString());
            canvas.setAttribute("height", videoHeight.toString());
            video.play();
          };
        })
        .catch((e) => {
          // console.log(e);
          // alert(e);
        });
    } catch (e) {
      // alert('error1: ' + e);
      // console.log(e);
    }
  }, [cameraFacingMode]);

  const switchCameraFacingMode = () => {
    handleCameraFacingMode((old) =>
      old === "environment" ? "user" : "environment",
    );
  };

  const captureImage = async () => {
    //take photo
    try {
      let video = document.getElementsByTagName("video")[0];
      let canvas = document.getElementsByTagName("canvas")[0];
      let context = canvas.getContext("2d");
      context?.drawImage(video, 0, 0, videoDem.w, videoDem.h);
      let imageData1 = canvas.toDataURL("image/png", 1.0);
      handleImageData(imageData1);
      return imageData1;
    } catch (e) {
      return "";
    }
  };

  return { cameraFacingMode, switchCameraFacingMode, imageData, captureImage };
};

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", (evt) => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    });
    return () => {
      window.removeEventListener("resize", () => {
        setSize({ width: "", height: "" });
      });
    };
  }, []);

  return size;
};

export const useWindowSizeWhileChange = (Screen) => {
  const [size, setSize] = useState({
    width: "",
    height: "",
  });

  useEffect(() => {
    if (Screen === "StoriesList") {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
  }, [Screen]);

  return size;
};

export const useInfiniteScroll = ({ datas, pageSize, block, constraints }) => {
  const [state, setState] = useState({
    finalData: {},
    countValue: 0,
  });
  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      finalData: Object.fromEntries(
        new Map([...Object.entries(datas).splice(state?.countValue, pageSize)]),
      ),
    }));
  }, [datas]);

  useEffect(() => {
    if (block.current !== null) {
      block.current.addEventListener("scroll", (evt) => {
        if (
          evt.srcElement.offsetHeight + evt.srcElement.scrollTop >=
          evt.srcElement.scrollHeight
        ) {
          setState((previousState) => ({
            ...previousState,
            countValue: previousState?.countValue + 10,
            finalData: Object.fromEntries(
              new Map([
                ...Object.entries(previousState?.finalData),
                ...Object.entries(datas).splice(
                  previousState?.countValue + 10,
                  pageSize,
                ),
              ]),
            ),
          }));
        }
      });
      return () => {
        if (block.current !== null) {
          block.current.removeEventListener("scroll", (evt) => {});
        }
      };
    }
  }, [block]);

  return { finalData: state?.finalData };
};

export const usePagination = ({ datas, pageSize, siblingCount }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startEndCount, setStartEndCount] = useState({
    startCount: 1,
    endCount: pageSize,
  });
  const totalCount = Object.keys(datas).length;
  const PaginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 2;

    const range = (start, end) => {
      let length = end - start + 1;
      return Array.from({ length }, (_, idx) => idx + start);
    };

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);
      return [...leftRange, "...", totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, "...", ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, "...", ...middleRange, "...", lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  function groupingContacts(startCount, endCount) {
    let finalObj = {};

    Object.keys(datas).map((keyName, index) => {
      if (index + 1 <= endCount && index + 1 >= startCount) {
        finalObj[keyName] = datas[keyName];
      }
    });

    return finalObj;
  }

  const pageWiseData = useMemo(() => {
    let TotalPages = Math.ceil(Object.keys(datas)?.length / pageSize);
    let length = TotalPages - 1 + 1;
    let startCount = 1;
    let endCount = pageSize;
    let range = Array.from({ length }, (_, idx) => idx + 1);
    let object = {};
    for (let i = 0; i < range?.length; i++) {
      let key = i + 1;
      object[key] = groupingContacts(startCount, endCount);

      startCount = startCount + pageSize;
      endCount = endCount + pageSize;
      setStartEndCount({
        startCount: startEndCount?.startCount + pageSize,
        endCount: startEndCount?.endCount + pageSize,
      });
    }
    return object;
  }, [datas, pageSize]);

  const Paginator = () => {
    const size = useWindowSize();
    return (
      <>
        <div className="d-flex flex-column w-100 h-auto align-items-center py-1">
          <div className="d-flex align-items-center w-auto h-fit  mx-1 my-1  rounded-lg gap-1">
            <button
              className={`rounded-2 btn btn-light text-primary fw-semibold fs-10  px-3 py-2 `}
              onClick={(evt) => {
                evt.preventDefault();
                if (currentPage !== 1) {
                  setCurrentPage(currentPage - 1);
                }
              }}
            >
             <FaArrowLeft /> 
            </button>
            {PaginationRange?.map((page, index) => {
              return (
                <>
                  {typeof page === "number" ? (
                    <button
                      key={index}
                      onClick={(evt) => {
                        evt.preventDefault();
                        if (currentPage !== page) {
                          setCurrentPage(page);
                        }
                      }}
                      className={`${
                        currentPage === page
                          ? "bg-black text-white"
                          : "bg-white text-black"
                      } rounded-2 border border-2  text-secondary h-100  ${
                        size.width < 430
                          ? "fs-10 fw-bold"
                          : "fs-9   fw-semibold"
                      } `}
                      style={{ width: "45px" }}
                    >
                      {page}
                    </button>
                  ) : (
                    <button
                      className={`leading-none text-secondary fw-semibold h-100 ${
                        size.width < 430 ? "fs-10 " : "fs-9  "
                      } cursor-not-allowed`}
                      style={{ width: "45px" }}
                    >
                      {page}
                    </button>
                  )}
                </>
              );
            })}
            <button
              className={`rounded-2 btn btn-light text-primary fw-semibold fs-10  px-3 py-2`}
              onClick={(evt) => {
                evt.preventDefault();
                if (currentPage !== Math.ceil(totalCount / pageSize)) {
                  setCurrentPage(currentPage + 1);
                }
              }}
            >
               <FaArrowRight />
            </button>
          </div>
        </div>
      </>
    );
  };

  return { Paginator, currentPage, pageWiseData };
};

export function getRandomColor() {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

export const useCustomPagination = ({ datas, pageSize, siblingCount }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalCount = Object.keys(datas).length;

  function groupingContacts(startCount, endCount) {
    let finalObj = {};

    Object.keys(datas).map((keyName, index) => {
      if (index + 1 <= endCount && index + 1 >= startCount) {
        finalObj[keyName] = datas[keyName];
      }
    });

    return finalObj;
  }

  const pageWiseData = useMemo(() => {
    let TotalPages = Math.ceil(Object.keys(datas)?.length / pageSize);
    let length = TotalPages - 1 + 1;
    let startCount = 1;
    let endCount = pageSize;
    let range = Array.from({ length }, (_, idx) => idx + 1);
    let object = {};
    if (TotalPages !== 0) {
      for (let i = 0; i <= range?.length; i++) {
        let key = i + 1;
        if (TotalPages == key) {
          endCount =
            endCount -
            pageSize +
            Object.keys(groupingContacts(startCount, endCount)).length;
        }
        object[key] = {
          data: groupingContacts(startCount, endCount),
          count_results: {
            startCount: startCount,
            endCount: endCount,
          },
        };
        startCount = startCount + pageSize;
        endCount = endCount + pageSize;
      }
    }

    return object;
  }, [datas, pageSize]);

  const Paginator = () => {
    const size = useWindowSize();
    return (
      <>
        {Object.keys(pageWiseData).length > 0 ? (
          <div className="d-flex flex-column w-100 h-auto align-items-center py-1">
            <div className="d-flex align-items-center w-auto h-fit  mx-1 my-1  rounded-lg gap-4">
              <button
                className={`rounded-2 btn btn-light text-primary fw-semibold fs-10  px-2 py-1 `}
                onClick={(evt) => {
                  evt.preventDefault();
                  if (currentPage !== 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                 <FaArrowLeft /> 
              </button>
              <p className="text-center mb-0 fw-semibold fs-11 text-white">
                {pageWiseData[currentPage]["count_results"].startCount +
                  " - " +
                  pageWiseData[currentPage]["count_results"].endCount +
                  " of " +
                  Object.keys(datas).length}
              </p>

              <button
                className={`rounded-2 btn btn-light text-primary fw-semibold fs-10  px-2 py-1`}
                onClick={(evt) => {
                  evt.preventDefault();
                  if (currentPage !== Math.ceil(totalCount / pageSize)) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                 <FaArrowRight /> 
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return { Paginator, currentPage, pageWiseData, setCurrentPage };
};

export const renderFieldValue = (contactInfo, field) => {
  console.log(contactInfo, "contactInfo");
  switch (field) {
    case "firstName":
      return Array.isArray(contactInfo?.n)
        ? contactInfo?.n?.[0]?.value?.[1]
        : contactInfo?.n?.split(";")?.[1];
    case "lastName":
      return Array.isArray(contactInfo?.n)
        ? contactInfo?.n?.[0]?.value?.[0]
        : contactInfo?.n?.split(";")?.[0];
    case "organization":
      return typeof contactInfo?.org !== "undefined"
        ? contactInfo?.version === "2.1"
          ? contactInfo?.org
          : typeof contactInfo?.org === "string" ? contactInfo?.org : contactInfo?.org?.[0]?.value?.[0]
        : "";
    case "designation":
      return contactInfo?.version === "2.1" || contactInfo?.version === "3.0"
        ? typeof contactInfo?.title === "string" ? contactInfo?.title : contactInfo?.title?.[0]?.value?.[0]
        : "";
    case "email":
      return contactInfo?.version === "2.1"
        ? contactInfo?.email
        : contactInfo?.version === "3.0"
          ? contactInfo?.email?.map((item) => item.value[0]) || [
              contactInfo?.email,
            ]
          : [];
    case "contactNo":
      return contactInfo?.version == "2.1"
        ? contactInfo?.tel
        : contactInfo?.version == "3.0"
          ? contactInfo?.tel?.map((item) => item.value[0])
          : [];
    case "address":
      return contactInfo?.adr?.street;
    case "city":
      return contactInfo?.adr?.city;
    case "state":
      return contactInfo?.adr?.state;
    case "country":
      return contactInfo?.adr?.country;
    case "pincode":
      return contactInfo?.adr?.pincode;
    default:
      return "";
  }
};
