import React, { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import {
  ContactManagerConfig,
  List_API,
  Login_API,
  TagsByUser_API,
} from "../../services/userservices";

export const NewUserEmailSignUp = ({
  logo,
  dependencies: { LoginState, LoginChanger },
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  let InitialState = {
    isAPILoading: false,
  };

  function StateChanger(state, action) {
    switch (action.type) {
      case "API_LOADER": {
        return {
          ...state,
          isAPILoading: action.status,
        };
      }
      default: {
        return state;
      }
    }
  }

  const [state, changer] = useReducer(StateChanger, InitialState);

  async function registerEmail(data) {
    changer({ type: "API_LOADER", status: true });
    await Login_API({ ...data, action: "add_new_contact" }).then((res) => {
      LoginChanger({
        type: "TOAST_RESPONSE",
        status: res?.status,
        message: res?.message,
      });

      if (res?.status === "success") {
        clearLocalStorage();

        setTimeout(() => {
          LoginChanger({
            type: "EMAIL_VERIFY",
            email: data.email,
            process: "triggered",
          });
          LoginChanger({ type: "SCREEN_NAME", screen: "verify" });
        }, 1000);

        handleSuccessfulEmailRegistration(res.data);
      }

      changer({ type: "API_LOADER", status: false });
    });
  }

  const clearLocalStorage = () => {
    const keysToRemove = [
      "EditLeads", "DisplayLeads", "EditItems", 
      "InsertItems", "user_login_code", "user_email", "config_settings"
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
  };

  const handleSuccessfulEmailRegistration = (data) => {
    localStorage.setItem("exhibitor_id", JSON.stringify(data.exhibitor_id));
    localStorage.setItem("user_id", JSON.stringify(data.user_id));

    fetchEventData(data.user_id);
    fetchBusinessCardTags(data.exhibitor_id);
    fetchConfigSettings(data.user_id);
  };

  const fetchEventData = async (user_id) => {
    const payload = { uid: user_id };
    const res = await List_API(payload);
    localStorage.setItem("Lists", JSON.stringify(res.data || []));
  };

  const fetchBusinessCardTags = async (exhibitor_id) => {
    const formData = new FormData();
    formData.append("action", "get_business_card_tags");
    formData.append("uid", JSON.parse(localStorage.getItem("user_id")));

    const res = await TagsByUser_API(formData);
    localStorage.setItem("Tags", JSON.stringify(res));
  };

  const fetchConfigSettings = async (user_id) => {
    const res = await ContactManagerConfig({ uid:user_id });
    localStorage.setItem("config_settings", JSON.stringify(res));
  };

  useEffect(() => {
    reset({
      email: "",
    });
  }, [isSubmitSuccessful]);

  return (
    <div className="d-flex flex-column py-5 align-items-center px-3">
      <img
        src={logo}
        width="auto"
        height="auto"
        style={{ maxWidth: "270px", maxHeight: "70px" }}
      />
      <h4 className="fw-semibold fs-2 pt-4" style={{ color: "#322d43" }}>
        {t("login.hello_user")}
      </h4>
      <p
        className="fw-normal fs-8 
            pt-2 px-1"
        style={{ color: "#4a455a" }}
      >
        {t("login.set_code")}
      </p>

      <form
        className="d-flex flex-column align-items-center mt-2 fs-11"
        style={{ gap: "20px" }}
        onSubmit={handleSubmit(registerEmail)}
      >
        <div className="form-floating fs-9 ">
          <input
            className={`form-control ${errors?.email ? "is-invalid" : null}`}
            type="email"
            id="email_input"
            placeholder={t("login.enter_email")}
            {...register("email", {
              required: { value: true, message: t("login.required") },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("login.invalid_email"),
              },
            })}
            style={{ borderRadius: "10px" }}
          ></input>
          <label htmlFor="email_input ">{t("login.enter_email")}</label>
          {errors?.email?.message && (
            <div className="invalid-feedback text-start ">
              {errors?.email?.message}
            </div>
          )}
        </div>

        <button
          className="d-flex align-items-center justify-content-center btn btn-primary  fw-semibold w-100 py-3"
          style={{ borderRadius: "10px", gap: "10px" }}
          type="submit"
        >
          <p className="m-0 ">{t("login.submit")}</p>
          {state.isAPILoading && (
            <div className="spinner-border text-light"></div>
          )}
        </button>
      </form>
    </div>
  );
};


