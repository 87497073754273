import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OtherPagesCheck } from "../App";
import { Menu } from "../functions/menu";
import { RiSendPlaneFill } from "react-icons/ri";
import { MdOutlineConnectWithoutContact, MdWebStories } from "react-icons/md";
import { IoMdContacts, IoMdPhotos } from "react-icons/io";
import { FaQrcode } from "react-icons/fa";
import { TiBusinessCard } from "react-icons/ti";
import { Dropdown } from "react-bootstrap";
import frenchflag from "../../src/assets/flags/french_flag.jpg"
import germanyflag from "../../src/assets/flags/germany_flag.jpg"
import arabic from "../../src/assets/flags/Arabic-Language-Flag.svg"
import engFlag from "../../src/assets/flags/us_flag.jpg"
import inFlag from "../../src/assets/flags/in.svg"
import { useTranslation } from 'react-i18next';
const Dashboard = () => {
  const navigate = useNavigate();

  const Navigate = (path) => {
    navigate("/" + path);
  };
  const orginMap = {
    en: "English",
    fr: "Français",
    de: "Deutsch",
    ar:"عربي",
    ta: "தமிழ்",
    hi: "हिन्दी",
  };
  const getInitialLanguage = () => {
    const savedLanguage = localStorage.getItem("language") || "en";
    const flagMap = {
      en: engFlag,
      fr: frenchflag,
      de: germanyflag,
      ar: arabic,
      ta: inFlag,
      hi: inFlag,
    };

    return {
      name: savedLanguage,
      flag: flagMap[savedLanguage],
      orgin: orginMap[savedLanguage],
    };
  };

  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage);

  useEffect(() => {
    changeLanguage(selectedLanguage.name);
  }, []);
  const handleLanguageChange = (language, flag) => {
    setSelectedLanguage({ name: language, flag , orgin: orginMap[language]});
    changeLanguage(language)
  };
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };
  return (
    <>
      {/*------------------------------------------Display Leads---------------------------------------------------*/}
      <div className="container-fluid min-vh-100 my-auto p-0">
        <div
          className="d-flex flex-column align-items-center w-100 min-vh-100 "
          style={{ backgroundColor: "#5411dc", height: "100%" }}
        >
          <div className="d-flex w-100 align-items-center justify-content-sm-center py-3  sticky-top"
          style={{ backgroundColor: "#5411dc", height: "100%" }}>
           
            <p className="fw-semibold fs-5 mx-2 my-0 " style={{ color: "#fff" }}>
          
              {t('dashboard')}
            </p>
            {/* <Dropdown className="m-2 position-absolute top-0 end-0">
      <Dropdown.Toggle className="btn btn-light " variant="default">
        <img src={selectedLanguage.flag} alt={selectedLanguage.name} width="20" className="me-2" />
        {selectedLanguage.orgin}
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('ta', inFlag)}>
          <img src={inFlag} alt="Arabic" className="me-2" width="20" />
          தமிழ்
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('hi', inFlag)}>
          <img src={inFlag} alt="Arabic" className="me-2" width="20" />
          हिन्दी
        </Dropdown.Item>
      <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('en', engFlag)}>
          <img src={engFlag} alt="English" className="me-2" width="20" />
          English
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('fr', frenchflag)}>
          <img src={frenchflag} alt="French" className="me-2" width="20" />        
Français
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('de', germanyflag)}>
          <img src={germanyflag} alt="German" className="me-2" width="20" />
          Deutsch
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('ar', arabic)}>
          <img src={arabic} alt="Arabic" className="me-2" width="20" />
          عربي
        </Dropdown.Item>
     
      </Dropdown.Menu>
    </Dropdown> */}
          </div>
         
          <div className="d-flex flex-wrap w-100 justify-content-center min-vh-100 bg-white rounded-2 px-3 py-3" style={{ gap: "10px" }}>
      <button
        className="border-0 shadow-sm rounded-3 py-2 px-3 fw-semibold fs-10 content-box color-effect-1 m-1"
        style={{ cursor: "pointer", width: "40%" }}
        onClick={() => Navigate("showleads")}
      >
        <p className="fw-semibold p-1 mb-0" style={{ fontSize: "20px" }}>
          {t('contacts')}
        </p>
        <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
          <div className="box-icon">
            <i>
              <IoMdContacts size={30} />
            </i>
          </div>
        </div>
        <p>{t('contactsDescription')}</p>
      </button>
      <button
        className="border-0 shadow-lg rounded-3 py-2 px-3 fw-semibold fs-10 content-box color-effect-1 m-1"
        style={{ width: "40%" }}
        onClick={() => Navigate("share")}
      >
        <p className="fw-semibold p-1 mb-0" style={{ fontSize: "20px" }}>
          {t('share')}
        </p>
        <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
          <div className="box-icon">
            <i>
              <RiSendPlaneFill size={30} />
            </i>
          </div>
        </div>
        <p>{t('shareDescription')}</p>
      </button>
      <button
        className="border-0 shadow-sm rounded-3 py-2 px-3 fw-semibold fs-10 content-box color-effect-1 m-1"
        style={{ cursor: "pointer", width: "40%" }}
        onClick={() => Navigate("stories")}
      >
        <p className="fw-semibold p-1 mb-0" style={{ fontSize: "20px" }}>
          {t('stories')}
        </p>
        <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
          <div className="box-icon">
            <i>
              <IoMdPhotos size={30} />
            </i>
          </div>
        </div>
        <p>{t('storiesDescription')}</p>
      </button>
      <button
        className="border-0 shadow-sm rounded-3 py-2 px-3 fw-semibold fs-10 content-box color-effect-1 m-1"
        style={{ cursor: "pointer", width: "40%" }}
        onClick={() => Navigate("qr")}
      >
        <p className="fw-semibold p-1 mb-0" style={{ fontSize: "20px" }}>
          {t('qrScanner')}
        </p>
        <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
          <div className="box-icon">
            <i>
              <FaQrcode size={30} />
            </i>
          </div>
        </div>
        <p>{t('qrScannerDescription')}</p>
      </button>
      <button
        className="border-0 shadow-sm rounded-3 py-2 px-3 fw-semibold fs-10 content-box color-effect-1 m-1"
        style={{ cursor: "pointer", width: "40%" }}
        onClick={() => Navigate("ocr")}
      >
        <p className="fw-semibold p-1 mb-0" style={{ fontSize: "20px" }}>
          {t('cardScanner')}
        </p>
        <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
          <div className="box-icon">
            <i>
              <TiBusinessCard size={30} />
            </i>
          </div>
        </div>
        <p>{t('cardScannerDescription')}</p>
      </button>
    </div>
        </div>
       
      </div>
      <Menu />
      {/*------------------------------------------Display Leads---------------------------------------------------*/}
    </>
  );
};
export default Dashboard;
