import React, { useEffect, useReducer } from "react";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { inputMerge, pasteInput } from "../../functions/functions";
import { useForm } from "react-hook-form";
import { Login_API } from "../../services/userservices";
import { useTranslation } from "react-i18next";

export const EmailVerificationCode = ({
  logo,
  dependencies: { LoginState, LoginChanger },
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      pin_1: "",
      pin_2: "",
      pin_3: "",
      pin_4: "",
      pin_5: "",
      pin_6: "",
    },
  });

  let InitialState = {
    inputs: {
      inputType: "number",
      isShowIcon: true,
    },
    isAPILoading: false,
  };

  function StateChanger(state, action) {
    switch (action.type) {
      case "SHOWHIDEINPUT": {
        return {
          ...state,
          inputs: {
            ...state.inputs,
            inputType: action.inputType,
            isShowIcon: action.icon,
          },
        };
      }
      case "API_LOADER": {
        return {
          ...state,
          isAPILoading: action.status,
        };
      }
      default: {
        return null;
      }
    }
  }

  const [state, changer] = useReducer(StateChanger, InitialState);

  async function registerEmail(data) {
    changer({ type: "API_LOADER", status: true });
    await Login_API({
      email: LoginState?.verify?.email_for_verification,
      action: "verify_otp",
      verification_code: Object.keys(data)
        .map((key) => data[key])
        .join(""),
    }).then((res) => {
      LoginChanger({
        type: "TOAST_RESPONSE",
        status: res?.status,
        message: res?.message,
      });
      if (res?.status === "success") {
        setTimeout(() => {
          localStorage.setItem(
            "user_email",
            JSON.stringify(LoginState?.verify?.email_for_verification),
          );
          LoginChanger({ type: "SCREEN_NAME", screen: "set_login_code" });
          LoginChanger({
            type: "EMAIL_VERIFY",
            email: "",
            process: "not-triggered",
          });
        }, 1000);
      }

      changer({ type: "API_LOADER", status: false });
    });
  }
  let hideEmail = function (email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  };

  useEffect(() => {
    reset({
      pin_1: "",
      pin_2: "",
      pin_3: "",
      pin_4: "",
      pin_5: "",
      pin_6: "",
    });
  }, [isSubmitSuccessful]);

  return (
    <div className="d-flex flex-column py-5 align-items-center px-3">
      <img
        src={logo}
        width="auto"
        height="auto"
        style={{ maxWidth: "270px", maxHeight: "70px" }}
      />
      <h4 className="fw-semibold fs-2 pt-4" style={{ color: "#322d43" }}>
        {t('login.verify_account')}
      </h4>
      <p
        className="fw-normal fs-8 
            pt-2 px-1"
        style={{ color: "#4a455a" }}
      >
        {t('login.code_sent' )}{hideEmail(LoginState?.verify?.email_for_verification) }
      </p>
      <p
        className="fw-normal fs-10 
            pt-2 px-1"
        style={{ color: "#4a455a" }}
      >
        {t('login.enter_code')}
      </p>

      <form
        className="d-flex flex-column align-items-center mt-2 fs-11"
        style={{ gap: "20px" }}
        onSubmit={handleSubmit(registerEmail)}
      >
        <div className="d-flex  fs-9 " style={{ gap: "10px" }}>
          <input
            className={`form-control text-center ${
              errors?.pin_1 ? "border border-danger" : null
            }`}
            id="input_num_1"
            min={0}
            max={9}
            maxLength={1}
            onInput={(evt) => {
              if (evt.target.value.length > evt.target.maxLength) {
                evt.target.value = evt.target.value.slice(
                  0,
                  evt.target.maxLength,
                );
              }
            }}
            onKeyUp={(event) =>
              inputMerge(event, "", "input_num_1", "input_num_2")
            }
            onPaste={(event) => pasteInput(event)}
            type={state?.inputs?.inputType}
            {...register("pin_1", {
              required: {
                value: true,
                message: t('login.required_pin'),
              },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('login.invalid_pin'),
              },
            })}
            style={{ borderRadius: "10px", width: "40px" }}
          />
          <input
            className={`form-control text-center ${
              errors?.pin_2 ? "border border-danger" : null
            }`}
            type={state?.inputs?.inputType}
            id="input_num_2"
            min={0}
            max={9}
            maxLength={1}
            onInput={(evt) => {
              if (evt.target.value.length > evt.target.maxLength) {
                evt.target.value = evt.target.value.slice(
                  0,
                  evt.target.maxLength,
                );
              }
            }}
            onKeyUp={(event) =>
              inputMerge(event, "input_num_1", "input_num_2", "input_num_3")
            }
            onPaste={(event) => pasteInput(event)}
            {...register("pin_2", {
              required: {
                value: true,
                message: t('login.required_pin'),
              },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('login.invalid_pin'),
              },
            })}
            style={{ borderRadius: "10px", width: "40px" }}
          />
          <input
            className={`form-control text-center ${
              errors?.pin_3 ? "border border-danger" : null
            }`}
            type={state?.inputs?.inputType}
            id="input_num_3"
            min={0}
            max={9}
            maxLength={1}
            onInput={(evt) => {
              if (evt.target.value.length > evt.target.maxLength) {
                evt.target.value = evt.target.value.slice(
                  0,
                  evt.target.maxLength,
                );
              }
            }}
            onKeyUp={(event) =>
              inputMerge(event, "input_num_2", "input_num_3", "input_num_4")
            }
            onPaste={(event) => pasteInput(event)}
            {...register("pin_3", {
              required: {
                value: true,
                message: t('login.required_pin'),
              },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('login.invalid_pin'),
              },
            })}
            style={{ borderRadius: "10px", width: "40px" }}
          />
          <input
            className={`form-control text-center ${
              errors?.pin_4 ? "border border-danger" : null
            }`}
            type={state?.inputs?.inputType}
            id="input_num_4"
            min={0}
            max={9}
            maxLength={1}
            onInput={(evt) => {
              if (evt.target.value.length > evt.target.maxLength) {
                evt.target.value = evt.target.value.slice(
                  0,
                  evt.target.maxLength,
                );
              }
            }}
            onKeyUp={(event) =>
              inputMerge(event, "input_num_3", "input_num_4", "input_num_5")
            }
            onPaste={(event) => pasteInput(event)}
            {...register("pin_4", {
              required: {
                value: true,
                message: t('login.required_pin'),
              },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('login.invalid_pin'),
              },
            })}
            style={{ borderRadius: "10px", width: "40px" }}
          />
          <input
            className={`form-control text-center ${
              errors?.pin_5 ? "border border-danger" : null
            }`}
            type={state?.inputs?.inputType}
            id="input_num_5"
            min={0}
            max={9}
            maxLength={1}
            onInput={(evt) => {
              if (evt.target.value.length > evt.target.maxLength) {
                evt.target.value = evt.target.value.slice(
                  0,
                  evt.target.maxLength,
                );
              }
            }}
            onKeyUp={(event) =>
              inputMerge(event, "input_num_4", "input_num_5", "input_num_6")
            }
            onPaste={(event) => pasteInput(event)}
            {...register("pin_5", {
              required: {
                value: true,
                message: t('login.required_pin'),
              },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('login.invalid_pin'),
              },
            })}
            style={{ borderRadius: "10px", width: "40px" }}
          />
          <input
            className={`form-control text-center ${
              errors?.pin_6 ? "border border-danger" : null
            }`}
            type={state?.inputs?.inputType}
            id="input_num_6"
            min={0}
            max={9}
            maxLength={1}
            onInput={(evt) => {
              if (evt.target.value.length > evt.target.maxLength) {
                evt.target.value = evt.target.value.slice(
                  0,
                  evt.target.maxLength,
                );
              }
            }}
            onKeyUp={(event) =>
              inputMerge(event, "input_num_5", "input_num_6", "")
            }
            onPaste={(event) => pasteInput(event)}
            {...register("pin_6", {
              required: {
                value: true,
                message: t('login.required_pin'),
              },
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('login.invalid_pin'),
              },
            })}
            style={{ borderRadius: "10px", width: "40px" }}
          />
        </div>
        {(errors?.pin_1 ||
          errors?.pin_2 ||
          errors?.pin_3 ||
          errors?.pin_4 ||
          errors?.pin_5 ||
          errors?.pin_6) && (
          <p className="text-danger fw-normal fs-10  text-start m-0">
            {errors?.pin_1?.message ||
              errors?.pin_2?.message ||
              errors?.pin_3?.message ||
              errors?.pin_4?.message ||
              errors?.pin_5?.message ||
              errors?.pin_6?.message}
          </p>
        )}
        <div className="d-flex w-100 justify-content-center">
          <button
            type="submit"
            className="d-flex align-items-center justify-content-center btn btn-primary rounded-start  fw-semibold w-75 py-3"
            style={{
              borderRadius: "10px 0px 0px 10px",
              backgroundColor: "none",
              gap: "10px",
            }}
          >
            <p className="m-0 ">{t('login.submit')}</p>
            {state.isAPILoading && (
              <div className="spinner-border text-light"></div>
            )}
          </button>
          <button
            type="button"
            className="btn btn-light border border-primary  fw-semibold w-25 py-1"
            style={{
              borderRadius: "0px 10px 10px 0px",
            }}
            onClick={() => {
              if (state?.inputs?.inputType === "text") {
                changer({
                  type: "SHOWHIDEINPUT",
                  inputType: "password",
                  icon: false,
                });
              } else {
                changer({
                  type: "SHOWHIDEINPUT",
                  inputType: "text",
                  icon: true,
                });
              }
            }}
          >
            {state?.inputs?.isShowIcon === true ? (
              <BiShowAlt size={30} color="#5411dc" />
            ) : (
              <BiHide size={30} color="#5411dc" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
