import React, { memo, useEffect, useReducer, useState } from "react";
import { ScreenSwitch } from "../screens/QR/components/ScreenSwitch";
import { Scanner } from "../screens/QR/components/Scanner";
import { OptionsBar } from "../screens/QR/components/OptionsBar";
import { ScannedData } from "../screens/QR/components/ScannedData";
import { History } from "../screens/QR/History";
import { IndividualHistory } from "../screens/QR/IndividualHistory";
import UpgradePlan from "../functions/UpgradePlan";
import { useWindowSize } from "../functions/functions";
import { Menu } from "../functions/menu";

export const QR = memo(() => {
  //Upgrade plan modal based on config settings ------------------------------------
  const [pageLoader, setPageLoader] = useState(false);
  const size = useWindowSize();
  //-------------------------------------------------------------------------------------

  let initialQRPageState = {
    Screen: "Scanner",
    History: {},
    HistroyDelete: {
      ModalStatus: false,
      DeleteId: -1,
    },
    Scanner: {
      url: "",
      data: {},
      date: "",
      time: "",
    },
    ScannerHistory: {},
  };

  const QRPageReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_SCANNED_LINK": {
        return {
          ...state,
          Scanner: {
            ...state?.Scanner,
            url: action.url,
            data: action.data,
            date: action.date,
            time: action.time,
          },
        };
      }
      case "UPDATE_TO_EXISTING_DATE_HISTORY": {
        return {
          ...state,
          ScannerHistory: {
            ...state?.ScannerHistory,
            [action.date]: [
              ...state?.ScannerHistory?.[action.date],
              action.data,
            ],
          },
        };
      }
      case "UPDATE_TO_NEW_DATE_HISTORY": {
        return {
          ...state,
          ScannerHistory: {
            ...state?.ScannerHistory,
            [action.date]: [action.data],
          },
        };
      }
      case "UPDATE_SCREEN": {
        return {
          ...state,
          Screen: action.screen,
        };
      }
      case "UPDATE_HISTORY": {
        return {
          ...state,
          History: action.history,
        };
      }
      case "FETCH_HISTORY_FROM_API": {
        return {
          ...state,
          ScannerHistory: action.ScannerHistory,
        };
      }
      case "HISTROY_DELETE_MODAL_SHOW_STATUS": {
        return {
          ...state,
          HistroyDelete: action.HistroyDelete,
        };
      }

      default:
        break;
    }
  };
  const [QRPageState, QRPageDispatch] = useReducer(
    QRPageReducer,
    initialQRPageState,
  );

  useEffect(() => {
    setTimeout(() => {
      setPageLoader(false);
    }, 1000);
  }, []);
  return (
    <>
      {QRPageState?.Screen === "Scanner" ? (
        <div
          className=" d-flex flex-column justify-content-between h-100 bg-primary"
          // style={{ gap: "10px", height: size.height }}
        >
          <ScreenSwitch
            dependencies={{ state: QRPageState, setState: QRPageDispatch }}
          />
          {JSON.parse(localStorage.getItem("config_settings"))
            .business_cards_enabled === "No" ? (
            pageLoader ? (
              <>
                <div
                  className="d-flex w-100 bg-white align-items-center justify-content-center"
                  style={{ height: size.height - 120 }}
                >
                  <div
                    className="spinner-border text-primary "
                    role="status"
                    style={{ width: "200px", height: "200px" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <UpgradePlan />
            )
          ) : pageLoader ? (
            <div
              className="d-flex w-100 bg-white align-items-center justify-content-center"
              style={{ height: size.height - 120 }}
            >
              <div
                className="spinner-border text-primary "
                role="status"
                style={{ width: "200px", height: "200px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {QRPageState?.Scanner?.url === "" && (
                <Scanner
                  dependencies={{
                    state: QRPageState,
                    setState: QRPageDispatch,
                  }}
                />
              )}
              <OptionsBar />
              {QRPageState?.Scanner?.url && (
                <ScannedData
                  dependencies={{
                    state: QRPageState,
                    setState: QRPageDispatch,
                  }}
                />
              )}
            </>
          )}
           <Menu />
        </div>
      ) : null}
      {QRPageState?.Screen === "History" ? (
        <History
          dependencies={{ state: QRPageState, setState: QRPageDispatch }}
        />
      ) : null}
      {QRPageState?.Screen === "IndividualHistory" ? (
        <IndividualHistory
          dependencies={{ state: QRPageState, setState: QRPageDispatch }}
        />
      ) : null}
    </>
  );
});
