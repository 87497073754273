import React, { useState } from "react";
import { FaCopy, FaQrcode } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import { useCustomPagination, useWindowSize } from "../../functions/functions";
import SearchBykeyword from "./SearchBykeyword";
import { getEmailTemplate } from "../../services/userservices";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import QRCode from "qrcode.react";
const ToursTab = ({ data, state, setState }) => {
  const size = useWindowSize();
  const datas = data?.data;
  const isPlanSubscribed = data?.isPlanSubscribed;
  const [keyword, setKeyword] = useState(null);
  // Filter data based on the entered keyword
  const filteredData = keyword
    ? data?.data.filter((item) =>
        item.tour_name.toLowerCase().includes(keyword.toLowerCase()),
      )
    : data?.data;
  const { Paginator, pageWiseData, currentPage, setCurrentPage } =
    useCustomPagination({
      datas: filteredData,
      pageSize: 20,
      siblingCount: 1,
    });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    // bgcolor: 'background.paper',
    border: "2px solid #5411dc",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const [vcf, setVcf] = React.useState(null);
  const handleOpen = (vcf) => {
    setOpen(true);
    setVcf(vcf);
  };
  const handleClose = () => setOpen(false);
  function getWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1100) {
      return "25%";
    } else if (screenWidth >= 768 && screenWidth < 1100) {
      return "40%";
    } else {
      return "100%";
    }
  }
  return (
    <>
      <SearchBykeyword state={keyword} setState={setKeyword} />
      <div
        className="flex-wrap  w-100 justify-content-center align-items-center  p-2 gap-2"
        style={{
          scrollbarWidth: "thin",
          scrollbarGutter: "stable",
          margin: 0,
          overflowY: "auto",
        }}
      >
        <div className="d-flex flex-wrap w-100 justify-content-center">
          {Array.isArray(datas) &&
          isPlanSubscribed !== null &&
          isPlanSubscribed !== "undefined" ? (
            isPlanSubscribed === "Yes" ? (
              Object.keys(pageWiseData).length > 0 &&
              typeof pageWiseData[currentPage] !== "undefined" &&
              Object.keys(pageWiseData[currentPage]["data"]).length > 0 ? (
                <>
                  {Object.keys(pageWiseData[currentPage]["data"]).map(
                    (tour, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className=" card rounded-2  align-items-center justify-content-center shadow bg-white m-2"
                            style={{
                              maxwidth: "330px",
                              cursor: "pointer",
                              width: getWidth(),
                            }}
                          >
                            <img
                              src={
                                pageWiseData[currentPage]["data"][tour]
                                  .tour_thumbnail_image
                              }
                              className="card-img-top"
                              alt="profileimg"
                              height="150px"
                            />
                            <div className="card-body text-start w-100 p-0 px-3 py-2">
                              <p className="card-title mb-0 fw-semibold fst-italic fs-10 text-primary">
                                {pageWiseData[currentPage]["data"][tour]
                                  .tour_name.length < 75
                                  ? pageWiseData[currentPage]["data"][
                                      tour
                                    ].tour_name.toUpperCase()
                                  : pageWiseData[currentPage]["data"][
                                      tour
                                    ].tour_name.toUpperCase() + "..."}
                              </p>
                            </div>
                            <div className="card-footer d-flex flex-nowrap justify-content-start align-items-center w-100 gap-2">
                              {pageWiseData[currentPage]["data"][tour]
                                .published_status == "active" && (
                                <>
                                  <button
                                    className="btn btn-primary px-2 py-1"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        pageWiseData[currentPage]["data"][tour]
                                          .tour_url,
                                      );

                                      toast.info("copied link", {
                                        position: "bottom-center",
                                        autoClose: 1000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                  >
                                    <FaCopy size={16} />
                                  </button>
                                  <button
                                    className="btn btn-primary px-2 py-1"
                                    onClick={async (evt) => {
                                      evt.preventDefault();
                                      let payload = new FormData();
                                      // payload.append(
                                      //   "exhibitor_id",
                                      //   JSON.parse(
                                      //     localStorage.getItem("exhibitor_id"),
                                      //   ),
                                      // );
                                      payload.append("email_instance_id", 14);
                                      payload.append(
                                        "tour_code",
                                        pageWiseData[currentPage]["data"][tour]
                                          .tour_code,
                                      );

                                      await getEmailTemplate(payload).then(
                                        (res) => {
                                          if (
                                            res?.subject &&
                                            res?.body &&
                                            typeof res?.subject !==
                                              "undefined" &&
                                            typeof res?.body !== "undefined"
                                          ) {
                                            setState({
                                              type: "UPDATE_EMAIL_TEMPLATE",
                                              subject: res?.subject,
                                              body: res?.body,
                                              instanceID: 14,
                                              sourceID:
                                                pageWiseData[currentPage][
                                                  "data"
                                                ][tour].tour_code,
                                              contentType: null,
                                              details : pageWiseData[currentPage]["data"][tour]
                                            });
                                          } else {
                                            setState({
                                              type: "UPDATE_EMAIL_TEMPLATE",
                                              subject: "preset subject here",
                                              body: "<p>Enter your text here</p>",
                                              instanceID: 14,
                                              sourceID:
                                                pageWiseData[currentPage][
                                                  "data"
                                                ][tour].tour_code,
                                              contentType: null,
                                              details:pageWiseData[currentPage]["data"][tour]
                                            });
                                          }
                                          setState({
                                            type: "UPDATE_SHARE_MODAL_STATE",
                                            value: true,
                                          });
                                        },
                                      );
                                    }}
                                  >
                                    <MdEmail size={16} />
                                  </button>
                                  <button
                                    className="btn btn-primary px-2 py-1"
                                    onClick={() => {
                                      if (navigator.share) {
                                        navigator.share({
                                          title:
                                            pageWiseData[currentPage]["data"][
                                              tour
                                            ].tour_name,
                                          url: pageWiseData[currentPage][
                                            "data"
                                          ][tour].tour_url,
                                          text: pageWiseData[currentPage][
                                            "data"
                                          ][tour].tour_name,
                                        });
                                      } else {
                                        setState({
                                          type: "UPDATE_FALLBACK_STATE",
                                          state: true,
                                          url: pageWiseData[currentPage][
                                            "data"
                                          ][tour].tour_url,
                                        });
                                      }
                                    }}
                                  >
                                    <RiShareForwardFill size={16} />
                                  </button>
                                  <button
                                    className="btn btn-primary px-2 py-1"
                                    onClick={() => {
                                      handleOpen(
                                        pageWiseData[currentPage]["data"][
                                          tour
                                        ].tour_url.toString(),
                                      );
                                    }}
                                  >
                                    <FaQrcode size={16} />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    },
                  )}
                </>
              ) : (
                <>
                  <h2 className="fw-bold fs-4 mb-0 py-4 px-2 text-center">
                    No tours have been created
                  </h2>
                  <p className="mb-0 fw-normal text-secondary fs-10">
                    <a
                      className="text-decoration-none text-primary pe-auto"
                      target="_blank"
                      href={
                        process.env.REACT_APP_MODE == "production"
                          ? "https://app.veespaces.com/login"
                          : "https://dev-app.veespaces.com/login"
                      }
                    >
                      Click here
                    </a>{" "}
                    to create some tours to share with others
                  </p>
                </>
              )
            ) : (
              <>
                <h2 className="fw-bold fs-4 mb-0 py-4 px-2 text-center">
                  This plan has not been subscribed
                </h2>
                <p className="mb-0 fw-normal text-secondary fs-10">
                  <a
                    className="text-decoration-none text-primary pe-auto"
                    target="_blank"
                    href="https://www.veespaces.com/digital-business-cards/pricing/"
                  >
                    Click here
                  </a>{" "}
                  to view our plan details
                </p>
              </>
            )
          ) : (
            <>
              <div
                className="d-flex w-100 bg-white align-items-center justify-content-center"
                style={{ height: size.height }}
              >
                <div
                  className="spinner-border text-primary "
                  role="status"
                  style={{ width: "200px", height: "200px" }}
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center bg-primary rounded-2 px-2 py-1">
        <Paginator />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <QRCode
            value={vcf}
            size={250} // min 150
            level="L"
            renderAs="canvas"
            includeMargin={true}
            bgColor="#fff"
            fgColor="#5411dc"
            style={{ borderRadius: "10px" }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ToursTab;
