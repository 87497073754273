import React, { memo, useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { Modal } from "../../../functions/Modal";
import { useForm } from "react-hook-form";
import { EditLeads_API, EditNewLead_API } from "../../../services/userservices";
import { t } from "i18next";

export const AddNotes = memo(
  ({ dependencies: { state, setState, profileInformation } }) => {
    const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { errors, isSubmitSuccessful },
    } = useForm({
      defaultValues: {
        notes: "",
      },
    });
    const [notes, setNotes] = useState(state?.AddNotes?.Notes);
    async function AddTheNotes(data) {
      //---------------------------------------API Calling----------------------------------------//
      const PayloadFormData = new FormData();
      PayloadFormData.append("action", "update_notes");
      PayloadFormData.append("contact_id", profileInformation.contact_id);
      // PayloadFormData.append(
      //   "exhibitor_id",
      //   JSON.parse(localStorage.getItem("exhibitor_id")),
      // );
      PayloadFormData.append("notes", notes);

      const serialize = () => {
        // console.log(form);
        let requestArray = {};
        // form.querySelectorAll("[name]").forEach((elem) => {
        //   // console.log(elem);
        //   requestArray[elem.name] = elem.value;
        // });
        requestArray["country"] = "india";
        requestArray["status"] = "1";
        requestArray["key"] = "4";
        requestArray["source"] = "leads-mobileapp";
        requestArray["uid"] = JSON.parse(
          localStorage.getItem("user_id"),
        );
        return requestArray;
      };
      let serialized = serialize();
      // serialized.email_address = emailAddresses;
      serialized.notes = notes;
      serialized.contactId = profileInformation.contact_id;

      let payload = {action : "individual", user: [serialized] };
      EditNewLead_API(payload,profileInformation.contact_id).then((res) => {
        if (res.status.localeCompare("success") == 0) {
          /*----------------------------Temp - Notes state maintain------------------------------*/
          let _obj = JSON.parse(localStorage.getItem("EditLeads"));
          _obj.data.notes = notes;
          localStorage.setItem("EditLeads", JSON.stringify(_obj));
          /*----------------------------Temp - Notes state maintain------------------------------*/
          setState({ type: "NOTES_STATE", value: notes });
        }
      });
      //---------------------------------------API Calling----------------------------------------//
      setState({
        type: "ADD_NOTES_MODAL_STATE",
        value: false,
      });
    }

    useEffect(() => {
      /*----------------------------Temp - Notes state maintain------------------------------*/
      let _obj = JSON.parse(localStorage.getItem("EditLeads"));
      setState({ type: "NOTES_STATE", value: _obj.data.notes });
      /*----------------------------Temp - Notes state maintain------------------------------*/
    }, []);

    return (
      <>
        {notes === "" ? (
          <div
            className="d-flex w-100 justify-content-center "
            style={{ gap: "10px" }}
          >
            <p className="fst-italic fs-11 fw-semibold text-secondary">
            {t('contactsMenu.addNotes')} 
            </p>
            <BsFillPencilFill
              style={{ cursor: "pointer" }}
              type="button"
              onClick={() => {
                setState({
                  type: "ADD_NOTES_MODAL_STATE",
                  value: true,
                });
                setNotes(state?.AddNotes?.Notes);
              }}
            />
          </div>
        ) : (
          <div
            className="d-flex flex-column w-100 justify-content-center rounded  px-3 py-3"
            style={{
              gap: "10px",
              cursor: "pointer",
              backgroundColor: "rgb(235, 224, 255)",
            }}
            onClick={() => {
              setState({
                type: "ADD_NOTES_MODAL_STATE",
                value: true,
              });
              setNotes(state?.AddNotes?.Notes);
            }}
          >
            <div className="d-flex justify-content-between w-100 align-items-center">
              <p className="d-flex justify-content-start w-100  fs-10 fw-semibold text-dark mb-0">
              {t("contactsMenu.notes")}
              </p>
              <BsFillPencilFill style={{ cursor: "pointer" }} type="button" />
            </div>

            <p className="d-flex justify-content-start w-100 fst-italic fs-11 fw-normal text-secondary mb-0">
              {state?.AddNotes?.Notes}
            </p>
          </div>
        )}

        {state?.AddNotes?.isAddNotesModal_Active && (
          <Modal>
            <div className="d-flex justify-content-between align-items-center  py-2 px-2">
              <p className="fst-italic fs-10 fw-semibold text-dark mb-0">
              {t('contactsMenu.addNotes')}
              </p>
            </div>
            <form onSubmit={handleSubmit(AddTheNotes)}>
              <div className="d-flex flex-start  py-2 px-2">
                <textarea
                  className="form-control fs-10"
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                >
                  {notes}
                </textarea>
              </div>
              <div className="d-flex justify-content-between py-2 px-2">
                <button
                  className="d-flex justify-content-center align-items-center flex-fill btn btn-light rounded-0 fs-11"
                  onClick={() => {
                    setState({
                      type: "ADD_NOTES_MODAL_STATE",
                      value: false,
                    });
                  }}
                >
                  {t('contactsMenu.cancel')}
                </button>
                <button className="d-flex justify-content-center  align-items-center flex-fill btn btn-primary  rounded-0 fs-11">
                {t('contactsMenu.save')}
                </button>
              </div>
            </form>
          </Modal>
        )}
      </>
    );
  },
);
