import React, { useState } from "react";
import { useCustomPagination, useWindowSize } from "../../functions/functions";
import { FaCopy, FaQrcode } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import SearchBykeyword from "./SearchBykeyword";
import { getEmailTemplate } from "../../services/userservices";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import QRCode from "qrcode.react";
const AssetsTab = ({ data, state, setState }) => {
  const size = useWindowSize();
  const datas = data?.data;
  const isPlanSubscribed = data?.isPlanSubscribed;

  const [keyword, setKeyword] = useState(null);
  // Filter data based on the entered keyword
  const filteredData = keyword
    ? data?.data.filter(
        (item) =>
          item &&
          item.asset_name &&
          item.asset_name.toLowerCase().includes(keyword.toLowerCase()),
      )
    : data?.data;
  const { Paginator, pageWiseData, currentPage, setCurrentPage } =
    useCustomPagination({
      datas: filteredData,
      pageSize: 20,
      siblingCount: 1,
    });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    // bgcolor: 'background.paper',
    border: "2px solid #5411dc",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const [vcf, setVcf] = React.useState(null);
  const handleOpen = (vcf) => {
    setOpen(true);
    setVcf(vcf);
  };
  const handleClose = () => setOpen(false);
  function getWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      return "40%";
    } else {
      return "100%";
    }
  }
  return (
    <>
      <SearchBykeyword state={keyword} setState={setKeyword} />
      <div
        className="d-flex flex-wrap justify-content-center align-items-start align-content-start h-100 p-2 gap-2"
        style={{
          scrollbarWidth: "thin",
          scrollbarGutter: "stable",
          margin: 0,
          overflowY: "auto",
        }}
      >
        {Array.isArray(datas) &&
        isPlanSubscribed !== null &&
        isPlanSubscribed !== "undefined" ? (
          isPlanSubscribed === "Yes" ? (
            Object.keys(pageWiseData).length > 0 &&
            typeof pageWiseData[currentPage] !== "undefined" &&
            Object.keys(pageWiseData[currentPage]["data"]).length > 0 ? (
              <>
                {Object.keys(pageWiseData[currentPage]["data"]).map(
                  (asset, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          style={{ width: getWidth() }}
                          className="rounded-2 flex-wrap border-secondary bg-light d-flex  flex-column px-2 py-2  border border-2 justify-content-between "
                        >
                          {/* <img
                            width={38}
                            height={38}
                            src={asset?.asset_thumbnail_image}
                            className="rounded-2 "
                            style={{ aspectRatio: '1/1' }}
                          /> */}
                          <div
                            className="d-flex flex-column align-items-start justify-content-start"
                            style={{
                              width: size.width <= 315 ? "100%" : "50%",
                            }}
                          >
                            <p className="mb-0 fw-bold fs-9 text-secondary  w-100 text-break text-start w-100">
                              {
                                pageWiseData[currentPage]["data"][asset]
                                  .content_type
                              }
                            </p>
                            <p className="mb-0 fw-normal fs-11 fst-italic text-secondary w-100 text-break text-start w-100">
                              {pageWiseData[currentPage]["data"][asset]
                                .asset_name &&
                                pageWiseData[currentPage]["data"][
                                  asset
                                ].asset_name.substring(0, 23) +
                                  (pageWiseData[currentPage]["data"][asset]
                                    .asset_name.length > 23
                                    ? "..."
                                    : "")}
                            </p>
                          </div>
                          <div
                            className={`d-flex align-items-center justify-content-center gap-2 ${
                              size.width <= 315 ? "mt-1" : null
                            }`}
                          >
                            {pageWiseData[currentPage]["data"][asset]
                              .published_status == "2" && (
                              <button
                                className="btn btn-primary px-2 py-1 w-auto"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    pageWiseData[currentPage]["data"][asset]
                                      .asset_url,
                                  );

                                  toast.info("copied link", {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                }}
                              >
                                <FaCopy size={16} />
                              </button>
                            )}
                            {/* {pageWiseData[currentPage]["data"][asset]
                              .published_status == "2" && (
                              <button
                                className="btn btn-primary px-2 py-1 w-auto"
                                onClick={async (evt) => {
                                  evt.preventDefault();
                                  let payload = new FormData();
                                  // payload.append(
                                  //   "exhibitor_id",
                                  //   JSON.parse(
                                  //     localStorage.getItem("exhibitor_id"),
                                  //   ),
                                  // );
                                  payload.append("email_instance_id", 18);
                                  payload.append(
                                    "content_id",
                                    pageWiseData[currentPage]["data"][asset]
                                      .asset_id,
                                  );
                                  payload.append(
                                    "content_type",
                                    pageWiseData[currentPage]["data"][asset]
                                      .content_type,
                                  );

                                  await getEmailTemplate(payload).then(
                                    (res) => {
                                      if (
                                        res?.subject &&
                                        res?.body &&
                                        typeof res?.subject !== "undefined" &&
                                        typeof res?.body !== "undefined"
                                      ) {
                                        setState({
                                          type: "UPDATE_EMAIL_TEMPLATE",
                                          subject: res?.subject,
                                          body: res?.body,
                                          instanceID: 18,
                                          sourceID:
                                            pageWiseData[currentPage]["data"][
                                              asset
                                            ].asset_id,
                                          contentType:
                                            pageWiseData[currentPage]["data"][
                                              asset
                                            ].content_type,
                                        });
                                      } else {
                                        setState({
                                          type: "UPDATE_EMAIL_TEMPLATE",
                                          subject: "preset subject here",
                                          body: "<p>Enter your text here</p>",
                                          instanceID: 18,
                                          sourceID:
                                            pageWiseData[currentPage]["data"][
                                              asset
                                            ].asset_id,
                                          contentType:
                                            pageWiseData[currentPage]["data"][
                                              asset
                                            ].content_type,
                                        });
                                      }
                                    },
                                  );

                                  setState({
                                    type: "UPDATE_SHARE_MODAL_STATE",
                                    value: true,
                                  });
                                }}
                              >
                                <MdEmail size={16} />
                              </button>
                            )} */}
                            {pageWiseData[currentPage]["data"][asset]
                              .published_status == "2" && (
                              <button
                                className="btn btn-primary px-2 py-1 w-auto"
                                onClick={async () => {
                                  if (navigator.share) {
                                    navigator.share({
                                      title:
                                        pageWiseData[currentPage]["data"][asset]
                                          .asset_name,
                                      url: pageWiseData[currentPage]["data"][
                                        asset
                                      ].asset_url,
                                      text: pageWiseData[currentPage]["data"][
                                        asset
                                      ].asset_name,
                                    });
                                  } else {
                                    setState({
                                      type: "UPDATE_FALLBACK_STATE",
                                      state: true,
                                      url: pageWiseData[currentPage]["data"][
                                        asset
                                      ].asset_url,
                                    });
                                  }
                                }}
                              >
                                <RiShareForwardFill size={16} />
                              </button>
                            )}
                            {pageWiseData[currentPage]["data"][asset]
                              .published_status == "2" && (
                              <button
                                className="btn btn-primary px-2 py-1 w-auto"
                                onClick={() => {
                                  handleOpen(
                                    pageWiseData[currentPage]["data"][
                                      asset
                                    ].asset_url.toString(),
                                  );
                                }}
                              >
                                <FaQrcode size={16} />
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  },
                )}
              </>
            ) : (
              <>
                <h2 className="fw-bold fs-4 mb-0 py-4 px-2 text-center">
                  No assets have been uploaded
                </h2>
                <p className="mb-0 fw-normal text-secondary fs-10">
                  <a
                    className="text-decoration-none text-primary pe-auto"
                    target="_blank"
                    href={
                      process.env.REACT_APP_MODE == "production"
                        ? "https://app.veespaces.com/login"
                        : "https://dev-app.veespaces.com/login"
                    }
                  >
                    Click here
                  </a>{" "}
                  to upload some assets to share with others
                </p>
              </>
            )
          ) : (
            <>
              <h2 className="fw-bold fs-4 mb-0 py-4 px-2 text-center">
                This plan has not been subscribed
              </h2>
              <p className="mb-0 fw-normal text-secondary fs-10">
                <a
                  className="text-decoration-none text-primary pe-auto"
                  target="_blank"
                  href="https://www.veespaces.com/digital-business-cards/pricing/"
                >
                  Click here
                </a>{" "}
                to view our plan details
              </p>
            </>
          )
        ) : (
          <>
            <div
              className="d-flex w-100 bg-white align-items-center justify-content-center"
              style={{ height: size.height }}
            >
              <div
                className="spinner-border text-primary "
                role="status"
                style={{ width: "200px", height: "200px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center bg-primary rounded-2 px-2 py-1">
        <Paginator />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <QRCode
            value={vcf}
            size={250} // min 150
            level="L"
            renderAs="canvas"
            includeMargin={true}
            bgColor="#fff"
            fgColor="#5411dc"
            style={{ borderRadius: "10px" }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AssetsTab;
