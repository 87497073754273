import {
  DISPLAY_CARDS,
  CHOOSE_SPECIFIC_CARD,
  SET_PRESENT_DISPLAY,
} from "../../../state_services/actionTypes/businesscard/CardsActionTypes";

const initialState = {
  DISPLAY: "",
  ProfileUrl: "",
  ChoosedCardIndex: 0,
  CardsDetails: "",
};

export const CardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_CARDS:
      return {
        ...state,
        DISPLAY: action.type,
        CardsDetails: action.Cardsdetails,
      };
    case CHOOSE_SPECIFIC_CARD:
      return {
        ...state,
        DISPLAY: action.type,
        ProfileUrl: action.ProfileUrl,
        ChoosedCardIndex: action.ChoosedCardIndex,
      };
    case SET_PRESENT_DISPLAY:
      return {
        ...state,
        DISPLAY: action.DISPLAY,
      };
    default:
      return state;
  }
};
