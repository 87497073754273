import React, { useRef, useState } from "react";
import Camera from "react-html5-camera-photo";

function VirtualPhotoBooth() {
  const [photo, setPhoto] = useState(null);
  const canvasRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user");

  const handleTakePhoto = (dataUri) => {
    setPhoto(dataUri);
  };

  const handleSwitchCamera = () => {
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  };

  const handleSavePhoto = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Draw the photo onto the canvas
    const image = new Image();
    image.src = photo;
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Fill the background with a solid color
      ctx.fillStyle = "#ff0000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Fill the background with an image
      const backgroundImage = new Image();
      backgroundImage.src = "path/to/background-image.png";
      backgroundImage.onload = () => {
        const pattern = ctx.createPattern(backgroundImage, "repeat");
        ctx.fillStyle = pattern;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      };
    };
  };

  return (
    <>
      <Camera
        idealFacingMode={facingMode}
        // onTakePhoto={(dataUri) => {
        //   console.log("Photo taken!", dataUri);
        // }}
        onTakePhoto={handleSavePhoto}
        isImageMirror={false}
        imageType={"png"}
        videoConstraints={{
          facingMode,
        }}
      />
      <button onClick={handleSwitchCamera}>Switch camera</button>
      {photo && (
        <div>
          <canvas ref={canvasRef} width={640} height={480} />
          <button onClick={handleSavePhoto}>Save Photo</button>
        </div>
      )}
    </>
  );
}

export default VirtualPhotoBooth;
