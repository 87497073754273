import React, { useState, useEffect, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

//API
import { FetchDetailsFromBcardImage_API } from "../../services/userservices";
import {
  StoreBcardImageData,
  SetPresentDisplay,
} from "../../state_services/actions/ocr/OCRActions";
import {
  BCARD_IMAGE_PREVIEW,
  CAPTURE_BCARD_IMAGE,
} from "../../state_services/actionTypes/ocr/OCRActionTypes";
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import LoaderUI from "./components/LoaderUI";

//API
import {
  DisplayLeads_API,
} from "../../services/userservices";

export const ImageCrop = (props) => {

  const image_Data = props?.data?.state?.BCARD_IMAGE;
  let navigate = useNavigate();
  const Crop_img = useRef();
  const [isProcessing, setIsProcessing] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    setImage(e.currentTarget);

    // const crop = centerCrop(
    //   makeAspectCrop(
    //     {
    //       // You don't need to pass a complete crop into
    //       // makeAspectCrop or centerCrop.
    //       unit: '%',
    //       width: 90
    //     },
    //     16 / 9,
    //     width,
    //     height
    //   ),
    //   width,
    //   height
    // )

    const crop = {
      unit: "px",
      x: 25,
      y: 25,
      width: Crop_img.current.width - 50,
      height: Crop_img.current.height - 50,
    };
    setCrop(crop);
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    // canvas.toBlob((blob) => {
    //   Upload_Businesscard_Frontimage_FN(blob);
    // },
    // "image/jpeg",
    // 0.95);
    setOutput(base64Image);
  };

  //  const Upload_Businesscard_Frontimage_FN = (blob) =>{
  //   console.log(blob);
  //  };

  useEffect(() => {
    if (output !== null) {
      setIsProcessing(true);
      let Base64_data = output.split(";base64,")[1];
      const PayloadFormData = new FormData();
      PayloadFormData.append("image", Base64_data);
      FetchDetailsFromBcardImage_API(PayloadFormData).then((res) => {
        setIsProcessing(false);
        let _Object = image_Data;
        _Object.Crop_Base64Image = output;
        _Object.FinalData = "";
        _Object.Data = res;
        props?.data?.dispatch(StoreBcardImageData(_Object));
        props?.data?.dispatch(SetPresentDisplay(BCARD_IMAGE_PREVIEW));
      });
    }
  }, [output]);

  return (
    <>
      <div className="d-flex flex-column align-items-center w-100 h-100 overflow-y-auto gap-1">
        <div style={{ position: "relative" }}>
          <button
            className="btn btn-primary"
            style={{ position: "absolute", top: 5, left: 5, zIndex: 2 }}
            onClick={() => {
              props?.data?.dispatch(SetPresentDisplay(CAPTURE_BCARD_IMAGE));
            }}
          >
            <IoMdClose className="text-white" size={20} />
          </button>
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img src={image_Data?.URL} onLoad={onImageLoad} ref={Crop_img} />
          </ReactCrop>
          <button
            className="btn btn-primary"
            style={{ position: "absolute", top: 5, right: 5, zIndex: 2 }}
            onClick={cropImageNow}
          >
            <TiTick size={20} />
          </button>
        </div>

        <div>{output && <img src={output} width="50%" height="50%" />}</div>
        {isProcessing && <LoaderUI />}
      </div>
    </>
  );
};
