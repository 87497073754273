import React, { useEffect, useRef, useState } from "react";
import { ProfileScreen } from "./ProfileScreen";
import { StoriesScreen } from "./StoriesScreen";
import { useWindowSize } from "../../functions/functions";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { FaTrash } from "react-icons/fa";
import { IoMdCloseCircle, IoMdSend } from "react-icons/io";
import AvatarGroup from "@mui/material/AvatarGroup";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  CreateNewLead_Notes,
  DisplayLeads_API,
  Stories_API,
  Tag_Stories_API,
  downloadFile,
} from "../../services/userservices";
import SocialScreen from "./SocialScreen";
import { RiShareForwardFill } from "react-icons/ri";
import { t } from "i18next";
export const ProfileOptions = (props) => {
  let profileinfo = props.data;
  const size = useWindowSize();
  console.log(profileinfo, "profileinfo");
  const PROFILE_TABS = {
    PROFILE_SCREEN: "Profile_Screen",
    BUSINESSCARD_SCREEN: "Businesscard_Screen",
    NOTES_SCREEN: "Notes_Screen",
    STORIES_SCREEN: "Stories_Screen",
    SOCIAL_SCREEN: "Social_Screen",
  };
  const [apiLoader, setApiLoader] = useState(false);
  const [voiceApiLoader, setVoiceApiLoader] = useState(false);
  const [Tabstate, setTabstate] = useState(PROFILE_TABS.PROFILE_SCREEN);
  const [open, setOpen] = React.useState(false);
  const [items, setItems] = useState(profileinfo?.contactinfo?.ContactNotes); // State to hold the array of notes and voice notes
  const [inputText, setInputText] = useState(""); // State to hold the text typed in the input field
  const [storiesTagged, setStoriesTagged] = useState([]);
  console.log(storiesTagged, "storiesTagged");
  // Function to handle adding a note or voice note to the array
  // Function to convert blob to file
  const convertBlobToFile = (blobUrl, fileName) => {
    return fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => new File([blob], fileName));
  };
  const addItem = async (type, content) => {
    if (content.trim() !== "") {
      // Check if the content is not empty
      if (type !== "audio") {
        setApiLoader(true);
      }
      let convertedContent = content; // Default content is the original content
      if (type === "audio") {
        setVoiceApiLoader(true);
        try {
          // Convert blob URL to file
          const file = await convertBlobToFile(content, "voice_note.mp3");
          convertedContent = file;
          console.log(convertedContent, "convertedContent");
        } catch (error) {
          console.error("Failed to convert blob to file:", error);
          return; // Abort adding item if conversion fails
        }
      }
      let payload = new FormData();
      payload.append("contact_id", profileinfo?.contactinfo?.contact_id);
      payload.append(
        "uid",
        JSON.parse(localStorage?.getItem("user_id")),
      );
      payload.append("type", type);
      payload.append("content", convertedContent);
      try {
        const res = await CreateNewLead_Notes(payload);
        //-------------------------------------------------Display Leads - Fetch------------------------------------------------//
        let Dpayload = {
          uid: JSON.parse( localStorage.getItem( "user_id" ) ),
        };
        const displayLeadsRes = await DisplayLeads_API(Dpayload);
        if (displayLeadsRes.status === "success") {
          setApiLoader(false);
          setVoiceApiLoader(false);
          let leads_data = {};
          displayLeadsRes.data.forEach((leads) => {
            leads_data[leads.data.email_address] = leads.data;
          });
          localStorage.setItem("DisplayLeads", JSON.stringify(leads_data));
        }
        //-------------------------------------------------Display Leads - Fetch------------------------------------------------//
        let _obj = JSON.parse(localStorage.getItem("DisplayLeads"));
        let _Eobj = {
          data: _obj[profileinfo?.contactinfo?.email_address],
          editsystem: "edit",
          refId: profileinfo?.contactinfo?.email_address,
        };
        localStorage.setItem("EditLeads", JSON.stringify(_Eobj));
        let _objNew = JSON.parse(localStorage.getItem("EditLeads"));
        setItems(_objNew?.data?.ContactNotes);
        setInputText(""); // Clear the input field after adding the item
      } catch (error) {
        console.error("Error adding item:", error);
      }
    }
  };

  // Function to handle deleting an item from the array
  const deleteItem = (noteId) => {
    // setItems(prevItems => prevItems.filter((_, i) => i !== index));
    (async function () {
      let payload = new FormData();

      payload.append("note_id", noteId);
      payload.append("uid", JSON.parse(localStorage?.getItem("user_id")));
      try {
        const res = await CreateNewLead_Notes(payload);
        if (res[0].status === "success") {
          setOpen(true);
        }
        //-------------------------------------------------Display Leads - Fetch------------------------------------------------//
        let Dpayload = {
          uid: JSON.parse( localStorage.getItem( "user_id" ) ),
        };

        const displayLeadsRes = await DisplayLeads_API(Dpayload);

        if (displayLeadsRes.status === "success") {
          let leads_data = {};
          displayLeadsRes.data.forEach((leads) => {
            leads_data[leads.data.email_address] = leads.data;
          });
          localStorage.setItem("DisplayLeads", JSON.stringify(leads_data));
        }
        //-------------------------------------------------Display Leads - Fetch------------------------------------------------//

        let _obj = JSON.parse(localStorage.getItem("DisplayLeads"));
        let _Eobj = {
          data: _obj[profileinfo?.contactinfo?.email_address],
          editsystem: "edit",
          refId: profileinfo?.contactinfo?.email_address,
        };
        localStorage.setItem("EditLeads", JSON.stringify(_Eobj));

        let _objNew = JSON.parse(localStorage.getItem("EditLeads"));
        setItems(_objNew?.data?.ContactNotes);

        setInputText(""); // Clear the input field after adding the item
      } catch (error) {
        console.error("Error adding item:", error);
      }
    })();
  };
  function convertDateTime(dateTimeStr) {
    // Define months array
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Split date and time
    const [datePart, timePart] = dateTimeStr.split(" ");

    // Extract year, month, and day
    const [year, month, day] = datePart.split("-");

    // Extract hours, minutes, and seconds
    const [hours, minutes, seconds] = timePart.split(":");

    // Convert month to text format
    const monthText = months[parseInt(month, 10) - 1];

    // Convert hours to AM/PM format
    const ampm = hours >= 12 ? "pm" : "am";
    let adjustedHours = hours % 12;
    adjustedHours = adjustedHours ? adjustedHours : 12; // Adjust 0 to 12 for 12-hour clock

    // Construct the final string
    const result = `Created on ${monthText} ${parseInt(day, 10)}, ${year} ${adjustedHours}.${minutes} ${ampm}`;

    return result;
  }
  // Inside your component function
  const containerRef = useRef(null);
  // Add this useEffect to scroll to the bottom after items are updated
  useEffect(() => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [items]);
  useEffect(() => {
    let StoriesPayload = new FormData();
    StoriesPayload.append("contactId", profileinfo.contactinfo.contact_id);
    Tag_Stories_API(StoriesPayload,profileinfo.contactinfo.contact_id).then((res) => {
      console.log(res?.data);
      setStoriesTagged(res?.data);
    });
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoMdCloseCircle fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  function getColumns() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1100) {
      return 2;
    } else if (screenWidth >= 768 && screenWidth < 1100) {
      return 2;
    } else {
      return 1;
    }
  }
  return (
    <>
      <div className="d-flex flex-column bg-white rounded-bottom">
        <div className="d-flex w-100 px-3 pb-3">
          <ul className="nav nav-tabs w-100">
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => setTabstate(PROFILE_TABS.PROFILE_SCREEN)}
            >
              <a
                className={`${
                  Tabstate === PROFILE_TABS.PROFILE_SCREEN ? "active" : ""
                } nav-link fs-11 fw-semibold`}
                aria-current="page"
              >
               {t('contactsMenu.profile')} 
              </a>
            </li>
  
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => setTabstate(PROFILE_TABS.SOCIAL_SCREEN)}
            >
              <a
                className={`${
                  Tabstate === PROFILE_TABS.SOCIAL_SCREEN ? "active" : ""
                } nav-link fs-11 fw-semibold`}
              >
              {t('contactsMenu.socialLinks')}
              </a>
            </li>
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => setTabstate(PROFILE_TABS.NOTES_SCREEN)}
            >
              <a
                className={`${
                  Tabstate === PROFILE_TABS.NOTES_SCREEN ? "active" : ""
                } nav-link fs-11 fw-semibold`}
              >
                {t('contactsMenu.notes')}
              </a>
            </li>
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => setTabstate(PROFILE_TABS.STORIES_SCREEN)}
            >
              <a
                className={`${
                  Tabstate === PROFILE_TABS.STORIES_SCREEN ? "active" : ""
                } nav-link fs-11 fw-semibold`}
              >
                {t('contactsMenu.taggedStories')}
              </a>
            </li>
          </ul>
        </div>
        <div
          className="d-flex w-100 bg-white rounded-bottom py-4"
          style={{ height: size.height - 275, overflowY: "auto" }}
        >
          {Tabstate === PROFILE_TABS.PROFILE_SCREEN ? (
            <ProfileScreen data={profileinfo} />
          ) : null}
      
          {Tabstate === PROFILE_TABS.NOTES_SCREEN ? (
            <div className="d-flex flex-column mx-3 w-100">
              <div
                className="overflow-auto"
                style={{ height: "100%" }}
                ref={containerRef}
              >
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-3 border border-light bg-light rounded p-2"
                  >
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <small className="text-primary">
                          {convertDateTime(item.created_at)}
                        </small>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => deleteItem(item.note_id)}
                        >
                          <FaTrash />
                        </button>
                      </div>
                      {item.type === "notes" ? (
                        <p>{item.note}</p>
                      ) : (
                        <>
                          <small>{item.note}</small>
                          <audio
                            controls
                            src={item.downloaded_previewed_file}
                          ></audio>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="Add Notes"
                  name="meet-notes"
                  className="form-control"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)} // Update the inputText state as the user types
                />

                {!apiLoader ? (
                  <button
                    type="button"
                    disabled={voiceApiLoader}
                    className="btn btn-link"
                    onClick={() => addItem("notes", inputText)} // Call addItem function with type 'note' when the button is clicked
                  >
                    <IoMdSend size={30} />
                  </button>
                ) : (
                  <button type="button" className="btn btn-link">
                    <CircularProgress color="secondary" size={25} />
                  </button>
                )}
              </div>
              <div className="overflow-hidden w-100 d-flex justify-content-center py-2">
                {!voiceApiLoader ? (
                  <AudioRecorder
                    onRecordingComplete={(blob) =>
                      addItem("audio", URL.createObjectURL(blob))
                    }
                    audioTrackConstraints={{
                      noiseSuppression: true,
                      echoCancellation: true,
                    }}
                    onNotAllowedOrFound={(err) => console.table(err)}
                    downloadFileExtension="webm"
                    mediaRecorderOptions={{
                      audioBitsPerSecond: 128000,
                    }}
                    showVisualizer={true}
                  />
                ) : (
                  <div>
                    <CircularProgress color="secondary" size={25} />
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {Tabstate === PROFILE_TABS.STORIES_SCREEN ? (
            <div className=" m-0 w-100 ">
              <ImageList
                sx={{
                  width: "100%",
                  margin: "0",
                  transform: "translateZ(0)",
                }}
                variant="masonry"
                cols={getColumns()}
                gap={0}
              >
                {storiesTagged.map((item, index) => (
                  <div key={item.story}>
                    {/* <img src={ item.story_img } alt={ item.name } className="img-fluid" /> */}
                    <ImageListItem key={item.story_img}>
                      <img
                        srcSet={item.story_img}
                        src={item.story_img}
                        alt={item.story_img}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title={item.name}
                        subtitle={item.storyAddedAt}
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${item.storyAddedAt}`}
                          >
                            {/* <InfoIcon /> */}
                          </IconButton>
                        }
                      />
                       <ImageListItemBar
      title={item.name}
      subtitle={item.storyAddedAt}
        actionIcon={
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <AvatarGroup
              color="secondary"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
             
              max={3}
            >
              {item?.getTaggedUsers.map((user) => (
                <Avatar alt={user.tag_name} src="/static/images/avatar/1.jpg" />
              ))}
            </AvatarGroup>
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              aria-label={`info about ${item?.name}`}
              className="me-2"
              onClick={async () => {
                if (navigator.share) {
                  try {
                    await downloadFile(item);
                  } catch (error) {
                    console.error("Error sharing:", error);
                  }
                } else {
                  // Fallback if navigator.share is not supported
                  // setState({
                  //   type: 'UPDATE_FALLBACK_STATE',
                  //   state: true,
                  //   url: story?.date_added,
                  // });
                }
              }}
            >
              <RiShareForwardFill />
            </IconButton>
          </Box>
        }
      />
                    </ImageListItem>

                  </div>
                ))}
              </ImageList>
            </div>
          ) : null}
          {Tabstate === PROFILE_TABS.SOCIAL_SCREEN ? (
            <SocialScreen data={profileinfo} />
          ) : null}
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={500}
        onClose={handleClose}
        message="Note deleted"
        action={action}
      />
    </>
  );
};
