import React, { memo, useEffect, useState } from "react";
import { StoryTile } from "./components/StoryTile";
import { FaChevronLeft } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ViewStory } from "./components/ViewStory";
import { useWindowSize } from "../../functions/functions";
import UpgradePlan from "../../functions/UpgradePlan";
import { ToastContainer } from "react-toastify";
import { ImageList } from "@mui/material";
import { t } from "i18next";

export const StoriesList = memo(({ dependencies: { state, setState } }) => {
  let navigate = useNavigate();
  const [pageLoader, setPageLoader] = useState(true);
  const [visibleStories, setVisibleStories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(10);
  const size = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setPageLoader(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (state?.StoriesList?.length) {
      const reversedStories = [...state.StoriesList].reverse();
      setVisibleStories(reversedStories.slice(0, 10));
      setCurrentIndex(10);
    }
  }, [state?.StoriesList]);

  const loadMoreStories = () => {
    const nextIndex = currentIndex + 10;
    const reversedStories = [...state.StoriesList].reverse();
    setVisibleStories((prevVisibleStories) => [
      ...prevVisibleStories,
      ...reversedStories.slice(currentIndex, nextIndex),
    ]);
    setCurrentIndex(nextIndex);
  };

  function getColumns() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1100) {
      return 2;
    } else if (screenWidth >= 768 && screenWidth < 1100) {
      return 2;
    } else {
      return 1;
    }
  }

  return (
    <>
      <div
        className="d-flex flex-column p-0 mx-auto bg-primary"
        style={{ overflowY: "hidden" }}
      >
        <div
          className="d-flex justify-content-between flex-column align-items-center"
          style={{ overflowY: "hidden" }}
        >
          <div
            className="d-flex w-100 text-start align-items-center justify-content-between py-3 px-3 sticky-top"
            style={{ color: "white", fontSize: "2em" }}
          >
            <FaChevronLeft
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/dashboard");
              }}
            />
            <p className="m-0 fw-semibold fs-4">{t('storiesMenu.stories')}</p>
            {JSON.parse(localStorage.getItem("config_settings"))
              .stories_enabled === "Yes" ? (
              <p className="m-0" style={{ width: "20px" }}>
                <AiOutlinePlus
                  className="text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState({
                      type: "UPDATE_CHOICE_MODAL_OPEN",
                      choice: true,
                    });
                  }}
                />
              </p>
            ) : null}
          </div>

          {JSON.parse(localStorage.getItem("config_settings"))
            .stories_enabled === "No" ? (
            pageLoader ? (
              <div className="d-flex w-100 bg-white align-items-center justify-content-center">
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{ width: "200px", height: "200px" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <UpgradePlan />
            )
          ) : pageLoader ? (
            <div
              className="d-flex w-100 bg-white align-items-center justify-content-center"
              style={{ height: size.height }}
            >
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "200px", height: "200px" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="container-fluid rounded-1 bg-white"
              style={{ overflowY: "auto", height: size.height }}
            >
              <div className="row row-cols-2 gap-0 justify-content-center">
                <ImageList
                  sx={{
                    width: "100%",
                    margin: "0",
                    transform: "translateZ(0)",
                  }}
                  variant="masonry"
                  cols={getColumns()}
                  gap={0}
                >
                  {visibleStories.length > 0 ? (
                    visibleStories.map((story, index) => (
                      <StoryTile
                        key={index}
                        story={story}
                        dependencies={{ state: state, setState: setState }}
                      />
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center bg-light px-2 py-3 rounded-1 shadow rounded-2">
                      <p className="fw-semibold fs-10 mb-0 text-secondary">
                        No stories available
                      </p>
                    </div>
                  )}
                </ImageList>
              </div>
              {currentIndex < state.StoriesList.length && (
                <div className="d-flex justify-content-center mt-3">
                  <button
                   className="btn btn-outline-primary"
                    onClick={loadMoreStories}
                  >
                    more stories
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
      {state?.isViewStoryModalOpen && (
        <ViewStory dependencies={{ state: state, setState: setState }} />
      )}
    </>
  );
});

export default StoriesList;
