import { t } from "i18next";
import React, { memo } from "react";
import { BiHistory } from "react-icons/bi";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const ScreenSwitch = memo(({ dependencies: { state, setState } }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="d-flex w-100 align-items-center justify-content-between p-2 bg-primary">
        <FaChevronLeft
          size={30}
          style={{ cursor: "pointer", color: "#fff" }}
          onClick={() => {
            navigate("/dashboard");
          }}
        />
  <p className="mb-0 fw-semibold fs-3 text-white">{t('qrMenu.qrScanner')}</p>
      
          {JSON.parse(localStorage.getItem("config_settings"))
            .business_cards_enabled === "Yes" ? (
            <>  <div
            className="d-flex justify-content-center align-items-center flex-column rounded-1 text-white bg-none p-2"
            style={{ gap: "2px", cursor: "pointer" }}
            onClick={() => {
              setState({ type: "UPDATE_SCREEN", screen: "History" });
            }}
          >
              <BiHistory size={25}  />

              <p className="mb-0 fw-semibold fs-10 text-white">{t('qrMenu.history')}</p></div>
            </>
          ) : null}
        
      </div>
    </>
  );
});
