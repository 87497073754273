import React, { useEffect, useReducer, useState } from "react";
import logo from "../assets/logo.png";
import { NewUserEmailSignUp } from "../screens/Login/NewUserEmailSignUp";
import { EmailVerificationCode } from "../screens/Login/EmailVerificationCode";
import { ForgotLoginCode } from "../screens/Login/ForgotLoginCode";
import { SignInWithLoginCode } from "../screens/Login/SignInWithLoginCode";
import { LoginCodeSet } from "../screens/Login/LoginCodeSet";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import frenchflag from "../../src/assets/flags/french_flag.jpg"
import germanyflag from "../../src/assets/flags/germany_flag.jpg"
import arabic from "../../src/assets/flags/Arabic-Language-Flag.svg"
import engFlag from "../../src/assets/flags/us_flag.jpg"
import inFlag from "../../src/assets/flags/in.svg"
import { useTranslation } from 'react-i18next';

export const Login = () => {
  let navigate = useNavigate();
  let InitialState = {
    motion: ["150%", "0%"],
    screen_flow: "",
    current_screen: "",
    verify: {
      email_for_verification: "",
      verification_process: "not-triggered",
    },
    login_code_set: false,
    API_response: {
      status: "",
      message: "",
    },
  };

  function StateChanger(state, action) {
    switch (action.type) {
      case "EMAIL_VERIFY": {
        return {
          ...state,
          verify: {
            ...state.verify,
            email_for_verification: action.email,
            verification_process: action.process,
          },
        };
      }
      case "LOGIN_CODE_STATUS": {
        return {
          ...state,
          login_code_set: action.status,
        };
      }
      case "SCREEN_NAME": {
        return {
          ...state,
          current_screen: action.screen,
        };
      }
      case "SCREEN_FLOW": {
        return {
          ...state,
          screen_flow: action.screen_flow,
        };
      }
      case "TOAST_RESPONSE": {
        return {
          ...state,
          API_response: {
            ...state.API_response,
            status: action.status,
            message: action.message,
          },
        };
      }
      default: {
        return state;
      }
    }
  }

  const [state, changer] = useReducer(StateChanger, InitialState);
  const orginMap = {
    en: "English",
    fr: "Français",
    de: "Deutsch",
    ar:"عربي",
    ta: "தமிழ்",
    hi: "हिन्दी",
  };
  const getInitialLanguage = () => {
    const savedLanguage = localStorage.getItem("language") || "en";
    const flagMap = {
      en: engFlag,
      fr: frenchflag,
      de: germanyflag,
      ar: arabic,
      ta: inFlag,
      hi: inFlag,
    };

    return {
      name: savedLanguage,
      flag: flagMap[savedLanguage],
      orgin: orginMap[savedLanguage],
    };
  };

  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage);

  useEffect(() => {
    changeLanguage(selectedLanguage.name);
  }, []);
  const handleLanguageChange = (language, flag) => {
    setSelectedLanguage({ name: language, flag , orgin: orginMap[language]});
    changeLanguage(language)
  };
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };
  useEffect(() => {
    if (
      localStorage.getItem("user_email") !== null &&
      localStorage.getItem("user_login_code") !== null
    ) {
      let UserEmail = JSON.parse(localStorage.getItem("user_email"));
      let UserLoginCode = JSON.parse(localStorage.getItem("user_login_code"));
      if (
        typeof UserEmail !== "undefined" &&
        UserEmail !== "" &&
        UserEmail !== null &&
        typeof UserLoginCode !== "undefined" &&
        UserLoginCode !== "" &&
        UserLoginCode !== null
      ) {
        changer({ type: "SCREEN_FLOW", screen_flow: "login" });
        changer({ type: "SCREEN_NAME", screen: "login" });
      } else {
        changer({
          type: "SCREEN_FLOW",
          screen_flow: "new_user-verify-set_login_code",
        });
        changer({ type: "SCREEN_NAME", screen: "new_user" });
      }
    } else {
      changer({
        type: "SCREEN_FLOW",
        screen_flow: "new_user-verify-set_login_code",
      });
      changer({ type: "SCREEN_NAME", screen: "new_user" });
    }
  }, []);

  useEffect(() => {
    if (state?.API_response?.message !== "") {
      toast(state?.API_response?.message);
    }
  }, [state?.API_response]);

  return (
    <>
      <div
        className="container-fluid d-flex align-items-center min-vh-100 overflow-hidden position-relative"
        style={{
          background:
            "linear-gradient(142deg, rgba(230,230,251,1) 0%, rgba(230,224,246,1) 33%, rgba(232,223,247,1) 66%, rgba(223,206,235,1) 100%)",
        }}
      >
        <div className="container-sm h-100 overflow-hidden">
        <Dropdown className="m-2 position-absolute top-0 end-0">
      <Dropdown.Toggle className="btn btn-light " variant="default">
        <img src={selectedLanguage.flag} alt={selectedLanguage.name} width="20" className="me-2" />
        {selectedLanguage.orgin}
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('ta', inFlag)}>
          <img src={inFlag} alt="Arabic" className="me-2" width="20" />
          தமிழ்
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('hi', inFlag)}>
          <img src={inFlag} alt="Arabic" className="me-2" width="20" />
          हिन्दी
        </Dropdown.Item>
      <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('en', engFlag)}>
          <img src={engFlag} alt="English" className="me-2" width="20" />
          English
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('fr', frenchflag)}>
          <img src={frenchflag} alt="French" className="me-2" width="20" />        
Français
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('de', germanyflag)}>
          <img src={germanyflag} alt="German" className="me-2" width="20" />
          Deutsch
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleLanguageChange('ar', arabic)}>
          <img src={arabic} alt="Arabic" className="me-2" width="20" />
          عربي
        </Dropdown.Item>
     
      </Dropdown.Menu>
    </Dropdown>
          <motion.div
            animate={{
              x: state.motion,
              opacity: [0.3, 1],
            }}
            transition={{
              duration: 1.3,
              type: "spring",
              stiffness: 60,
            }}
          >
            {state.current_screen === "new_user" ? (
              <motion.div
                animate={{
                  x: state.motion,
                  opacity: [0.3, 1],
                }}
                transition={{
                  duration: 1.3,
                  type: "spring",
                  stiffness: 60,
                }}
              >
                <NewUserEmailSignUp
                  logo={logo}
                  dependencies={{ LoginState: state, LoginChanger: changer }}
                />
              </motion.div>
            ) : null}

            {state.current_screen === "verify" ? (
              <motion.div
                animate={{
                  x: state.motion,
                  opacity: [0.3, 1],
                }}
                transition={{
                  duration: 1.3,
                  type: "spring",
                  stiffness: 60,
                }}
              >
                <EmailVerificationCode
                  logo={logo}
                  dependencies={{ LoginState: state, LoginChanger: changer }}
                />
              </motion.div>
            ) : null}

            {state.current_screen === "reset_user" ? (
              <motion.div
                animate={{
                  x: state.motion,
                  opacity: [0.3, 1],
                }}
                transition={{
                  duration: 1.3,
                  type: "spring",
                  stiffness: 60,
                }}
              >
                <ForgotLoginCode
                  logo={logo}
                  dependencies={{ LoginState: state, LoginChanger: changer }}
                />
              </motion.div>
            ) : null}

            {state.current_screen === "login" ? (
              <motion.div
                animate={{
                  x: state.motion,
                  opacity: [0.3, 1],
                }}
                transition={{
                  duration: 1.3,
                  type: "spring",
                  stiffness: 60,
                }}
              >
                <SignInWithLoginCode
                  logo={logo}
                  dependencies={{ LoginState: state, LoginChanger: changer }}
                />
              </motion.div>
            ) : null}

            {state.current_screen === "set_login_code" ? (
              <motion.div
                animate={{
                  x: state.motion,
                  opacity: [0.3, 1],
                }}
                transition={{
                  duration: 1.3,
                  type: "spring",
                  stiffness: 60,
                }}
              >
                <LoginCodeSet
                  logo={logo}
                  dependencies={{ LoginState: state, LoginChanger: changer }}
                />
              </motion.div>
            ) : null}
          </motion.div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={1200}
          limit={2}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  );
};
