import React, { memo, useEffect, useState } from "react";
import { AssetsByUserAPI } from "../../../services/userservices";

const BulkEmailCards = memo(
  ({ dependencies: { sendBulkEmail, setSendBulkEmail } }) => {
    const [assetsByUser, setAssetsByUser] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
      setLoading(true); // Start loader before fetching data
      AssetsByUserAPI({
        uid: JSON.parse(localStorage?.getItem("user_id")),
      }).then((res) => {
        setAssetsByUser(res);
        setLoading(false); // Stop loader once data is fetched
      });
    }, []);

    // Function to handle selection, ensuring only one item across all lists is selected
    const handleSelection = (type, id) => {
      setSendBulkEmail({
        type,  // stores the type of the selected item (digital_cards, collections, virtual_tours)
        id,    // stores the selected item's id
      });
    };

    return (
      <>
        {loading ? ( // Show loader while fetching data
          <div className="d-flex justify-content-center align-items-center" style={{ height: '350px' }}>
            <p>Loading...</p> {/* Replace this with a spinner if you have one */}
          </div>
        ) : (
          Object.keys(assetsByUser).length > 0 && (
            <div
              className="d-flex w-100 border bg-light rounded-2 p-3 mb-3 mt-2"
              style={{ maxHeight: "350px", overflowY: "auto" }}
            >
              <ul className="mb-0 p-0" style={{ listStyle: "none" }}>
                {/* Digital Cards Section */}
                <li>
                  <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                    DIGITAL CARDS
                  </p>
                  <ul style={{ listStyle: "none", overflowY: "auto", height: "100%" }}>
                    {assetsByUser?.digital_cards?.isPlanSubscribed === "Yes" ? (
                      <>
                        {assetsByUser?.digital_cards?.data?.length > 0 ? (
                          assetsByUser?.digital_cards?.data?.map((card) => (
                            <div key={card?.card_id} className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={
                                  sendBulkEmail?.type === "digital_cards" &&
                                  sendBulkEmail?.id === card?.card_id
                                }
                                onChange={() => handleSelection("digital_cards", card?.card_id)}
                              />
                              <label
                                className="form-check-label fw-normal fs-11"
                                htmlFor={`${card?.card_name}_${card?.card_id}`}
                              >
                                {card?.card_name}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">No cards to add</p>
                        )}
                      </>
                    ) : (
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                        No subscription found
                      </p>
                    )}
                  </ul>
                </li>

                {/* Micro Pages Section */}
                <li>
                  <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                    MICRO PAGES
                  </p>
                  <ul style={{ listStyle: "none", overflowY: "auto", height: "100%" }}>
                    {assetsByUser?.collections?.isPlanSubscribed === "Yes" ? (
                      <>
                        {assetsByUser?.collections?.data?.length > 0 ? (
                          assetsByUser?.collections?.data
                            .filter((collection) => collection.published_status === 2)
                            .map((collection) => (
                              <div key={collection?.collection_id} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  checked={
                                    sendBulkEmail?.type === "collections" &&
                                    sendBulkEmail?.id === collection?.collection_id
                                  }
                                  onChange={() => handleSelection("collections", collection?.collection_id)}
                                />
                                <label
                                  className="form-check-label fw-normal fs-11"
                                  htmlFor={`${collection?.collection_name}_${collection?.collection_id}`}
                                >
                                  {collection?.collection_name}
                                </label>
                              </div>
                            ))
                        ) : (
                          <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">
                            No collections to add
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">No subscription found</p>
                    )}
                  </ul>
                </li>

                {/* Virtual Space Section */}
                <li>
                  <p className="mb-0 fw-semibold fs-10 px-2 py-1 rounded-2 border border-2 bg-white mb-1">
                    VIRTUAL SPACE
                  </p>
                  <ul style={{ listStyle: "none", overflowY: "auto", height: "100%" }}>
                    {assetsByUser?.virtual_tours?.isPlanSubscribed === "Yes" ? (
                      <>
                        {assetsByUser?.virtual_tours?.data?.length > 0 ? (
                          assetsByUser?.virtual_tours?.data
                            .filter((tour) => tour.published_status === "active")
                            .map((tour) => (
                              <div key={tour?.tour_id} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  checked={
                                    sendBulkEmail?.type === "virtual_tours" &&
                                    sendBulkEmail?.id === tour?.tour_id
                                  }
                                  onChange={() => handleSelection("virtual_tours", tour?.tour_id)}
                                />
                                <label
                                  className="form-check-label fw-normal fs-11"
                                  htmlFor={`${tour?.tour_name}_${tour?.tour_id}`}
                                >
                                  {tour?.tour_name}
                                </label>
                              </div>
                            ))
                        ) : (
                          <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">No tours to add</p>
                        )}
                      </>
                    ) : (
                      <p className="mb-0 fw-normal mb-1 fs-11 fst-italic">No subscription found</p>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          )
        )}
      </>
    );
  }
);

export default BulkEmailCards;
