import React, { useEffect, useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { FaChevronLeft } from "react-icons/fa";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { FaAt } from "react-icons/fa";
import { Add_Stories_API, Stories_API } from "../../services/userservices";
import { AddTags } from "./components/AddTags";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useWindowSize } from "../../functions/functions";
import LoaderUI from "../OCR/components/LoaderUI";
import { t } from "i18next";

export const AddStoryPreview = ({ dependencies: { state, setState } }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      story_name: "",
      story_date: "",
    },
  });

  let APILOADERREF = useRef();
  const size = useWindowSize();
  const [addedTags, setAddedTags] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  useEffect(() => {
    if (
      typeof state?.FileUploaded !== "undefined" &&
      state?.FileUploaded !== null &&
      state?.FileUploaded["size"] > 0
    ) {
      let image = document.getElementById("image-preview");
      image.src = URL.createObjectURL(state?.FileUploaded);
    }
  }, []);

  async function AddStoryToDB(data) {
    setApiLoader(true);
    const selectedfile = state?.FileUploaded;
    if (typeof selectedfile !== "undefined" && selectedfile !== null) {
      let AddStoryPayload = new FormData();
      AddStoryPayload.append("action", "add_new_story");
      AddStoryPayload.append("name", data?.story_name);
      // AddStoryPayload.append('date_added', data?.story_date);
      AddStoryPayload.append(
        "uid",
        JSON.parse(localStorage.getItem("user_id")),
      );
      {
        selected.length > 0 &&
          AddStoryPayload.append(
            "tagged_users",
            selected.map((item, index) => item.tag_id),
          );
      }

      AddStoryPayload.append("story_img", selectedfile);

      await Add_Stories_API(AddStoryPayload).then((res) => {
        setState({ type: "UPDATE_SCREEN", screen: "StoriesList" });
        setState({
          type: "UPDATE_ACTION",
          action: "add",
          id: Math.floor(Math.random() * 578543),
        });
      });
      setApiLoader(false);
    }
  }
  useEffect(() => {
    reset({
      story_name: "",
    });
  }, [isSubmitSuccessful]);
  let ExhibitorTags = JSON.parse(localStorage.getItem("DisplayLeads"));
  let selected = [];
  let contactList = Object.values(ExhibitorTags).map((option) => ({
    tag_id: option.contact_id,
    tag_name: `${option?.first_name} ${option?.last_name}`,
  }));
  console.log(ExhibitorTags, "ExhibitorTags");
  console.log(contactList, "contactList");
  console.log(selected, "selected");
  return (
    <>
      <div className="d-flex flex-column p-0 min-vh-100 mx-auto bg-primary">
        <div className="d-flex justify-content-between flex-column align-items-center ">
          <div
            className="d-flex w-100 text-start align-items-center justify-content-between py-3 px-3"
            style={{ color: "white", fontSize: "2em" }}
          >
            <FaChevronLeft
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState({ type: "UPDATE_SCREEN", screen: "StoriesList" });
              }}
            />
            <p className="m-0 fw-semibold fs-4">{t('storiesMenu.preview')}</p>
            <p className="m-0 " style={{ width: "20px" }}></p>
          </div>
          {apiLoader ? (
            <LoaderUI />
          ) : (
            <div className="container-fluid py-2 rounded-1 bg-white min-vh-100 ">
              <div
                className="row row-cols-lg-2 align-items-center justify-content-center py-3 mx-auto"
                style={{ gap: "15px" }}
              >
                <div className="d-flex bg-white text-center border border-2 rounded-2 p-3 justify-content-center">
                  <img
                    id="image-preview"
                    alt="uploaded image"
                    src="https://assets-global.website-files.com/5e39e095596498a8b9624af1/633604c562868a10ab4c7163_uMk2yhmH04IjjAHWzOeM_tATsEn6kaJHIXikeFABZPv7G2VpYt7NdACThY1yQcBUw7KQWXpOiDBqumo3FVIKMpqBNPWO_U-5gGreQMZ23EFrqbhAXMRWtPU-zY7XdyZ0HfsgQV7FcafaziA6lddfUYoOERc3k2_UJ9M90FcOpdC_iIDDZfAZqzWDmg.png"
                    height="150px"
                    className="rounded-2"
                    style={{ maxWidth: "100%" }}
                  />
                  <label
                    htmlFor="file-upload"
                    className="d-flex justify-content-center align-items-center  p-2 rounded-circle bg-white shadow"
                    style={{
                      position: "relative",
                      top: 120,
                      right: 30,
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <BiImageAdd size={25} className="text-secondary" />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(evt) => {
                      let image = document.getElementById("image-preview");
                      setState({
                        type: "UPDATE_FILE_UPLOADED",
                        file: evt.target.files[0],
                      });
                      image.src = URL.createObjectURL(evt.target.files[0]);
                    }}
                  />
                </div>
                <div>
                  {/* <AddTags
                dependencies={{
                  state: state,
                  setState: setState,
                }}
                Tags={[]}
                addedTags={addedTags}
                setAddedTags={setAddedTags}
                setEditedTags={[]}
              /> */}
                  <div
                    className="d-flex flex-column w-100 justify-content-center rounded  px-3 py-3"
                    style={{
                      gap: "10px",
                      cursor: "pointer",
                      backgroundColor: "rgb(235, 224, 255)",
                    }}
                  >
                    <p className="d-flex justify-content-start w-100 fst-italic  fs-10 fw-semibold text-dark mb-0">
                    {t('storiesMenu.taggedPeople')}
                    </p>

                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={contactList}
                      size="small"
                      filterSelectedOptions
                      defaultValue={[]}
                      onChange={(event, newValue) => {
                        console.log(newValue, "newValue");
                        selected = newValue;
                        console.log(selected, "selected");
                      }}
                      getOptionLabel={(option) => option.tag_name} // Display only the concatenated name
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option.tag_id} // Use option.id as the key
                            icon={<FaAt />}
                            label={option.tag_name} // Display only the concatenated name
                            color="secondary"
                            sx={{ padding: "2px", margin: "2px" }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} color="secondary" />
                      )}
                    />
                  </div>
                </div>
                <form
                  className="d-flex flex-column text-start p-0"
                  style={{ gap: "10px" }}
                  onSubmit={handleSubmit(AddStoryToDB)}
                >
                  <label
                    htmlFor="story_name"
                    className=" fst-italic fs-10 fw-semibold text-secondary"
                  >
                    {t('storiesMenu.storyName')}
                  </label>
                  <input
                    id="story_name"
                    {...register("story_name", {
                      required: {
                        value: true,
                        message: "A story name is required to add this story",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
                        message:
                          "Story name should not contain special characters",
                      },
                    })}
                    type="text"
                    placeholder={t('storiesMenu.storyName')}
                    className={`form-control input-group form-control px-3 py-2 fs-11 ${
                      errors?.story_name ? "border border-danger" : null
                    }`}
                    style={{ padding: "10px 0px" }}
                  />
                  {errors?.story_name && (
                    <p className="text-danger fw-normal fs-11 fst-italic  text-start m-0">
                      {errors?.story_name?.message}
                    </p>
                  )}
                  {/* <label
                  htmlFor="story_date"
                  className=" fst-italic fs-10 fw-semibold text-secondary">
                  Date
                </label>
                <input
                  id="story_date"
                  {...register('story_date', {
                    required: {
                      value: true,
                      message: 'A Date is required to add this story',
                    },
                    // pattern: {
                    //   value: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
                    //   message:
                    //     'Story name should not contain special characters',
                    // },
                  })}
                  type="date"
                  placeholder="Date"
                  className={`form-control input-group form-control px-3 py-2 fs-11 ${
                    errors?.story_date ? 'border border-danger' : null
                  }`}
                  style={{ padding: '10px 0px' }}
                />
                {errors?.story_date && (
                  <p className="text-danger fw-normal fs-11 fst-italic  text-start m-0">
                    {errors?.story_date?.message}
                  </p>
                )} */}
                  <button
                    type="submit"
                    className="d-flex justify-content-center align-items-center btn btn-primary"
                    style={{ gap: "10px" }}
                  >
                    <p className="fw-semibold fs-10 mb-0">{t('storiesMenu.addStory')}</p>
                    <AiOutlineAppstoreAdd size={20} />
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
