import { t } from "i18next";
import React from "react";
import { AiFillCloseSquare, AiOutlineClose } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import { BsCameraFill } from "react-icons/bs";

export const UploadChoice = ({ dependencies: { state, setState } }) => {
  return (
    <>
      <div className="d-flex flex-column pt-1 pb-4 px-1">
        <div className="d-flex justify-content-end">
          <AiFillCloseSquare
            size={30}
            className="text-primary py-1"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState({
                type: "UPDATE_CHOICE_MODAL_OPEN",
                choice: false,
              });
            }}
          />
        </div>
        <label
          htmlFor="file-upload"
          className="d-flex w-auto align-items-center p-2 btn btn-primary rounded-1 shadow"
          style={{ gap: "5px", cursor: "pointer" }}
        >
          <BiImageAdd size={25} className="text-white" />
          <p className="mb-0 fw-normal w-auto text-white fs-10">{t('storiesMenu.select')}</p>
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          className="d-none"
          onChange={(evt) => {
            setState({
              type: "UPDATE_FILE_UPLOADED",
              file: evt?.target?.files[0],
            });
            setState({
              type: "UPDATE_SCREEN",
              screen: "AddStoryPreview",
            });
            setState({
              type: "UPDATE_CHOICE_MODAL_OPEN",
              choice: false,
            });
          }}
        />
        <hr></hr>
        <button
          className="d-flex w-auto align-items-center p-2 btn btn-outline-primary  rounded-1 shadow"
          style={{ gap: "5px", cursor: "pointer" }}
          onClick={() => {
            setState({ type: "UPDATE_SCREEN", screen: "Camera" });
            setState({ type: "UPDATE_CHOICE_MODAL_OPEN", choice: false });
          }}
        >
          <BsCameraFill size={25} className="text-primary" />
          <p className="mb-0 fw-semibold w-auto text-primary fs-10">{t('storiesMenu.capture')}</p>
        </button>
      </div>
    </>
  );
};
