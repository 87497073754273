import React, { useState } from "react";
import { Modal } from "../../functions/Modal";
import BulkEmailCards from "./components/BulkEmailCards";
import BulkEmailMessage from "./components/BulkEmailMessage";
import { MdArrowBack, MdSend } from "react-icons/md";
import { BulkEmail, BulkEmailSend } from "../../services/userservices";
import { ToastContainer, toast } from "react-toastify";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const BulkEmailModal = ({ dependencies: { bulkEmail, setBulkEmail } }) => {
  const [apiLoader, setApiLoader] = useState("none");
  const [sendBulkEmail, setSendBulkEmail] = useState({
    contact_id: [...bulkEmail?.selected_contacts],
    assets: [],
    collections: [],
    digital_cards: [],
    virtual_tours: [],
    message: "",
  });
  const [bulkMailCard, setBulkMailCard] = useState({
    
      type:null,  // stores the type of the selected item (digital_cards, collections, virtual_tours)
      id:null,    // stores the selected item's id
    
  });
  let TOAST_CONFIG = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  async function sendEmail() {
    const contactIds = sendBulkEmail?.contact_id || [];
  
    // Map over contactIds and send individual requests
    const emailPromises = contactIds.map(async (contactId) => {
      let payload = new FormData();
      payload.append("contact_id", JSON.stringify(contactId));
      payload.append("user_id", JSON.parse( localStorage.getItem( "user_id" )));
      if(bulkMailCard?.type == "digital_cards"){
        payload.append("card_id", bulkMailCard?.id);
        payload.append("mail_instance_id", 1);
      }
      if(bulkMailCard?.type == "collections"){
        payload.append("MicroId", bulkMailCard?.id);
        payload.append("mail_instance_id", 12);
      }
      if(bulkMailCard?.type =="virtual_tours"){
        payload.append("TourId", bulkMailCard?.id);
        payload.append("mail_instance_id", 9);
      }
    

      return BulkEmail(payload);
    });
  
    try {
      const results = await Promise.all(emailPromises); // Wait for all requests to complete
      const success = results.every((res) => res.data); // Check if all requests were successful
  
      if (success) {
        setApiLoader("success");
      }
  
      setTimeout(() => {
        setApiLoader("none");
        setBulkEmail({ selected_contacts: [], isBulkEmailModalOpen: false });
      }, 1000);
    } catch (err) {
      // Handle errors
      console.log(err);
    }
  }
  
  return (
    <>
      <Modal>
        <div className="d-flex w-100 align-items-center justify-content-between mt-2 mb-2">
          <button
            className="d-flex align-items-center justify-content-center btn btn-warning px-3 py-2"
            style={{ gap: "10px" }}
            onClick={(evt) => {
              evt.preventDefault();
              setBulkEmail((previousState) => ({
                ...previousState,
                isBulkEmailModalOpen: false,
              }));
            }}
          >
            <MdArrowBack size={15} />{" "}
            <p className="mb-0 fw-semibold fs-11">GO BACK</p>
          </button>
          <button
            className="d-flex align-items-center justify-content-center btn btn-primary border px-3 py-2"
            style={{ gap: "10px" }}
            onClick={(evt) => {
              evt.preventDefault();
              setApiLoader("loading");
              if (
                // (
                //   sendBulkEmail?.assets.length > 0 ||
                //   sendBulkEmail?.collections.length > 0 ||
                //   sendBulkEmail?.digital_cards.length > 0 ||
                //   sendBulkEmail?.virtual_tours.length > 0) &&
                // sendBulkEmail?.message.length > 0 ||
                 bulkMailCard?.id != null
              ) {
                sendEmail();
              }
              //  else if (
              //   (sendBulkEmail?.assets.length > 0 ||
              //     sendBulkEmail?.collections.length > 0 ||
              //     sendBulkEmail?.digital_cards.length > 0 ||
              //     sendBulkEmail?.virtual_tours.length > 0) &&
              //   sendBulkEmail?.message.length == 0
              // ) {
              //   toast.warning(
              //     "A Message is needed to send bulk email to all the emails mentioned",
              //     TOAST_CONFIG,
              //   );
              //   setApiLoader("none");
              // }
              //  else if (
              //   (sendBulkEmail?.assets.length == 0 ||
              //     sendBulkEmail?.collections.length == 0 ||
              //     sendBulkEmail?.digital_cards.length == 0 ||
              //     sendBulkEmail?.virtual_tours.length == 0) &&
              //   sendBulkEmail?.message.length > 0
              // ) {
              //   toast.warning(
              //     "Attach atleast one type of content to send bulk email",
              //     TOAST_CONFIG,
              //   );
              //   setApiLoader("none");
              // }
               else 
              //  if (
              //   (sendBulkEmail?.assets.length == 0 ||
              //     sendBulkEmail?.collections.length == 0 ||
              //     sendBulkEmail?.digital_cards.length == 0 ||
              //     sendBulkEmail?.virtual_tours.length == 0) &&
              //   sendBulkEmail?.message.length == 0
              // ) 
              {
                toast.warning(
                  "attachment of atleast one type of content is needed to send bulk email",
                  TOAST_CONFIG,
                );
                setApiLoader("none");
              }
            }}
          >
            <p className="mb-0 fw-semibold fs-11">
              {apiLoader === "success" ? "SENT" : "SEND"}
            </p>{" "}
            {apiLoader === "none" ? <MdSend size={15} /> : null}
            {apiLoader === "loading" ? (
              <div
                class="spinner-border text-warning"
                role="status"
                style={{ width: "20px", height: "20px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : null}
            {apiLoader === "success" ? (
              <>
                <IoMdCheckmarkCircleOutline size={25} color="green" />
              </>
            ) : null}
          </button>
        </div>
        <div
          style={
            apiLoader === "loading" || apiLoader === "success"
              ? { opacity: 0.5, pointerEvents: "none" }
              : null
          }
        >
          <BulkEmailCards

            dependencies={{
              sendBulkEmail: bulkMailCard,
              setSendBulkEmail: setBulkMailCard,
            }}
          />

          {/* <BulkEmailMessage
            dependencies={{
              sendBulkEmail: sendBulkEmail,
              setSendBulkEmail: setSendBulkEmail,
            }}
          /> */}
        </div>
      </Modal>
    </>
  );
};

export default BulkEmailModal;
