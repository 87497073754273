import {
  BCARD_STORE_IMAGE_DATA,
  DISPLAY_TYPE,
} from "../../../state_services/actionTypes/ocr/OCRActionTypes";

const SetPresentDisplay = (display) => {
  return {
    type: DISPLAY_TYPE,
    DISPLAY: display,
  };
};

const StoreBcardImageData = (image_data) => {
  return {
    type: BCARD_STORE_IMAGE_DATA,
    BCARD_IMAGE: image_data,
  };
};

export { SetPresentDisplay, StoreBcardImageData };
