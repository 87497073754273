import React from "react";
import { FinalData } from "./components/FinalData";
import { FaChevronLeft } from "react-icons/fa";

import { BCARD_IMAGE_PREVIEW } from "../../state_services/actionTypes/ocr/OCRActionTypes";
import { SetPresentDisplay } from "../../state_services/actions/ocr/OCRActions";

export const Preview = (props) => {
  const image_Data = props?.data?.state?.BCARD_IMAGE;

  const Goback_Fn = () => {
    props?.data?.dispatch(SetPresentDisplay(BCARD_IMAGE_PREVIEW));
  };

  return (
    <>
      <div
        className="d-flex flex-column  align-items-center w-100 bg-transparent mb-5"
        
      >
        <div
          className="d-flex w-100 text-start align-items-center justify-content-between py-3 px-3 bg-primary"
          style={{ color: "white", fontSize: "2em" }}
        >
          <FaChevronLeft
            size={25}
            style={{ cursor: "pointer" }}
            onClick={Goback_Fn}
          />
          <p className="m-0 fw-semibold fs-4">Confirm</p>
          <p className="m-0 " style={{ width: "20px" }}></p>
        </div>
        <FinalData data={image_Data} />
      </div>
    </>
  );
};
