import React, { useEffect, useReducer, useState } from "react";
import { StoriesList } from "../screens/Stories/StoriesList";
import { AddStoryPreview } from "../screens/Stories/AddStoryPreview";
import { Modal } from "../functions/Modal";
import { UploadChoice } from "../screens/Stories/components/UploadChoice";
import { Stories_API } from "../services/userservices";
import CryptoJS from "crypto-js";
import { Camera } from "../screens/Stories/Camera";
import UpgradePlan from "../functions/UpgradePlan";
import { useWindowSize } from "../functions/functions";

export const Stories = () => {
  const ENCRYPT_KEY = "AX450s7u3vHJ";
  const Decrypted = localStorage.getItem("Stories")
    ? JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Stories"),
          ENCRYPT_KEY,
        ).toString(CryptoJS.enc.Utf8),
      )
    : "";

  let initialStoriesState = {
    Screen:
      localStorage.getItem("Stories") && Decrypted.Screen
        ? Decrypted.Screen
        : "StoriesList",
    isChoiceModalOpen:
      localStorage.getItem("Stories") && Decrypted.isChoiceModalOpen
        ? Decrypted.isChoiceModalOpen
        : false,
    isViewStoryModalOpen:
      localStorage.getItem("Stories") && Decrypted.isViewStoryModalOpen
        ? Decrypted.isViewStoryModalOpen
        : false,
    isCaptureImageModalOpen: false,
    API_Action:
      localStorage.getItem("Stories") && Decrypted.API_Action
        ? Decrypted.API_Action
        : { Action: "", RequestID: "" },
    ViewStory:
      localStorage.getItem("Stories") && Decrypted.ViewStory
        ? Decrypted.ViewStory
        : {},
    FileUploaded:
      localStorage.getItem("Stories") && Decrypted.File ? Decrypted.File : {},
    isAddTagsModal_Active:
      localStorage.getItem("Stories") && Decrypted.isAddTagsModal_Active
        ? Decrypted.isAddTagsModal_Active
        : false,
    StoriesList:
      localStorage.getItem("Stories") && Decrypted.StoriesList
        ? Decrypted.StoriesList
        : [],
  };

  const StoriesReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_SCREEN": {
        return {
          ...state,
          Screen: action.screen,
        };
      }
      case "UPDATE_ACTION": {
        return {
          ...state,
          API_Action: { Action: action.action, RequestID: action.id },
        };
      }

      case "UPDATE_CHOICE_MODAL_OPEN": {
        return {
          ...state,
          isChoiceModalOpen: action.choice,
        };
      }
      case "UPDATE_CAPTURE_MODAL_OPEN": {
        return {
          ...state,
          isCaptureImageModalOpen: action.image,
        };
      }
      case "UPDATE_VIEW_MODAL_OPEN": {
        return {
          ...state,
          isViewStoryModalOpen: action.view,
        };
      }
      case "UPDATE_VIEW_STORY_STATE": {
        return {
          ...state,
          ViewStory: action.story,
        };
      }

      case "UPDATE_FILE_UPLOADED": {
        return {
          ...state,
          FileUploaded: action.file,
        };
      }
      case "ADD_TAGS_MODAL_STATE": {
        return {
          ...state,
          isAddTagsModal_Active: action.value,
        };
      }

      case "UPDATE_LIST": {
        return {
          ...state,
          StoriesList: action.list,
        };
      }
      default:
        break;
    }
  };

  const [StoriesPageState, StoriesPageDispatch] = useReducer(
    StoriesReducer,
    initialStoriesState,
  );

  useEffect(() => {
    console.log(StoriesPageState);
    localStorage.setItem(
      "Stories",
      CryptoJS.AES.encrypt(
        JSON.stringify(StoriesPageState),
        ENCRYPT_KEY,
      ).toString(),
    );
  }, [StoriesPageState]);

  let screenCheck_forListFetch = StoriesPageState?.Screen;
  let actionCheck_forListFetch = StoriesPageState?.API_Action?.RequestID;
  useEffect(() => {
    if (screenCheck_forListFetch === "StoriesList") {
      let StoriesPayload = new FormData();
      StoriesPayload.append("action", "list_stories");
      StoriesPayload.append(
        "uid",
        JSON.parse(localStorage.getItem("user_id")),
      );
      Stories_API( StoriesPayload).then((res) => {
        // console.log(res?.data);
        StoriesPageDispatch({ type: "UPDATE_LIST", list: res?.data });
      });
    }
  }, [screenCheck_forListFetch, actionCheck_forListFetch]);
  useEffect(() => {
    if (screenCheck_forListFetch === "StoriesList") {
      let StoriesPayload = new FormData();
      StoriesPayload.append("action", "list_stories");
      StoriesPayload.append(
        "uid",
        JSON.parse(localStorage.getItem("user_id")),
      );
      Stories_API(StoriesPayload).then((res) => {
        // console.log(res?.data);
        StoriesPageDispatch({ type: "UPDATE_LIST", list: res?.data });
      });
    }
  }, []);

  return (
    <>
      {StoriesPageState?.Screen === "StoriesList" ? (
        <StoriesList
          dependencies={{
            state: StoriesPageState,
            setState: StoriesPageDispatch,
          }}
        />
      ) : null}
      {StoriesPageState?.Screen === "AddStoryPreview" ? (
        <AddStoryPreview
          dependencies={{
            state: StoriesPageState,
            setState: StoriesPageDispatch,
          }}
        />
      ) : null}
      {StoriesPageState?.Screen === "Camera" ? (
        <Camera
          dependencies={{
            state: StoriesPageState,
            setState: StoriesPageDispatch,
          }}
        />
      ) : null}
      {StoriesPageState?.isChoiceModalOpen && (
        <Modal>
          <UploadChoice
            dependencies={{
              state: StoriesPageState,
              setState: StoriesPageDispatch,
            }}
          />
        </Modal>
      )}
    </>
  );
};
