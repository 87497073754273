import { t } from "i18next";
import React, { useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBykeyword = ({ state, setState }) => {
  const SEARCH_INPUT_REFERENCE = useRef();
  return (
    <>
      <div className="d-flex w-100 align-items-center justify-content-between mb-3 p-2">
        <div class="input-group ">
          <input
            type="text"
            ref={SEARCH_INPUT_REFERENCE}
            class="form-control fw-normal text-secondary  fs-11 fst-italic"
            placeholder={t('shareMenu.searchPlaceholder')}
            aria-label="enter-contact-name"
            aria-describedby="enter-contact-name"
            onBlur={(evt) => {
              if (
                SEARCH_INPUT_REFERENCE.current.value !== "" &&
                SEARCH_INPUT_REFERENCE.current.value !== null
              ) {
                setState(SEARCH_INPUT_REFERENCE.current.value);
              } else {
                setState("");
              }
            }}
            onKeyUp={(evt) => {
              if (evt.key === "Enter") {
                if (
                  SEARCH_INPUT_REFERENCE.current.value !== "" &&
                  SEARCH_INPUT_REFERENCE.current.value !== null
                ) {
                  setState(SEARCH_INPUT_REFERENCE.current.value);
                } else {
                  setState("");
                }
              }
            }}
          />
          <span
            className="input-group-text"
            id="search_contact"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (
                SEARCH_INPUT_REFERENCE.current.value !== "" &&
                SEARCH_INPUT_REFERENCE.current.value !== null
              ) {
                setState(SEARCH_INPUT_REFERENCE.current.value);
              } else {
                setState("");
              }
            }}
          >
            <AiOutlineSearch size={25} />
          </span>
        </div>
      </div>
    </>
  );
};

export default SearchBykeyword;
