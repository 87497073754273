import {
  BCARD_STORE_IMAGE_DATA,
  DISPLAY_TYPE,
} from "../../../state_services/actionTypes/ocr/OCRActionTypes";

const initialState = {
  DISPLAY: "",
  BCARD_IMAGE: {
    URL: "",
    Crop_Base64Image: "",
    Data: "",
    FinalData: "",
  },
};

export const OCRReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_TYPE:
      return {
        ...state,
        DISPLAY: action.DISPLAY,
      };
    case BCARD_STORE_IMAGE_DATA:
      return {
        ...state,
        BCARD_IMAGE: action.BCARD_IMAGE,
      };
    default:
      return state;
  }
};
